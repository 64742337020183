import moment from 'moment';

const STATES = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

function randNum(max: number) {
  return Math.floor(Math.random() * (max + 1));
}

function randArrItem<T>(arr: T[]) {
  return arr[randNum(arr.length - 1)];
}

export function generateFakeStats(
  clubId: number,
  currentTierKeys: string[],
  maxNewMembersPerDay: number
) {
  const prices = [499, 999, 2999];
  const tiers = currentTierKeys.map((t, idx) => ({ price: prices[idx], name: t }));

  const result: CoreAPI.AdminClubStatsResponse = {
    clubId,
    memberCount: 0,
    breakdown: {
      country: { USA: 0 },
      state: {},
      tier: {},
    },
    dailyStats: [],
  };

  for (let daysAgo = 365; daysAgo > -1; daysAgo--) {
    const newMemberCount = randNum(maxNewMembersPerDay);
    result.memberCount += newMemberCount;
    result.breakdown.country['USA'] += newMemberCount;

    const userActivity: CoreAPI.ClubStatistics['userActivity'] = {
      postCount: randNum(result.memberCount),
      postResponseCount: randNum(result.memberCount * 1.5),
    };

    const earnings: CoreAPI.ClubStatistics['earnings'] = {
      earningsBeforeRefunds: 0,
      refunds: 0,
      paymentFees: 0,
      platformFees: 0,
      artistEarnings: 0,
      labelEarnings: 0,
      avgSpendPerCustomer: 0,
    };

    for (let currMem = 0; currMem < newMemberCount; currMem++) {
      const memberState = randArrItem(STATES);
      const { name, price } = randArrItem(tiers);
      result.breakdown.state[memberState] = (result.breakdown.state[memberState] || 0) + 1;
      result.breakdown.tier[name] = (result.breakdown.tier[name] || 0) + 1;
      earnings.earningsBeforeRefunds += price;
      earnings.paymentFees += Math.round(30 + price * 0.029);
    }

    const earningsBeforeFees = earnings.earningsBeforeRefunds - earnings.refunds;
    const earningsAfterFees = earningsBeforeFees - earnings.paymentFees;
    earnings.platformFees = earningsAfterFees * 0.25;
    earnings.artistEarnings = earningsAfterFees - earnings.platformFees;
    earnings.avgSpendPerCustomer = earningsBeforeFees / newMemberCount;

    result.dailyStats.push({
      date: moment().subtract(daysAgo, 'day').unix(),
      newMemberCount,
      earnings: !earningsBeforeFees && !earnings.refunds ? null : earnings,
      userActivity:
        !userActivity.postCount && !userActivity.postResponseCount ? null : userActivity,
    });
  }
  return result;
}
