import { Button, Icon, Tooltip } from '@blueprintjs/core';
import type { Identifier, XYCoord } from 'dnd-core';
import React, { useRef } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { v4 as uuidv4 } from 'uuid';
import { SingleLineInput } from '../../components/TextInputs';
import { arrayUpdatingIdx } from './PageClubSettings';

export const ChannelEditor: React.FunctionComponent<{
  channels: CoreAPI.ClubFeedChannel[];
  onChange: (channels: CoreAPI.ClubFeedChannel[]) => void;
}> = ({ channels, onChange }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      <h4 style={{ marginBottom: 'unset' }}>Discussion Channels</h4>
      <DndProvider backend={HTML5Backend}>
        {channels.map((c, idx) => (
          <ChannelRow key={c.key} channels={channels} channel={c} idx={idx} onChange={onChange} />
        ))}
      </DndProvider>
      <Button
        style={{ marginTop: 10 }}
        icon="plus"
        onClick={() =>
          onChange([
            ...channels,
            {
              key: `${uuidv4().split('-').pop()}`,
              label: `Untitled-${channels.length}`,
              slug: 'untitled',
              restricted: false,
            },
          ])
        }
      >
        Add Channel
      </Button>
    </div>
  );
};

const CHANNEL_ITEM_TYPE = 'channel';
export interface DragItem {
  idx: number;
  key: string;
  type: string;
}

// Drag and drop logic: https://codesandbox.io/s/long-frost-jonwuv?file=/src/Card.tsx:2702-2729

const ChannelRow: React.FC<{
  channels: CoreAPI.ClubFeedChannel[];
  idx: number;
  channel: CoreAPI.ClubFeedChannel;
  onChange: (channels: CoreAPI.ClubFeedChannel[]) => void;
}> = ({ channels, idx, channel, onChange }) => {
  const { key, slug, label, restricted } = channel;
  const ref = useRef<HTMLDivElement>(null);
  const [{ isDragging }, drag] = useDrag({
    type: CHANNEL_ITEM_TYPE,
    item: () => ({ key, idx }),
    collect: monitor => ({ isDragging: monitor.isDragging() }),
  });

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const update = [...channels];
    update.splice(dragIndex, 1);
    update.splice(hoverIndex, 0, channels[dragIndex]);
    onChange(update);
  };

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: CHANNEL_ITEM_TYPE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.idx;
      const hoverIndex = idx;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.idx = hoverIndex;
    },
  });

  drag(drop(ref));

  return (
    <div
      data-handler-id={handlerId}
      ref={key === 'main' ? null : ref}
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        gap: 10,
        paddingRight: 10,
        marginRight: -10,
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <Icon
        icon="drag-handle-vertical"
        iconSize={15}
        style={{
          margin: '6px 0',
          ...(key === 'main'
            ? { opacity: 0, pointerEvents: 'none' }
            : { opacity: 0.6, cursor: 'move' }),
        }}
      />
      <div style={{ display: 'flex', gap: 10, height: 57 }}>
        <SingleLineInput
          required
          label={'URL Slug'}
          disabled={key === 'main'}
          value={slug}
          onChange={slug => {
            onChange(arrayUpdatingIdx(channels, idx, { slug }));
          }}
        />
        <SingleLineInput
          required
          label={'Name'}
          value={label}
          onChange={label => {
            onChange(arrayUpdatingIdx(channels, idx, { label }));
          }}
        />
      </div>
      <Button
        icon="trash"
        disabled={key === 'main'}
        onClick={() =>
          key !== 'main' &&
          window.confirm('Are you sure you want to remove this channel?') &&
          onChange([...channels.slice(0, idx), ...channels.slice(idx + 1)])
        }
      />
      <Tooltip
        content={
          restricted
            ? 'This channel is locked - only admins can post.'
            : 'This channel is unlocked - both admins and fans can post.'
        }
      >
        <Button
          icon={restricted ? 'lock' : 'unlock'}
          onClick={() => onChange(arrayUpdatingIdx(channels, idx, { restricted: !restricted }))}
        />
      </Tooltip>
    </div>
  );
};
