import { Button, Popover } from '@blueprintjs/core';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { makeRequest, useResource } from '../Resource';
import { formatBirthday } from '../Utils';
import { PageListHeader, PageListTable } from './Components';
import { PageContainer } from './PageHome';

const XLSXEndpoint = `${
  process.env.NODE_ENV === 'production' ? '' : 'http://localhost:8000'
}/api/admin/v1/users.xlsx`;

export const PageUsers: React.FunctionComponent = props => {
  const [users, { applyLocalUpdates }] = useResource<CoreAPI.CurrentUser[]>('/api/admin/v1/users');
  const [searchVal, setSearchVal] = React.useState<string>('');

  const deleteUser = async (id: number) => {
    const res = await makeRequest<{ message: string }>(`/api/admin/v1/users/${id}`, 'DELETE');
    if (res.message) {
      applyLocalUpdates((users || []).filter(u => u.id !== id));
    }
  };

  return (
    <PageContainer>
      <PageListHeader title="Users" filter={searchVal} onChangeFilter={setSearchVal}>
        <div style={{ width: 10 }} />
        <Button onClick={() => (window.location.href = XLSXEndpoint)}>Export XLSX</Button>
      </PageListHeader>
      <PageListTable>
        <thead>
          <tr>
            <th>Name / Email</th>
            <th style={{ width: 100 }}>Birthday</th>
            <th style={{ width: 150 }}>Location</th>
            <th style={{ width: 100 }}>Account Created</th>
            <th style={{ width: 200 }}>Last Seen</th>
            <th style={{ width: 250 }}>Memberships</th>
            <th style={{ width: 140 }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {(users || [])
            .sort((a, b) => a.email.localeCompare(b.email))
            .filter(a =>
              `${a.email}|${a.firstName} ${a.lastName}|${a.username}`.includes(searchVal)
            )
            .map(user => (
              <tr key={user.id}>
                <td>
                  {`${user.username} (${user.firstName} ${user.lastName})`}
                  <br />
                  <a href={`mailto:${user.email}`}>{user.email}</a>
                </td>
                <td>{formatBirthday(user.birthday)}</td>
                <td>
                  {user.shippingAddress
                    ? `${user.shippingAddress.city}, ${user.shippingAddress.state}`
                    : 'None Provided'}
                </td>
                <td>{new Date(user.createdAt).toLocaleDateString()}</td>
                <td>
                  {user.lastMeRefreshAt ? new Date(user.lastMeRefreshAt).toLocaleDateString() : '-'}
                  {user.lastMeRefreshAt && (
                    <>
                      &nbsp;<Link to={`/admin/users/${user.id}/activity`}>Activity &nbsp;&gt;</Link>
                    </>
                  )}
                </td>
                <td>
                  <ul>
                    {user.memberships.map(m => (
                      <li key={m.id}>{m.name}</li>
                    ))}
                  </ul>
                </td>
                <td>
                  <DeleteUserPopover deleteUser={() => deleteUser(user.id)} />
                </td>
              </tr>
            ))}
        </tbody>
      </PageListTable>
    </PageContainer>
  );
};

const DeleteUserPopover: React.FC<{ deleteUser: () => Promise<void> }> = ({ deleteUser }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover
      interactionKind="click"
      popoverClassName="bp3-popover-content-sizing"
      position="top"
      isOpen={isOpen}
    >
      <Button icon="trash" onClick={() => setIsOpen(v => !v)}>
        Delete User
      </Button>
      <div>
        <h4>Are you sure?</h4>
        <p>
          If this is a paying member of any fan crews, be sure to cancel their subscriptions in
          Stripe.
        </p>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }} />
          <Button className="bp3-popover-dismiss">Dismiss</Button>
          <div style={{ width: 10 }} />
          <Button
            intent="danger"
            onClick={async () => {
              await deleteUser();
              setIsOpen(false);
            }}
          >
            Delete
          </Button>
        </div>
      </div>
    </Popover>
  );
};
