import { ResponsiveLine } from '@nivo/line';
import moment from 'moment';
import React from 'react';
import { BrandSecondaryTint, BrandTint } from '../../Colors';
import { DashboardBox } from '../../components/Common';
import { DashboardDateRange, DAY_SEC } from '../PageClubDashboard';

export const CardActivity: React.FunctionComponent<{
  data: CoreAPI.AdminClubStatsResponse | undefined;
  dateRange: DashboardDateRange;
}> = ({ data, dateRange }) => {
  const [start, end] = dateRange;
  if (!start || !end || !data) {
    return <DashboardBox style={{ flex: 1, height: 400 }} />;
  }

  const dailyposts = [];
  const dailyresponses = [];
  let day = start;
  while (day < end) {
    const unix = day;
    const x = moment.unix(day).format('YYYY-MM-DD');
    dailyposts.push({
      x,
      y: data.dailyStats.reduce((acc, { date, userActivity }) => {
        if (userActivity && date > unix && date <= unix + DAY_SEC) acc += userActivity.postCount;
        return acc;
      }, 0),
    });
    dailyresponses.push({
      x: x,
      y: data.dailyStats.reduce((acc, { date, userActivity }) => {
        if (userActivity && date > unix && date <= unix + DAY_SEC)
          acc += userActivity.postResponseCount;
        return acc;
      }, 0),
    });
    day += DAY_SEC;
  }

  return (
    <DashboardBox style={{ flex: 1, height: 400 }}>
      <ResponsiveLine
        margin={{ top: 20, right: 20, bottom: 60, left: 30 }}
        animate
        enableGridX={false}
        enableSlices={false}
        enableArea={true}
        colors={[BrandTint, BrandSecondaryTint]}
        data={[
          {
            id: 'Posts',
            data: dailyposts,
          },
          {
            id: 'Responses',
            data: dailyresponses,
          },
        ]}
        xScale={{
          type: 'time',
          format: '%Y-%m-%d',
          useUTC: false,
          precision: 'day',
        }}
        xFormat="time:%Y-%m-%d"
        yScale={{
          type: 'linear',
          stacked: false,
          min: 0,
          max: Math.max(...dailyposts.map(d => d.y), ...dailyresponses.map(d => d.y)),
        }}
        axisLeft={{
          tickValues: 4,
        }}
        axisBottom={{
          format: '%b %d',
          tickValues:
            end - start < 15 * DAY_SEC
              ? 'every 1 day'
              : end - start < 32 * DAY_SEC
              ? 'every 1 week'
              : 'every month',
        }}
        curve={'monotoneX'}
        defs={[
          {
            id: 'gradientA',
            type: 'linearGradient',
            colors: [
              { offset: 0, color: 'inherit', opacity: 1 },
              { offset: 100, color: 'inherit', opacity: 0.5 },
            ],
          },
        ]}
        fill={[{ match: '*', id: 'gradientA' }]}
        tooltip={props => (
          <div
            style={{
              background: 'white',
              fontSize: 12,
              fontWeight: 500,
              padding: 2,
              boxShadow: '0 1px 1px rgba(0,0,0,0.1)',
            }}
          >
            {props.point.serieId}: {props.point.data.y}
          </div>
        )}
        pointSize={3}
        isInteractive={true}
        enableCrosshair={false}
        useMesh={true}
        pointLabelYOffset={-12}
        pointBorderWidth={1}
        pointBorderColor={{
          from: 'color',
          modifiers: [['darker', 0.3]],
        }}
      />
    </DashboardBox>
  );
};
