import moment from 'moment-timezone';
import React from 'react';
import { Link } from 'react-router-dom';
import { SessionContext } from '../App';
import { JoinThankYou } from '../pages-club/JoinThankYou';
import { useResource } from '../Resource';
import { Button } from './Button';
import { SheetModal } from './Common';

export const AcceptMeetModal: React.FunctionComponent<{
  club: CoreAPI.ClubWithMembership;
  invitationHash: string;
  onDismiss: () => void;
}> = props => {
  return (
    <SheetModal
      style={{ padding: 30, textAlign: 'center', maxWidth: 600 }}
      onClick={e => e.stopPropagation()}
    >
      {props.club.membership?.billingPeriodEnd &&
      props.club.membership.billingPeriodEnd > Date.now() ? (
        <AcceptConfirm {...props} userId={props.club.membership.userId} />
      ) : (
        <AcceptSignIn {...props} />
      )}
    </SheetModal>
  );
};

export const AcceptConfirm: React.FunctionComponent<{
  club: CoreAPI.ClubWithMembership;
  invitationHash: string;
  userId: number;
}> = ({ club, invitationHash, userId }) => {
  const [saving, setSaving] = React.useState(false);
  const [thanks, setThanks] = React.useState(false);
  const [meetAndGreet, ops] = useResource<CoreAPI.MeetAndGreet>(
    `/api/v1/clubs/${club.id}/meet-and-greets/${invitationHash}`
  );
  if (!meetAndGreet) {
    return <div />;
  }
  if (thanks) {
    return (
      <div style={{ position: 'relative', height: 440, overflow: 'hidden' }}>
        <JoinThankYou />
      </div>
    );
  }
  return (
    <div>
      <img
        alt="vip membership"
        src={require('../images/vip.png').default}
        style={{ width: 120, margin: 'auto', marginBottom: 20 }}
      />
      <h2 style={{ marginTop: 0, marginBottom: 30 }}>MEET THE ARTIST!</h2>
      <strong>{moment(meetAndGreet.startDate).format('LLLL')}</strong>
      <div>{moment.tz.guess()}</div>
      <p style={{ marginTop: 20, marginBottom: 20 }}>
        This virtual meet and greet cannot be rescheduled and is not refundable if you are unable to
        make it. To join, you must be a member of the artist's fan crew. Download the With the Band
        iOS or Android apps and follow the instructions the day of the meet and greet to connect to
        a video chat within the app.
      </p>
      <p>
        <strong>Once you click the button below this link will expire and you'll be booked!</strong>
      </p>
      <div style={{ marginTop: 30 }}>
        <Button
          intent="primary"
          size="normal"
          style={{ display: 'block', width: '100%' }}
          onClick={async () => {
            setSaving(true);
            await ops.put({ userId: userId });
            setSaving(false);
            setThanks(true);
            setTimeout(() => {
              window.location.href = `/${club.slug}`;
            }, 3 * 1000);
          }}
        >
          {saving ? 'Booking...' : "I'm In!"}
        </Button>
      </div>
    </div>
  );
};
export const AcceptSignIn: React.FunctionComponent<{
  club: CoreAPI.ClubWithMembership;
  onDismiss: () => void;
}> = ({ club }) => {
  const [session] = React.useContext(SessionContext);

  return (
    <div>
      <img
        alt="vip membership"
        src={require('../images/vip.png').default}
        style={{ width: 120, margin: 'auto', marginBottom: 20 }}
      />
      <h2 style={{ marginTop: 0, marginBottom: 30 }}>The artist wants to meet YOU!</h2>
      <p>
        To accept this invitation to a virtual meet and greet with the artist you need to be a
        member of the artist's fan crew. Click below to see membership options.
      </p>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
        {!session.user && (
          <Link to={`/login?next=${encodeURIComponent(window.location.href)}`}>
            <Button size="normal">Sign In</Button>
          </Link>
        )}
        <div style={{ width: 20 }} />
        <Link to={`/${club.slug}/join?next=${encodeURIComponent(window.location.href)}`}>
          <Button intent="primary" size="normal">
            See Membership Options
          </Button>
        </Link>
      </div>
    </div>
  );
};
