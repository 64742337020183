import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import React from 'react';
import styled from 'styled-components';
import { Button } from '../components/Button';
import { ErrorMessage, Form, SheetModal } from '../components/Common';
import { makeRequest } from '../Resource';

const STRIPE_PUBLIC =
  (process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_STRIPE_PUBLIC_PROD
    : process.env.REACT_APP_STRIPE_PUBLIC_DEV) || '';

export const ManageModalEditCardInner: React.FunctionComponent<{ onDone: () => void }> = props => {
  const [error, setError] = React.useState<string | undefined>();
  const [state, setState] = React.useState<'none' | 'loading'>('none');
  const elements = useElements();
  const stripe = useStripe();

  const onSave = async (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<any>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }
    setState('loading');
    setError(undefined);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setError(error.message);
      setState('none');
    } else if (paymentMethod) {
      const resp = await makeRequest<{ error?: string }>(`/api/v1/update-card`, 'POST', {
        stripeToken: paymentMethod.id,
      });

      if (resp.error) {
        setError(resp.error);
        setState('none');
      } else {
        props.onDone();
      }
    }
  };

  return (
    <EditProfileModalContainer onClick={e => e.stopPropagation()}>
      <Form onSubmit={onSave} style={{ padding: '10px 15px' }}>
        <h3>Update your Credit Card</h3>
        <p style={{ marginBottom: 15 }}>
          Re-enter credit card information below. The new card will be used for all future crew
          membership payments.
        </p>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <div style={{ background: '#f3f3f3', borderBottom: '1px solid #ddd', padding: '7px 6px' }}>
          <CardElement
            options={{
              style: {
                base: {
                  iconColor: '#111',
                  color: '#111',
                  fontWeight: '400',
                  fontFamily: "'Open Sans', sans-serif",
                  fontSize: '16px',
                  fontSmoothing: 'antialiased',
                  '::placeholder': {
                    color: '#999',
                  },
                  ':-webkit-autofill': {
                    color: '#999',
                  },
                },
                invalid: {
                  iconColor: 'rgb(226, 62, 76)',
                  color: 'rgb(226, 62, 76)',
                },
              },
            }}
          />
        </div>
        <div style={{ textAlign: 'center', paddingTop: 20 }}>
          <Button onClick={onSave} disabled={!stripe || state === 'loading'} size="small">
            {state === 'loading' ? `Processing...` : `Update Card`}
          </Button>
        </div>
      </Form>
    </EditProfileModalContainer>
  );
};

export const ManageModalEditCard: React.FunctionComponent<{ onDone: () => void }> = props => {
  const stripePromise = React.useRef<Promise<Stripe | null>>(loadStripe(STRIPE_PUBLIC));
  return (
    <Elements stripe={stripePromise.current}>
      <ManageModalEditCardInner {...props} />
    </Elements>
  );
};

const EditProfileModalContainer = styled(SheetModal)`
  text-align: left;
  max-width: 600px;
`;
