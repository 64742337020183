import { Spinner } from '@blueprintjs/core';
import { ResponsiveLine } from '@nivo/line';
import moment from 'moment';
import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { ClubContext } from '../../App';
import { BrandTint } from '../../Colors';
import { formatPrice } from '../../Utils';
import { DashboardDateRange, DAY_SEC } from '../PageClubDashboard';

const summarizeForPeriod = (stats: CoreAPI.DailyStat[], start: number, end: number) => {
  const initSummary: CoreAPI.EarningsStats & Pick<CoreAPI.DailyStat, 'newMemberCount'> = {
    earningsBeforeRefunds: 0,
    refunds: 0,
    paymentFees: 0,
    platformFees: 0,
    artistEarnings: 0,
    labelEarnings: 0,
    avgSpendPerCustomer: 0,
    newMemberCount: 0,
  };

  const filteredStats = stats.filter(({ date }) => date > start && date <= end);

  if (!filteredStats.length) return initSummary;

  let daysWithEarnings = 0;
  const summary = filteredStats.reduce((acc, s) => {
    const { earnings, newMemberCount } = s;
    if (earnings) {
      if (earnings.artistEarnings) daysWithEarnings++;
      const keys = Object.keys(earnings) as (keyof CoreAPI.EarningsStats)[];
      keys.forEach(k => (acc[k] += earnings?.[k] || 0));
    }
    acc.newMemberCount += newMemberCount;
    return acc;
  }, initSummary);

  if (daysWithEarnings) {
    summary.avgSpendPerCustomer = summary.avgSpendPerCustomer / daysWithEarnings;
  }

  return summary;
};

export const CardSetFinance: React.FunctionComponent<{
  data: CoreAPI.AdminClubStatsResponse | undefined;
  dateRange: DashboardDateRange;
}> = ({ data, dateRange: [start, end] }) => {
  if (!data || !start || !end) {
    return (
      <SpinnerContainer>
        <Spinner size={50} />
      </SpinnerContainer>
    );
  }

  const summary = summarizeForPeriod(data.dailyStats, start, end);

  const daily = [];
  let day = start;
  while (day < end) {
    const unix = day;
    const summary = summarizeForPeriod(data.dailyStats, unix, unix + DAY_SEC);
    daily.push({ x: moment.unix(unix).format('YYYY-MM-DD'), y: summary.earningsBeforeRefunds });
    day += DAY_SEC;
  }

  return (
    <div style={{ display: 'flex', gap: 20 }}>
      <div style={{ flex: 3, height: 340 }}>
        <ResponsiveLine
          margin={{ top: 10, right: 20, bottom: 60, left: 40 }}
          animate
          enableGridX={false}
          enableSlices={false}
          isInteractive={true}
          useMesh={true}
          enableArea={true}
          colors={[BrandTint]}
          data={[{ id: 'Earnings Before Refunds', data: daily }]}
          xScale={{ type: 'time', format: '%Y-%m-%d', useUTC: false, precision: 'day' }}
          xFormat="time:%Y-%m-%d"
          yScale={{
            type: 'linear',
            stacked: false,
            min: 0,
            max: Math.max(Math.max(...daily.map(d => d.y)) * 1.2, 10000),
          }}
          axisLeft={{ format: value => formatPrice(Number(value), 'dollars'), tickValues: 4 }}
          axisBottom={{
            format: '%b %d',
            tickValues: end - start < 15 * DAY_SEC ? 'every 1 days' : undefined,
          }}
          curve={'monotoneX'}
          defs={[
            {
              id: 'gradientA',
              type: 'linearGradient',
              colors: [
                { offset: 0, color: 'inherit', opacity: 1 },
                { offset: 100, color: 'inherit', opacity: 0.5 },
              ],
            },
          ]}
          fill={[{ match: '*', id: 'gradientA' }]}
          tooltip={props => (
            <div
              style={{
                background: 'white',
                fontSize: 12,
                fontWeight: 500,
                padding: 2,
                boxShadow: '0 1px 1px rgba(0,0,0,0.1)',
              }}
            >
              {props.point.serieId}: {formatPrice(Number(props.point.data.y))}
            </div>
          )}
          pointSize={3}
          pointBorderWidth={1}
          pointBorderColor={{ from: 'color', modifiers: [['darker', 0.3]] }}
        />
      </div>
      <div style={{ width: '14%' }}>
        <StatHeader>Earnings Before Refunds</StatHeader>
        <Stat>{formatPrice(summary.earningsBeforeRefunds)}</Stat>
        <StatHeader>Artist Earnings</StatHeader>
        <Stat>{formatPrice(summary.artistEarnings)}</Stat>
        <StatHeader>Spend per customer</StatHeader>
        <Stat>{formatPrice(summary.avgSpendPerCustomer)}</Stat>
        <StatHeader>Total subscribers</StatHeader>
        <Stat>{data.memberCount}</Stat>
        <StatHeader>New subscribers</StatHeader>
        <Stat>{summary.newMemberCount}</Stat>
      </div>
    </div>
  );
};

export const CardSetFinanceMonthlyTable: React.FunctionComponent<{
  data: CoreAPI.AdminClubStatsResponse | undefined;
}> = ({ data }) => {
  const club = useContext(ClubContext);

  if (!data) {
    return (
      <SpinnerContainer>
        <Spinner size={50} />
      </SpinnerContainer>
    );
  }

  if (!club.config.earnings) return <div />;

  const { labelPercent } = club.config.earnings;

  const monthly = [];
  for (let ii = -5; ii <= 0; ii++) {
    const start = moment().add(ii, 'months').startOf('month');
    const summary = summarizeForPeriod(
      data.dailyStats,
      start.unix(),
      start.clone().endOf('month').unix()
    );
    monthly.push({ label: start.format('MMM YYYY'), summary });
  }

  return (
    <div style={{ display: 'flex' }}>
      <StatTable>
        <thead>
          <tr>
            <td>Month</td>
            <td>Earnings Before Refunds</td>
            <td>Refunds</td>
            <td>Payment Fees</td>
            <td>Platform Fees</td>
            {!!labelPercent && <td>Label Earnings</td>}
            <td>Artist Earnings</td>
          </tr>
        </thead>
        <tbody>
          {monthly.map(({ label, summary }) => (
            <tr key={label}>
              <td style={{ textAlign: 'left' }}>{label}</td>
              <td>{formatPrice(summary.earningsBeforeRefunds)}</td>
              <td>{formatPrice(summary.refunds)}</td>
              <td>{formatPrice(summary.paymentFees)}</td>
              <td>{formatPrice(summary.platformFees)}</td>
              {!!labelPercent && <td>{formatPrice(summary.labelEarnings)}</td>}
              <td style={{ fontWeight: 600 }}>{formatPrice(summary.artistEarnings)}</td>
            </tr>
          ))}
        </tbody>
      </StatTable>
    </div>
  );
};

const StatTable = styled.table`
  width: 100%;
  line-height: 1.4em;
  border: 1px solid #ccc;
  thead td {
    border-bottom: 1px solid #ccc;
    background: #eee;
    font-weight: 600;
    padding: 4px 8px;
    text-align: center;
    min-width: 85px;
    width: 15%;
  }
  td {
    padding: 8px;
    text-align: right;
  }
`;

const StatHeader = styled.h3`
  margin-bottom: 0;
  font-size: 14px;
`;
const Stat = styled.div`
  font-feature-settings: tnum;
  font-variant: tabular-nums;
  color: ${BrandTint};
  font-size: 20px;
`;

const SpinnerContainer = styled.div`
  flex: 1;
  height: 340px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
