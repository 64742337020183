import React, { useCallback, useContext } from 'react';
import { SessionContext } from '../App';
import { makeRequest, useResource } from '../Resource';

export interface UnreadNotifs {
  unreadCountsByChannel: Record<string, number>;
  actions?: {
    refreshNotifs: () => void;
    readChannelNotif: (channelKey: string) => Promise<void>;
  };
}

export function useUnreadNotifs(clubId: number): UnreadNotifs {
  const [session] = useContext(SessionContext);
  const [unreadCountsByChannel = {}, { refresh: refreshNotifs, applyLocalUpdates }] = useResource<
    UnreadNotifs['unreadCountsByChannel']
  >(`/api/v1/clubs/${clubId}/unread`);

  const unreadCountsJSON = JSON.stringify(unreadCountsByChannel);
  const readChannelNotif = useCallback(
    async (key: string) => {
      const unreadCountForKey = JSON.parse(unreadCountsJSON)[key];
      if (unreadCountForKey === 0) {
        return;
      }
      try {
        const updatedCounts = await makeRequest<UnreadNotifs['unreadCountsByChannel']>(
          `/api/v1/clubs/${clubId}/read`,
          'PUT',
          { key }
        );
        setTimeout(() => applyLocalUpdates(updatedCounts), 500);
      } catch (err) {
        // network error? oh well
      }
    },
    [applyLocalUpdates, clubId, unreadCountsJSON]
  );

  const actions = React.useMemo(() => {
    return session.user ? { refreshNotifs, readChannelNotif } : undefined;
  }, [session.user, refreshNotifs, readChannelNotif]);

  return {
    unreadCountsByChannel,
    actions,
  };
}
