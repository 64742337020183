import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { SessionContext } from '../App';
import { ClubRoot } from '../pages-club/ClubRoot';
import { ExplorePage } from './ExplorePage';
import { ForgotCompletePage } from './ForgotCompletePage';
import { ForgotPage } from './ForgotPage';
import { LoginPage } from './LoginPage';
import { LogoutPage } from './LogoutPage';
import { ManagePage } from './ManagePage';
import { PrivacyPage } from './PrivacyPage';
import { RegisterPage } from './RegisterPage';
import { TermsPage } from './TermsPage';

export const PublicRoot: React.FunctionComponent<{}> = props => {
  const [session] = React.useContext(SessionContext);

  return (
    <Switch>
      <Route path="/privacy-policy" component={PrivacyPage} />
      <Route path="/terms" component={TermsPage} />
      <Route path="/explore" component={ExplorePage} />
      <Route path="/logout" component={LogoutPage} />
      <Route path="/register" component={RegisterPage} />
      <Route path="/login" component={LoginPage} />
      <Route path="/forgot" component={ForgotPage} />
      <Route path="/forgot-complete" component={ForgotCompletePage} />
      <Redirect
        path="/"
        exact
        to={
          session.user
            ? session.clubs.length === 1
              ? `/${session.clubs[0].slug}`
              : '/manage'
            : '/login'
        }
      />
      {session.user ? (
        <Route path="/manage" component={ManagePage} />
      ) : (
        <Redirect path="/manage" to="/login?next=/manage" />
      )}
      <Route path="/:slug" component={ClubRoot} />
    </Switch>
  );
};
