import React from 'react';
import styled from 'styled-components/macro';
import { hexWithOpacity } from './Common';

type ButtonIntent = 'primary' | 'none';
type ButtonSize = 'small' | 'normal';

interface ButtonProps
  extends Omit<
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    'ref'
  > {
  intent?: ButtonIntent;
  size?: ButtonSize;
}

export const Button: React.FunctionComponent<ButtonProps> = ({
  intent,
  children,
  size,
  ...rest
}) => {
  return (
    <ButtonContainer intent={intent} size={size} {...rest}>
      {children}
    </ButtonContainer>
  );
};

export const ButtonContainer = styled.button<{ intent?: ButtonIntent; size?: ButtonSize }>`
  display: inline-block;
  text-align: center;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  font-weight: 600;
  font-size: ${({ size }) => (size === 'small' ? '12px' : '14px')};
  height: ${({ size }) => (size === 'small' ? '24px' : '36px')};
  padding: ${({ size }) => (size === 'small' ? '0 16px' : '0 24px')};
  border-radius: 20px;
  border: 1px solid ${hexWithOpacity('#ffffff', 0.3)};
  color: ${hexWithOpacity('#ffffff', 0.9)};
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.01rem;
  transition: opacity 150ms linear;

  ${({ intent, theme }) =>
    intent === 'primary'
      ? `background: ${theme.tintColor};`
      : `background: white;
        color: #111;`}

  &:hover {
    opacity: 0.5;
  }
  &:disabled {
    background: #eee;
    pointer-events: none;
    opacity: unset;
  }
`;
