import React from 'react';

export const AudioIcon = require('./../images/audio.svg').default;
export const PhotoIcon = require('./../images/photo.svg').default;
export const VideoIcon = require('./../images/video.svg').default;
export const VerifiedIcon = require('./../images/verified.svg').default;
export const Ellipsis = require('./../images/ellipsis.svg').default;

export const IconReply: React.FunctionComponent<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    width="18"
    height="18"
    viewBox="0 0 24 24"
    fill="currentColor"
    stroke="none"
    {...props}
  >
    <path d="M11,8.503V2c0-0.202-0.122-0.385-0.309-0.462c-0.188-0.078-0.402-0.034-0.545,0.108l-10,10  c-0.195,0.195-0.195,0.512,0,0.707l10,10C10.242,22.449,10.37,22.5,10.5,22.5c0.064,0,0.129-0.012,0.191-0.038  C10.878,22.385,11,22.202,11,22v-6.482c2.166,0.108,10.617,0.836,12.02,5.623c0.07,0.239,0.307,0.393,0.551,0.354  C23.817,21.46,24,21.249,24,21C24,9.529,13.342,8.574,11,8.503z" />
  </svg>
);

export const IconEdit: React.FunctionComponent<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
    <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
    <line x1="16" y1="5" x2="19" y2="8" />
  </svg>
);

export const IconPlus: React.FunctionComponent<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <line x1="12" y1="5" x2="12" y2="19" />
    <line x1="5" y1="12" x2="19" y2="12" />
  </svg>
);

export const IconRemove: React.FunctionComponent<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <line x1="4" y1="7" x2="20" y2="7" />
    <line x1="10" y1="11" x2="10" y2="17" />
    <line x1="14" y1="11" x2="14" y2="17" />
    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
  </svg>
);
