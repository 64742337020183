import React from 'react';
import uuid from 'uuid';

export const MuxVideo: React.FunctionComponent<
  CoreAPI.MuxVideo & {
    aspectClassName: string;
    autoPlay: boolean;
  }
> = ({ aspectClassName, muxPlaybackID, autoPlay, thumbnailSeconds }) => {
  const videoId = React.useRef(`video-${uuid.v4()}`);

  React.useEffect(() => {
    if (muxPlaybackID) {
      const player = (window as any).videojs(videoId.current);
      const containerSize = player.el().parentElement.getBoundingClientRect();
      player.width(containerSize.width);
      player.height(containerSize.height);
    }
  }, [muxPlaybackID]);

  return (
    <video
      id={videoId.current}
      style={{ overflow: 'hidden' }}
      controls
      className={`video-js ${aspectClassName}`}
      preload="auto"
      autoPlay={autoPlay}
      poster={`https://image.mux.com/${muxPlaybackID}/thumbnail.jpg${
        thumbnailSeconds ? `?time=${thumbnailSeconds}` : ''
      }`}
    >
      <source src={`https://stream.mux.com/${muxPlaybackID}.m3u8`} type="application/x-mpegURL" />
      <p className="vjs-no-js">
        To view this video please enable JavaScript, and consider upgrading to a web browser that
        <a
          href="https://videojs.com/html5-video-support/"
          rel="noopener noreferrer"
          target="_blank"
        >
          supports HTML5 video
        </a>
      </p>
    </video>
  );
};
