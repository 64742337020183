import React from 'react';
import { InfoRow } from './Common';

export const ProfileDetails: React.FC<{ user: CoreAPI.CurrentUser }> = ({ user }) => {
  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <InfoRow>
            <label>Username</label>
            <div>{user.username}</div>
          </InfoRow>
          <InfoRow>
            <label>Email Address</label>
            <div>{user.email}</div>
          </InfoRow>
          <InfoRow>
            <label>Phone Number</label>
            <div>{user.phoneNumber || <em>None</em>}</div>
          </InfoRow>
          <InfoRow>
            <label>Bio</label>
            <div>{user.bio || <em>None</em>}</div>
          </InfoRow>
        </div>
        <div style={{ flex: 1 }}>
          <InfoRow>
            <label>Name</label>
            <div>
              {user.firstName} {user.lastName}
            </div>
          </InfoRow>
          <InfoRow>
            <label>Hometown</label>
            <div>{user.hometown || <em>None</em>}</div>
          </InfoRow>
          <InfoRow>
            <label>Shipping Address</label>
            {user.shippingAddress ? (
              <Address address={user.shippingAddress} />
            ) : (
              <div>
                <em>None</em>
              </div>
            )}
          </InfoRow>
        </div>
      </div>
    </>
  );
};

const Address: React.FunctionComponent<{ address: CoreAPI.Address }> = ({ address }) => (
  <div style={{ whiteSpace: 'pre-wrap' }}>
    {`${address.addressLine1}${address.addressLine2 ? '\n' : ''}${address.addressLine2}\n${
      address.city
    }, ${address.state} ${address.zip}, ${address.country}`}
  </div>
);
