import qs from 'query-string';
import React from 'react';
import { RouteChildrenProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Button } from '../components/Button';
import { Form } from '../components/Common';
import { makeRequest } from '../Resource';
import { AuthPageContainer } from './AuthPageContainer';

export const ForgotPage: React.FunctionComponent<RouteChildrenProps<{}>> = ({ location }) => {
  const [result, setResult] = React.useState('');

  const emailRef = React.createRef<HTMLInputElement>();
  const email = qs.parse(location.search)['email'] as string;

  const onSubmit = async (e: React.FormEvent<any>) => {
    e.preventDefault();

    const payload = {
      email: emailRef.current?.value,
    };
    if (!payload.email) {
      setResult('Please enter an email address.');
      return;
    }
    const resp = await makeRequest<{ error?: string; success: boolean }>(
      `/api/v1/forgot`,
      'POST',
      payload
    );
    if (resp.error) {
      setResult(resp.error);
    } else {
      setResult('All set! Check your email for a link to reset your password.');
    }
  };

  return (
    <AuthPageContainer location={location}>
      <Form onSubmit={onSubmit}>
        <h1 style={{ textAlign: 'center' }}>Reset your Password</h1>

        {result ? <div className="alert alert-danger">{result}</div> : undefined}

        <div className="group" style={{ marginTop: 8, marginBottom: 8 }}>
          <label className="form-block">
            <div>Email</div>
            <input
              ref={emailRef}
              defaultValue={email}
              className="field"
              type="text"
              name="username"
              autoCapitalize="off"
              autoComplete="email"
              placeholder="hello-world@domain.com"
            />
          </label>
        </div>
        <div style={{ clear: 'both' }}></div>
        <input type="submit" style={{ display: 'none' }} />
        <Button intent="primary" style={{ width: '100%' }} onClick={onSubmit}>
          <div className="loader-container"></div>
          Email Reset Link
        </Button>

        <hr style={{ marginTop: 40, marginBottom: 40 }} />

        <div style={{ textAlign: 'center' }}>
          <p>Just getting started?</p>
          <Link to="/register">
            <Button style={{ width: '100%' }}>Create an account</Button>
          </Link>
        </div>
      </Form>
    </AuthPageContainer>
  );
};
