import { CompositeDecorator, ContentBlock, ContentState, Editor, EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { omit } from 'lodash';
import React, { CSSProperties } from 'react';
import styled from 'styled-components/macro';
import { NativeBridge } from '../../NativeBridge';
import { makeRequest } from '../../Resource';
import { hexWithOpacity } from '../Common';

interface DecoratedTextInputProps {
  initialValue: string;
  onChange: (text: string) => void;
  placeholder: string;
  style?: CSSProperties;
}
interface DecoratedTextInputState {
  editorState: EditorState;
}

export class DecoratedTextInput extends React.Component<
  DecoratedTextInputProps,
  DecoratedTextInputState
> {
  constructor(props: DecoratedTextInputProps) {
    super(props);

    this.state = {
      editorState: EditorState.createWithContent(
        ContentState.createFromText(props.initialValue),
        compositeDecorator
      ),
    };
  }

  get value() {
    return this.state.editorState.getCurrentContent().getPlainText();
  }

  editor = React.createRef<Editor>();

  focus = () => this.editor.current?.focus();

  logState = () => console.log(this.state.editorState.toJS());

  render() {
    return (
      <DecoratedTextInputContainer style={omit(this.props.style, 'marginTop')} onClick={this.focus}>
        <div style={{ marginTop: this.props.style?.marginTop }}>
          <Editor
            editorState={this.state.editorState}
            onChange={editorState => {
              this.setState({ editorState });
              this.props.onChange(editorState.getCurrentContent().getPlainText());
            }}
            placeholder={this.props.placeholder}
            ref={this.editor}
            spellCheck={true}
          />
        </div>
      </DecoratedTextInputContainer>
    );
  }
}

const DecoratedTextInputContainer = styled.div`
  border-radius: 10px;
  flex: 1;
  font-family: Manrope;
  background: ${hexWithOpacity('#ffffff', 0.9)};
  cursor: text;
  padding: 0 10px;
  color: ${hexWithOpacity('#000000', 0.9)};

  @media (prefers-color-scheme: dark) {
    background: #111;
    border: 1px solid #666;
    color: white;
    .AdminRoot & {
      background: white;
      border: 1px solid #ddd;
      color: initial;
    }
  }
`;

/**
 * Super simple decorators for handles and hashtags, for demonstration
 * purposes only. Don't reuse these regexes.
 */
export const HANDLE_REGEX = /@[\w_-]+/g;
export const HASHTAG_REGEX = /#[\w\u0590-\u05ff]+/g;
export const URL_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;

function findWithRegex(
  regex: RegExp,
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void
) {
  const text = contentBlock.getText();
  let matchArr, start;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

export const HandleSpan: React.FunctionComponent<{ offsetKey: string }> = props => {
  return (
    <span
      style={{ color: '#106ba3', direction: 'ltr', unicodeBidi: 'bidi-override' }}
      data-offset-key={props.offsetKey}
    >
      {props.children}
    </span>
  );
};

const HashtagSpan: React.FunctionComponent<{ offsetKey: string }> = props => {
  return (
    <span style={{ color: '#106ba3' }} data-offset-key={props.offsetKey}>
      {props.children}
    </span>
  );
};

const URLSpan: React.FunctionComponent<{ offsetKey: string }> = props => {
  return (
    <span
      style={{ color: '#007acc', textDecoration: 'underline' }}
      data-offset-key={props.offsetKey}
    >
      {props.children}
    </span>
  );
};

const compositeDecorator = new CompositeDecorator([
  {
    strategy: (contentBlock, callback, contentState) => {
      findWithRegex(URL_REGEX, contentBlock, callback);
    },
    component: URLSpan,
  },
  {
    strategy: (contentBlock, callback, contentState) => {
      findWithRegex(HANDLE_REGEX, contentBlock, callback);
    },
    component: HandleSpan,
  },
  {
    strategy: (contentBlock, callback, contentState) => {
      findWithRegex(HASHTAG_REGEX, contentBlock, callback);
    },
    component: HashtagSpan,
  },
]);

const Matchers: { regexp: RegExp; buildWrap: (text: string) => HTMLElement }[] = [
  {
    regexp: URL_REGEX,
    buildWrap: text => {
      const wrap = document.createElement('a');
      wrap.style.color = '#96ccff';
      wrap.style.fontSize = '14px';
      wrap.setAttribute('target', '_blank');
      wrap.setAttribute('rel', 'nofollow noreferer');
      wrap.setAttribute('href', text);
      return wrap;
    },
  },
  {
    regexp: HANDLE_REGEX,
    buildWrap: text => {
      const wrap = document.createElement('span');
      wrap.style.color = '#B980FF';
      wrap.addEventListener('click', async e => {
        const user = await makeRequest<CoreAPI.User>(
          `/api/v1/users/by-username/${text.replace('@', '')}`
        );
        if (user) {
          NativeBridge.isAvailable() && NativeBridge.viewProfile(user.id);
        }
      });
      return wrap;
    },
  },
  {
    regexp: HASHTAG_REGEX,
    buildWrap: text => {
      const wrap = document.createElement('span');
      wrap.style.color = '#9eebcf';
      wrap.style.cursor = 'default';
      return wrap;
    },
  },
];

export const DecoratedTextBlock: React.FunctionComponent<{ text: string }> = ({ text }) => {
  const ref = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    if (!ref.current) return;
    let nodes = Array.from(ref.current.childNodes);
    for (const node of nodes) {
      if (node.nodeType === Node.TEXT_NODE && node.textContent) {
        for (const { regexp, buildWrap } of Matchers) {
          const match = new RegExp(regexp).exec(node.textContent);
          if (match) {
            const before = node;
            const handle = (before as Text).splitText(match.index);
            const after = (handle as Text).splitText(match[0].length);
            const wrap = buildWrap(match[0]);
            handle.parentNode!.replaceChild(wrap, handle);
            wrap.append(handle);
            nodes.push(after);
          }
        }
      } else {
        nodes.push(...Array.from(node.childNodes));
      }
    }
  }, []);

  return (
    <div
      style={{ userSelect: 'text', whiteSpace: 'pre-wrap', wordBreak: 'break-word', fontSize: 15 }}
      dangerouslySetInnerHTML={{ __html: text }}
      ref={ref}
    />
  );
};
