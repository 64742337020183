import { Navbar } from '@blueprintjs/core';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

export const NavContainer: React.FunctionComponent<{ user: CoreAPI.CurrentUser }> = ({
  user,
  children,
}) => (
  <AdminNavbar>
    <Navbar.Group>
      <Navbar.Heading className="hide-small">
        <Link to={'/admin'}>
          <img
            alt="logo"
            src={require('../images/logo-black.png').default}
            style={{ height: 20, marginTop: 3 }}
          />
        </Link>
      </Navbar.Heading>
      <Navbar.Divider className="hide-small" />
      {children}
    </Navbar.Group>
    <div style={{ flex: 1, minWidth: 12 }} />
    <Navbar.Group style={{ minWidth: 0 }}>
      <span
        className="hide-small"
        title={user.email}
        style={{ minWidth: 0, overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {user.email}
      </span>

      <Navbar.Divider className="hide-small" />
      <a
        className="hide-small"
        href={`https://withtheband.zendesk.com/hc/en-us`}
        rel="noopener noreferrer"
        target="_blank"
      >
        Help &amp; Support
      </a>
      <Navbar.Divider />
      <Link to="/logout">Logout</Link>
    </Navbar.Group>
  </AdminNavbar>
);

export const NavItems: React.FunctionComponent<{ items: { name: string; path: string }[] }> = ({
  items,
}) => {
  const history = useHistory();
  const { pathname } = useLocation();

  return (
    <div style={{ position: 'relative', display: 'inline-block', lineHeight: 2.6 }}>
      <div className="bp3-tab-list bp3-large">
        {items.map(tab => (
          <a
            key={tab.name}
            title={tab.name}
            className={`bp3-tab ${pathname.includes(tab.path) && 'active'}`}
            style={{ lineHeight: `50px`, textDecoration: 'none' }}
            href={tab.path}
            onClick={e => {
              history.push(tab.path);
              e.preventDefault();
            }}
          >
            {tab.name}
          </a>
        ))}
      </div>
    </div>
  );
};

export const AdminNavbar = styled(Navbar)`
  display: flex;
  white-space: nowrap;
  box-shadow: none;
  border-bottom: 1px solid #ccc;

  .bp3-navbar-group {
    display: flex;
  }
  @media (max-width: 1100px) {
    & .hide-small {
      display: none;
    }
  }
  @media (max-width: 700px) {
    height: 100px;
    .bp3-tab {
      font-size: 14px;
      margin-right: 10px;
    }
  }
`;
