import {
  Button,
  Checkbox,
  Colors,
  Icon,
  InputGroup,
  Intent,
  Popover,
  PopoverInteractionKind,
  Position,
} from '@blueprintjs/core';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components/macro';
import { v4 as uuidv4 } from 'uuid';
import { AppToaster, SessionContext } from '../../App';
import { BrandAlphaSteps } from '../../Colors';
import { useViewerCount } from '../../Hooks';
import { makeRequest, useResource } from '../../Resource';
import { SocialsConfig, formatPrice } from '../../Utils';
import { DashboardBox, Sheet } from '../../components/Common';
import { JoinModal, hasOnlyFreeTier } from '../../components/JoinModal';
import { StringArrayInput } from '../../components/StringArrayInput';
import {
  ColorInput,
  FormLabel,
  MultiLineInput,
  NumberInput,
  SingleLineInput,
  SingleSelectEnumInput,
  ThumbnailInput,
} from '../../components/TextInputs';
import { VideoDropzoneInput } from '../../components/VideoDropzoneInput';
import { PageContainer } from '../PageHome';
import { ChannelEditor } from './ChannelEditor';
import { RewardsEditor } from './RewardsEditor';
import { cloneDeep } from 'lodash';

const Toolbar = {
  options: ['inline', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'remove', 'history'],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['bold', 'italic', 'underline', 'strikethrough'],
  },
};

export const EMPTY_CLUB: CoreAPI.Club = {
  id: -1,
  createdAt: 0,
  updatedAt: 0,
  featured: false,
  published: false,
  tiers: [],
  slug: '',
  name: '',
  config: {
    earnings: { billingCycle: 'monthly', platformPercent: 0.25, labelPercent: 0 },
    channels: [{ key: 'main', slug: 'main', label: 'Artist Posts', restricted: true }],
    sections: [{ type: 'feed', label: 'Posts' }],
    rewards: [],
    heroImageURL: 'https://app.withtheband.co/public/images/crowd.jpg',
    heroNavColor: '#FCFCFC',
    heroNavLogoURL: '',
    mobileNavTintColor: '#FFFFFF',
    mobileNavBarTintColor: '#D0021B',
    tintColor: '#FF0030',
    bgColor: '#F5A623',
    themeMode: 'dark',
    welcomeVideo: null,
    welcomeCopy: {
      sections: [
        {
          title: 'Exclusive Content',
          description:
            'We post on social media all over the place, but we post an inside look here on With the Band.',
        },
        {
          title: 'Behind the Scenes',
          description: "See clips of our recordings and studio time you won't find anywhere else.",
        },
        {
          title: 'Meet & Greet',
          description:
            'Get to know the band! We do short meet-and-greet sessions with random fans from With the Band every month.',
        },
        {
          title: 'Unique Merch',
          description: "Join the crew to receive merch we're not selling at the shows!",
        },
      ],
    },
    postMeetCopy:
      "Hope you enjoyed meeting the artist! We'll send you a recording of your virtual meet and greet via email.",
    artistUserId: null,
    social: {
      twitter: '',
      instagram: '',
      facebook: '',
      website: '',
    },
    summaryEmailRecipients: [],
    summaryEmailCadence: 'week',
  },
};

export const PageClubSettings = (props: RouteComponentProps<{ clubId: string }>) => {
  const { clubId } = props.match.params;
  const isEditing = clubId !== 'add';
  const [clubs = []] = useResource<CoreAPI.Club[]>('/api/admin/v1/clubs');

  const { banner } = useViewerCount(isEditing ? clubId : undefined);

  const onClubCreated = (id: number) => {
    props.history.replace(`/admin/clubs/${id}`);
  };

  return (
    <PageContainer style={{ paddingTop: banner ? 0 : undefined }}>
      {isEditing ? (
        <div>
          {clubs
            .filter(r => r.id === Number(clubId))
            .map(r => (
              <React.Fragment key={r.id}>
                {banner}
                <ClubForm key={r.id} club={r} onClubCreated={onClubCreated} />
              </React.Fragment>
            ))}
        </div>
      ) : (
        <ClubForm club={EMPTY_CLUB} onClubCreated={onClubCreated} />
      )}
    </PageContainer>
  );
};

const ClubForm: React.FunctionComponent<{
  club: CoreAPI.Club;
  onClubCreated: (id: number) => void;
}> = props => {
  const [session] = React.useContext(SessionContext);
  const [club, setClub] = React.useState<CoreAPI.Club>(props.club);
  const [previewingTiers, setPreviewingTiers] = React.useState(false);
  const [saving, setSaving] = React.useState<boolean>(false);
  const formRef = React.useRef<HTMLFormElement | null>(null);

  const fanCreatedClub = hasOnlyFreeTier(club);

  const save = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const update = cloneDeep(club);
    if (update.config.rewards?.length) {
      update.config.rewards = update.config.rewards.reduce((acc, r) => {
        if (r.name.length && r.tiers.length) {
          if (r.rewardId.startsWith('new')) r.rewardId = `reward-${Date.now()}`;
          acc.push(r);
        }
        return acc;
      }, [] as CoreAPI.ClubConfig['rewards']);
    }

    setSaving(true);
    let clubReceived = await makeRequest<CoreAPI.Club>(
      `/api/admin/v1/clubs/${club.id === -1 ? '' : club.id}`,
      'PUT',
      update
    );
    setSaving(false);
    if (clubReceived.id) {
      showSavedToast(true);
      setClub(clubReceived);
      if (club.id === -1) {
        props.onClubCreated(clubReceived.id);
      }
    } else {
      showSavedToast(false);
    }
  };

  const destroy = async () => {
    setSaving(true);
    await makeRequest<CoreAPI.Club>(`/api/admin/v1/clubs/${club.id}`, 'DELETE');
    setSaving(false);
    window.location.href = '/admin/clubs';
  };

  const upsertConfig = (config: Partial<CoreAPI.ClubConfig>) => {
    setClub({ ...club, config: { ...club.config, ...config } });
  };

  const showSavedToast = (success: boolean) => {
    AppToaster.show({
      intent: success ? Intent.SUCCESS : Intent.DANGER,
      message: success ? 'Club saved' : 'Club failed to save',
    });
  };

  return (
    <form
      ref={formRef}
      onSubmit={save}
      onKeyPress={e => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
    >
      <div style={{ display: 'flex', marginBottom: 20, alignItems: 'center' }}>
        <h2 className="bp3-heading">Settings</h2>
        <div style={{ flex: 1 }} />
        <Button icon={'floppy-disk'} disabled={saving} type={'submit'} intent="primary">
          Save
        </Button>
      </div>
      <div style={{ display: 'flex', alignItems: 'stretch', gap: 30 }}>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <h3>General</h3>
          <DashboardBox>
            <SingleLineInput
              label={'Name'}
              value={club.name}
              onChange={e => setClub({ ...club, name: e })}
              required={true}
            />
            <div style={{ display: 'flex', alignItems: 'center', gap: 30 }}>
              <div style={{ flex: 1 }}>
                <SingleLineInput
                  label={'Slug'}
                  value={club.slug}
                  onChange={e => setClub({ ...club, slug: e })}
                  required={true}
                />
                <em style={{ position: 'relative', color: Colors.GRAY3 }}>
                  eg: https://crew.withtheband.co/{club.slug || 'abbeyroad'}
                </em>
              </div>
              {session.user?.role === 'admin' && (
                <>
                  <Checkbox
                    label="Published"
                    checked={club.published}
                    onChange={e => setClub({ ...club, published: !club.published })}
                  />
                  <div
                    style={{
                      display: 'flex',
                      margin: -10,
                      background: 'antiquewhite',
                      padding: 10,
                    }}
                  >
                    <Checkbox
                      label="Featured"
                      checked={club.featured}
                      onChange={e => setClub({ ...club, featured: !club.featured })}
                    />
                  </div>
                </>
              )}
            </div>
            <SingleLineInput
              label="Meta (Facebook) Pixel Id"
              value={club.config.pixelId || ''}
              onChange={e => upsertConfig({ pixelId: e })}
            />

            <StringArrayInput
              label="Activity Summary Recipients"
              values={club.config.summaryEmailRecipients || []}
              onChange={e => upsertConfig({ summaryEmailRecipients: e })}
            />
            <SingleSelectEnumInput
              label="Activity Summary Cadence"
              value={club.config.summaryEmailCadence}
              options={['day', 'week']}
              onChange={e => upsertConfig({ summaryEmailCadence: e })}
            />
          </DashboardBox>
        </div>

        <div style={{ flex: 0.7 }}>
          <h3>Administrators</h3>
          <DashboardBox style={{ display: 'flex', flexDirection: 'column' }}>
            <ClubAdminsEditor
              club={club}
              artistUserId={club.config.artistUserId}
              setArtistUserId={u => upsertConfig({ artistUserId: u })}
            />
          </DashboardBox>
        </div>
      </div>

      <div style={{ height: 30 }} />

      <h3>Social Links</h3>
      <DashboardBox>
        {[SocialsConfig.slice(0, 1), SocialsConfig.slice(1, 5), SocialsConfig.slice(5)].map(
          (slice, idx) => (
            <div style={{ display: 'flex', marginRight: -30 }} key={`slice-${idx}`}>
              {slice.map(({ key, label, icon }) => (
                <React.Fragment key={key}>
                  {icon && (
                    <img
                      alt={`${key} logo`}
                      src={icon}
                      style={{ marginTop: 12, marginRight: 8, opacity: 0.7 }}
                    />
                  )}
                  <div style={{ flex: 1, marginRight: 30 }}>
                    <SingleLineInput
                      label={`${label} URL`}
                      value={club.config.social[key] || ''}
                      onChange={e => upsertConfig({ social: { ...club.config.social, [key]: e } })}
                    />
                  </div>
                </React.Fragment>
              ))}
            </div>
          )
        )}
      </DashboardBox>

      <div style={{ height: 30 }} />

      <div style={{ display: 'flex', gap: 30 }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h3>Styling</h3>
          <DashboardBox>
            <ThumbnailInput
              label={'Hero Image URL'}
              format={'png'}
              desiredSize={{ width: 1200, height: 500 }}
              value={club.config.heroImageURL}
              onChange={e => upsertConfig({ heroImageURL: e || '' })}
            />
            <div style={{ display: 'flex' }}>
              <ColorInput
                label={'Background Color'}
                value={club.config.bgColor}
                onChange={e => upsertConfig({ bgColor: e })}
                required={true}
              />
              <div style={{ width: 10 }} />
              <ColorInput
                label={'Header Text Color'}
                value={club.config.heroNavColor}
                onChange={e => upsertConfig({ heroNavColor: e })}
                required={true}
              />
              <div style={{ width: 10 }} />
              <ColorInput
                label={'Tint Color'}
                value={club.config.tintColor}
                onChange={e => upsertConfig({ tintColor: e })}
                required={true}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <ColorInput
                label={'Mobile Nav Background'}
                value={club.config.mobileNavBarTintColor}
                onChange={e => upsertConfig({ mobileNavBarTintColor: e })}
                required={true}
              />
              <div style={{ width: 5 }} />
              <ColorInput
                label={'Mobile Nav Tint Color'}
                value={club.config.mobileNavTintColor}
                onChange={e => upsertConfig({ mobileNavTintColor: e })}
                required={true}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ marginTop: 5 }}>
                <FormLabel>Welcome Video</FormLabel>
                <VideoDropzoneInput
                  style={{ marginRight: 10, width: 104, height: 150 }}
                  value={club.config.welcomeVideo}
                  onChange={welcomeVideo => upsertConfig({ welcomeVideo: welcomeVideo || null })}
                />
              </div>
              <div style={{ width: 5 }} />
              <ThumbnailInput
                label={'Logo / Brand Image URL'}
                format={'png'}
                value={club.config.heroNavLogoURL}
                onChange={e => upsertConfig({ heroNavLogoURL: e || '' })}
              />
            </div>
          </DashboardBox>
        </div>
        <div style={{ flex: 1, flexDirection: 'column', display: 'flex' }}>
          <h3>Channels &amp; Links</h3>
          <DashboardBox style={{ padding: 0, flex: 1, display: 'flex' }}>
            <SectionsEditor club={club} onChange={setClub} />
          </DashboardBox>
        </div>
      </div>

      {!fanCreatedClub && (
        <>
          <div style={{ height: 30 }} />

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h3>Custom Copy</h3>
          </div>
          <DashboardBox>
            <div>
              <FormLabel>Post Meet &amp; Greet Message</FormLabel>
              <SingleLineInput
                value={club.config.postMeetCopy}
                onChange={e => upsertConfig({ postMeetCopy: e })}
              />
            </div>
            <h4 style={{ marginTop: 30, marginBottom: 10 }}>Welcome Text</h4>
            <div style={{ display: 'flex' }}>
              {club.config.welcomeCopy?.sections.map((s, idx) => {
                return (
                  <div key={`welcome-copy-${idx}`} style={{ width: '25%', marginRight: 10 }}>
                    <SingleLineInput
                      label={`Section ${idx + 1}`}
                      value={s.title}
                      onChange={e => {
                        const updatedSections = club.config.welcomeCopy!.sections.slice();
                        updatedSections[idx] = { ...s, title: e };
                        upsertConfig({ welcomeCopy: { sections: updatedSections } });
                      }}
                    />

                    <MultiLineInput
                      style={{
                        resize: 'vertical',
                        minHeight: 80,
                        maxHeight: 192,
                        fontFamily: 'sans-serif',
                        fontSize: 14,
                      }}
                      value={s.description}
                      onChange={e => {
                        const updatedSections = club.config.welcomeCopy!.sections.slice();
                        updatedSections[idx] = { ...s, description: e };
                        upsertConfig({ welcomeCopy: { sections: updatedSections } });
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </DashboardBox>
          <div style={{ height: 30 }} />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h3>Membership Tiers</h3>
            <div style={{ flex: 1 }} />
            <Button icon="eye-on" onClick={() => setPreviewingTiers(true)}>
              Preview Pricing Panel
            </Button>
          </div>
          <Sheet visible={previewingTiers} onClick={() => setPreviewingTiers(false)}>
            <JoinModal club={{ ...club, membership: null }} visible={previewingTiers} />
          </Sheet>

          <DashboardBox style={{ padding: 0 }}>
            <TiersEditor club={club} onChange={setClub} />
          </DashboardBox>

          <div style={{ display: 'flex', alignItems: 'center', marginTop: 30 }}>
            <h3>Membership Rewards</h3>
          </div>
          <DashboardBox>
            <RewardsEditor
              tiers={club.tiers}
              rewards={club.config.rewards}
              updateRewards={rewards => {
                upsertConfig({ rewards });
              }}
            />
          </DashboardBox>
        </>
      )}

      <div style={{ height: 30 }} />

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h3>Danger Zone</h3>
      </div>
      <Popover
        interactionKind={PopoverInteractionKind.CLICK}
        popoverClassName="bp3-popover-content-sizing"
        position={Position.TOP}
      >
        <Button icon="trash">Delete Fan Crew</Button>
        <div>
          <h4>Are you sure?</h4>
          <p>
            If there are paying members of your fan crew, be sure to cancel their subscriptions in
            Stripe first.
          </p>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }} />
            <Button className="bp3-popover-dismiss">Dismiss</Button>
            <div style={{ width: 10 }} />
            <Button className="bp3-popover-dismiss" intent="danger" onClick={() => destroy()}>
              Delete
            </Button>
          </div>
        </div>
      </Popover>

      <div style={{ height: 30 }} />
    </form>
  );
};

const EMPTY_TIER = () =>
  ({
    name: 'Untitled Tier',
    imageURL: null,
    key: `tier-${uuidv4().split('-')[0]}`,
    description: '',
    price: 0,
    iap: { productId: '' },
    stripe: { productId: '', priceId: '' },
  } as CoreAPI.ClubMembershipTier);

const TiersEditor: React.FunctionComponent<{
  club: CoreAPI.Club;
  onChange: (club: CoreAPI.Club) => void;
}> = ({ club, onChange }) => {
  const [editingIdx, setEditingIdx] = React.useState(0);

  if (!club.config.earnings) return <div />;

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1, display: 'flex' }}>
        <TierList style={{ flex: 1 }}>
          {club.tiers.map((t, idx) => (
            <TierListItem key={idx} active={idx === editingIdx} onClick={() => setEditingIdx(idx)}>
              {t.name}
              <div style={{ flex: 1 }} />
              <div style={{ opacity: 0.4 }}>{formatPrice(t.price)}</div>
            </TierListItem>
          ))}
          <TierListItem
            active={false}
            onClick={() => {
              onChange({ ...club, tiers: [EMPTY_TIER(), ...club.tiers] });
            }}
          >
            Add Tier
            <div style={{ flex: 1 }} />
            <Icon icon="plus" iconSize={15} style={{ opacity: 0.4 }} />
          </TierListItem>
        </TierList>
      </div>
      <div style={{ flex: 3 }}>
        <TierEditor
          key={editingIdx}
          idx={editingIdx}
          billingCycle={club.config.earnings.billingCycle}
          tier={club.tiers[editingIdx]}
          onRemove={() => {
            const next = [...club.tiers.slice(0, editingIdx), ...club.tiers.slice(editingIdx + 1)];
            onChange({ ...club, tiers: next });
            setEditingIdx(Math.min(next.length - 1, Math.max(0, editingIdx)));
          }}
          onChange={(tier: CoreAPI.ClubMembershipTier) => {
            onChange({
              ...club,
              tiers: [
                ...club.tiers.slice(0, editingIdx),
                tier,
                ...club.tiers.slice(editingIdx + 1),
              ],
            });
          }}
        />
      </div>
    </div>
  );
};

const TierList = styled.div`
  overflow: scroll;
  border-right: 1px solid #eee;
`;

const TierListItem = styled.div<{ active: boolean }>`
  align-items: center;
  display: flex;
  padding: 17px 20px;
  border-bottom: 1px solid #eee;
  background: ${({ active }) => (active ? BrandAlphaSteps[3] : 'white')};
  &:hover {
    background: ${({ active }) => (active ? BrandAlphaSteps[4] : BrandAlphaSteps[1])};
  }
`;

const TierEditor: React.FunctionComponent<{
  idx: number;
  billingCycle: CoreAPI.ClubEarnings['billingCycle'];
  tier: CoreAPI.ClubMembershipTier;
  onChange: (tier: CoreAPI.ClubMembershipTier) => void;
  onRemove: () => void;
}> = ({ idx, billingCycle, tier, onChange, onRemove }) => {
  const [session] = React.useContext(SessionContext);
  const [editorState, setEditorState] = React.useState(() => {
    const { contentBlocks, entityMap } = htmlToDraft(tier.description);
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    return EditorState.createWithContent(contentState);
  });

  return (
    <div style={{ padding: 24 }}>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <h3 style={{ margin: 0, marginBottom: 15 }}>Tier {idx + 1}</h3>
        <div style={{ flex: 1 }} />
        <Button
          icon="trash"
          onClick={() => window.confirm('Are you sure you want to remove this tier?') && onRemove()}
        >
          Remove
        </Button>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <SingleLineInput
            required
            label={'Name'}
            value={tier.name}
            onChange={e => onChange({ ...tier, name: e })}
          />
          <div style={{ width: 140, marginTop: 20 }}>
            <NumberInput
              required
              label={`${billingCycle === 'monthly' ? 'Monthly' : 'Yearly'} Price (USD)`}
              value={tier.price / 100}
              onChange={e => onChange({ ...tier, price: e * 100 })}
            />
          </div>
        </div>
        <div style={{ width: 20 }} />
        <ThumbnailInput
          label={'Name Artwork'}
          format={'png'}
          desiredSize={{ width: 850, height: 560 }}
          value={tier.imageURL || ''}
          onChange={e => onChange({ ...tier, imageURL: e ? e : null })}
        />
      </div>
      <StyledEditorContainer>
        <Editor
          editorState={editorState}
          toolbar={Toolbar}
          onEditorStateChange={state => {
            setEditorState(state);
            onChange({
              ...tier,
              description: draftToHtml(convertToRaw(state.getCurrentContent())),
            });
          }}
        />
      </StyledEditorContainer>
      {session.user?.role === 'admin' && (
        <div style={{ display: 'flex', marginTop: 20, background: 'antiquewhite', padding: 10 }}>
          <SingleLineInput
            label={'Stripe Product ID'}
            value={tier.stripe.productId}
            onChange={e => onChange({ ...tier, stripe: { ...tier.stripe, productId: e } })}
          />
          <div style={{ width: 10 }} />
          <SingleLineInput
            label={'Stripe Price ID'}
            value={tier.stripe.priceId}
            onChange={e => onChange({ ...tier, stripe: { ...tier.stripe, priceId: e } })}
          />
          <div style={{ width: 10 }} />
          <SingleLineInput
            label={'Apple IAP Product ID'}
            value={tier.iap.productId}
            onChange={e => onChange({ ...tier, iap: { ...tier.iap, productId: e } })}
          />
        </div>
      )}
    </div>
  );
};

const StyledEditorContainer = styled.div`
  & .rdw-editor-toolbar {
    padding: 0;
    margin-bottom: 0;
  }
  & .rdw-inline-wrapper,
  & .rdw-list-wrapper,
  & .rdw-fontsize-wrapper,
  & .rdw-text-align-wrapper,
  & .rdw-colorpicker-wrapper,
  & .rdw-link-wrapper,
  & .rdw-remove-wrapper,
  & .rdw-history-wrapper {
    margin-bottom: 0;
  }
  & .rdw-dropdown-wrapper {
    height: 20px;
  }
  & .rdw-option-wrapper {
    padding: 0px;
    margin: 0 1px;
  }
  & .rdw-option-active {
    background: ${BrandAlphaSteps[3]};
    box-shadow: none;
  }
  & .rdw-editor-main {
    border: 1px solid #f1f1f1;
    border-top: 0;
  }
  & .rdw-editor-main {
    height: 220px;
  }
`;

export function arrayUpdatingIdx<T>(arr: T[], idx: number, item: Partial<T>) {
  return [...arr.slice(0, idx), { ...arr[idx], ...item }, ...arr.slice(idx + 1)];
}

const SectionsEditor: React.FunctionComponent<{
  club: CoreAPI.Club;
  onChange: (club: CoreAPI.Club) => void;
}> = ({ club, onChange }) => {
  const [editingIdx, setEditingIdx] = React.useState(0);

  const sections = club.config.sections || [];
  const section = sections[editingIdx];

  const onChangeSections = (sections: CoreAPI.ClubConfig['sections']) => {
    onChange({ ...club, config: { ...club.config, sections } });
  };
  React.useEffect(() => {
    if (!sections.find(s => s.type === 'feed')) {
      onChangeSections([{ type: 'feed', label: 'Posts' }, ...sections]);
    }
  }, []);

  return (
    <>
      <div style={{ flex: 1, display: 'flex' }}>
        <TierList style={{ flex: 1, minWidth: 180 }}>
          {sections.map((s, idx) => (
            <TierListItem key={idx} active={idx === editingIdx} onClick={() => setEditingIdx(idx)}>
              {s.label}
            </TierListItem>
          ))}
          <TierListItem
            active={false}
            onClick={() => {
              onChangeSections([...sections, { type: 'web', url: '', label: 'Untitled' }]);
              setEditingIdx(sections.length);
            }}
          >
            Add Section
            <div style={{ flex: 1 }} />
            <Icon icon="plus" iconSize={15} style={{ opacity: 0.4 }} />
          </TierListItem>
        </TierList>
      </div>
      <div style={{ flex: 2, padding: 20 }}>
        {section && (
          <>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <div style={{ flex: 1 }} />
              <Button
                icon="trash"
                disabled={section.type === 'feed'}
                onClick={() =>
                  window.confirm('Are you sure you want to remove this section?') &&
                  onChangeSections([
                    ...sections.slice(0, editingIdx),
                    ...sections.slice(editingIdx + 1),
                  ])
                }
              >
                Remove
              </Button>
            </div>

            <SingleLineInput
              required
              label={'Tab Label'}
              value={section.label}
              onChange={e => {
                onChangeSections(arrayUpdatingIdx(sections, editingIdx, { label: e }));
              }}
            />
            {section.type === 'feed' ? (
              <div>
                <p>
                  This is your feed tab - it displays your posts and hosts member discussions. This
                  tab always appears first.
                </p>
                <ChannelEditor
                  channels={club.config.channels || []}
                  onChange={channels => {
                    onChange({ ...club, config: { ...club.config, channels } });
                  }}
                />
              </div>
            ) : (
              <SingleLineInput
                required
                label={'Webpage URL'}
                value={section.url}
                onChange={e => {
                  onChangeSections(arrayUpdatingIdx(sections, editingIdx, { type: 'web', url: e }));
                }}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

const ClubAdminsEditor: React.FunctionComponent<{
  club: CoreAPI.Club;
  artistUserId: null | number;
  setArtistUserId: (val: number | null) => void;
}> = ({ club, artistUserId, setArtistUserId }) => {
  const [admins, ops] = useResource<
    (CoreAPI.ClubMembership & { user: CoreAPI.CurrentUser })[],
    { username: string }
  >(`/api/admin/v1/clubs/${club.id}/admins`);

  const values = admins || [];
  return (
    <div>
      <p>Administrators can access the dashboard and can post in all discussion channels.</p>

      <table style={{ width: '100%', marginBottom: 12 }}>
        <thead style={{ textAlign: 'left' }}>
          <tr>
            <th style={{ width: 30 }} />
            <th>Username</th>
            <th>Email</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {values.map(a => (
            <tr key={a.id}>
              <td>
                <Icon
                  title="Mark the artist with a star to make them the default poster"
                  icon={artistUserId === a.user.id ? 'star' : 'star-empty'}
                  onClick={() => setArtistUserId(artistUserId === a.user.id ? null : a.user.id)}
                />
              </td>
              <td>{a.user.username}</td>
              <td>{a.user.email}</td>
              <td>
                <Button small onClick={() => ops.deleteItem(a.id)}>
                  <Icon icon="trash" />
                </Button>
              </td>
            </tr>
          ))}
          {!values.length && (
            <tr>
              <td colSpan={3}>
                <em>No administrators have been added.</em>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <AddAdminButton
        onCreate={async username => {
          const resp = await ops.post({ username });
          if ('error' in resp) {
            alert((resp as any)['error']);
          }
        }}
      />
    </div>
  );
};

const AddAdminButton: React.FunctionComponent<{
  onCreate: (username: string) => void;
}> = props => {
  const [username, setUsername] = React.useState('');
  return (
    <Popover
      content={
        <form
          style={{ padding: 10, display: 'flex', alignItems: 'center' }}
          onSubmit={async e => {
            e.preventDefault();
            e.stopPropagation();
            props.onCreate(username);
          }}
        >
          <InputGroup
            type="text"
            autoFocus
            value={username}
            placeholder="Username"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
          />
          <div style={{ width: 10 }} />
          <Button small type="submit" intent="primary">
            Add
          </Button>
        </form>
      }
    >
      <Button small>Add Admin</Button>
    </Popover>
  );
};
