import React from 'react';
import { PageListTable } from '../pages-dashboard/Components';
import styled from 'styled-components/macro';

function getRaw<T, U>(m: T, context: U, col: SortableTableColumn<T, U>) {
  return col.raw instanceof Function ? col.raw(m, context) : `${col.render(m, context)}`;
}

function getFilterValue<T, U>(m: T, context: U, col: SortableTableColumn<T, U>) {
  const raw = col.raw instanceof Function ? col.raw(m, context) : null;
  if (typeof raw === 'string' || typeof raw === 'number') {
    return `${raw}`.toLowerCase();
  }
  return `${col.render(m, context)}`.toLowerCase();
}

function compare(a: string | number | Date, b: string | number | Date) {
  if (a instanceof Date && b instanceof Date) {
    return a < b ? 1 : -1;
  }
  if (typeof a === 'number' && typeof b === 'number') {
    return b - a;
  }
  return `${a}`.localeCompare(`${b}`);
}

export function filterWithSearch<T, U>(
  data: T[],
  context: U,
  columns: SortableTableColumn<T, U>[],
  searchVal: string
) {
  return data.filter(a =>
    columns.some(c => getFilterValue(a, context, c).includes(searchVal.toLowerCase()))
  );
}

export interface SortableTableColumn<T, U> {
  label: string;
  width?: number | string;
  render: (m: T, context: U) => React.ReactNode;
  raw?: (m: T, context: U) => string | number | Date;
}

export function SortableTable<T, U>(props: {
  data: T[];
  dataKey: (item: T) => string | number;
  context: U;
  columns: SortableTableColumn<T, U>[];
  searchVal: string;
  onRow?: (item: T) => void;
}) {
  const { data, dataKey, columns, searchVal, context, onRow } = props;
  const [sortLabel, setSortLabel] = React.useState<string>(columns[0].label);
  const [sortDirection, setSortDirection] = React.useState<number>(1);
  const sortColumn = columns.find(c => c.label === sortLabel)!;

  const filtered = filterWithSearch(data, context, columns, searchVal);

  const sorted = filtered.sort(
    (a, b) =>
      compare(getRaw(a, context, sortColumn), getRaw(b, context, sortColumn)) * sortDirection
  );

  return (
    <PageListTable>
      <thead>
        <tr>
          {columns.map(c => (
            <th
              key={c.label}
              onClick={() =>
                sortLabel === c.label ? setSortDirection(-sortDirection) : setSortLabel(c.label)
              }
              style={{
                width: c.width,
                fontWeight: sortLabel === c.label ? 600 : 400,
                userSelect: 'none',
                whiteSpace: 'nowrap',
              }}
            >
              {c.label} {sortLabel === c.label && (sortDirection === 1 ? '▼' : '▲')}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {sorted.map(m => (
          <Row key={dataKey(m)} clickable={!!onRow} onClick={() => onRow && onRow(m)}>
            {columns.map(c => (
              <td key={c.label}>{c.render(m, context)}</td>
            ))}
          </Row>
        ))}
      </tbody>
    </PageListTable>
  );
}

const Row = styled.tr<{ clickable?: boolean }>`
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'unset')};
  &:hover {
    opacity: ${({ clickable }) => (clickable ? 0.45 : 'unset')};
  }
`;
