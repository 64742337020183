import React from 'react';
import qs from 'query-string';
import Player, { PlayerVars } from 'react-youtube';

export const Youtube: React.FunctionComponent<{ url: string }> = props => {
  const videoId = qs.parseUrl(props.url).query['v'];
  if (!videoId || !(typeof videoId === 'string')) {
    return <span />;
  }
  const opts = {
    height: '390',
    width: '100%',
    playerVars: { modestbranding: 1, playsinline: 0 } as PlayerVars,
  };

  return <Player videoId={videoId} opts={opts} />;
};
