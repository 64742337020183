import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useResource } from '../Resource';
import { PageContainer } from './PageHome';
import { PageListHeader, PageListTable } from './Components';

const TypeDescriptions = {
  visit: 'Opened App',
  play: 'Watched Episode',
};

export const PageUserActivity: React.FunctionComponent<
  RouteComponentProps<{
    userId: string;
  }>
> = props => {
  const [searchVal, setSearchVal] = React.useState<string>('');
  const userId = props.match.params.userId;
  const [activity] = useResource<CoreAPI.UserAction[]>(`/api/admin/v1/users/${userId}/activity`);

  return (
    <PageContainer>
      <PageListHeader title="User Activity" filter={searchVal} onChangeFilter={setSearchVal}>
        <div style={{ width: 10 }} />
      </PageListHeader>
      <PageListTable>
        <thead>
          <tr>
            <th>Action</th>
            <th>Time</th>
            <th>Info</th>
          </tr>
        </thead>
        <tbody>
          {(activity || []).map(a => (
            <tr>
              <td>{TypeDescriptions[a.type]}</td>
              <td>{new Date(a.createdAt).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </PageListTable>
    </PageContainer>
  );
};
