import { Button, Classes, Colors, Dialog } from '@blueprintjs/core';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { SingleLineInput } from '../components/TextInputs';
import { makeRequest, useResource } from '../Resource';
import { PageListHeader, PageListTable } from './Components';
import { PageContainer } from './PageHome';
import { EMPTY_CLUB } from './settings/PageClubSettings';

export const PageClubs = (props: RouteComponentProps<{}>) => {
  const url = '/api/admin/v1/clubs';
  const [clubs] = useResource<CoreAPI.Club[]>(url);
  const [filter, setFilter] = React.useState<string>('');
  const [creating, setCreating] = React.useState(false);

  const onEditClick = (club: CoreAPI.Club) => {
    props.history.push(`/admin/clubs/${club.id}/dashboard`);
  };

  const onPostAndEdit = async (payload: CoreAPI.CreateClubPayload) => {
    const newClub = await makeRequest<CoreAPI.Club | { error: string }>(url, 'POST', payload);
    if ('id' in newClub) {
      // also triggers a reload of the clubs context
      window.location.href = `/admin/clubs/${newClub.id}/settings`;
    } else {
      alert(newClub.error);
    }
  };

  return (
    <PageContainer>
      {!!creating && (
        <CreateCrewDialog onCreate={onPostAndEdit} onClose={() => setCreating(false)} />
      )}
      <PageListHeader
        title="Crews"
        filter={filter}
        onChangeFilter={setFilter}
        onAdd={() => setCreating(true)}
      >
        <div style={{ width: 10 }} />
        <Button onClick={() => (window.location.href = `${url}.xlsx`)}>Export XLSX</Button>
      </PageListHeader>

      <PageListTable>
        <thead>
          <tr>
            <th>Title</th>
            <th>URL</th>
            <th style={{ width: 80 }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {(clubs || [])
            .filter(f => !filter || f.name.toLowerCase().includes(filter.toLowerCase()))
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(club => (
              <ClubsTableRow
                key={club.id}
                club={club}
                onEditClick={onEditClick}
                onDuplicateClick={() => onPostAndEdit({ duplicateClubId: club.id })}
              />
            ))}
        </tbody>
      </PageListTable>
    </PageContainer>
  );
};

const ClubsTableRow: React.FunctionComponent<{
  club: CoreAPI.Club;
  onDuplicateClick: (club: CoreAPI.Club) => void;
  onEditClick: (club: CoreAPI.Club) => void;
}> = ({ club, onEditClick, onDuplicateClick }) => {
  return (
    <tr>
      <td>{club.name}</td>
      <td>
        <a
          href={`https://app.withtheband.co/${club.slug}`}
        >{`https://app.withtheband.co/${club.slug}`}</a>
      </td>
      <td>
        <div style={{ display: 'flex' }}>
          <Button
            onClick={(c: React.MouseEvent) => {
              onDuplicateClick(club);
            }}
            icon={'duplicate'}
          >
            Duplicate
          </Button>
          <div style={{ width: 10 }} />
          <Button
            onClick={(c: React.MouseEvent) => {
              onEditClick(club);
            }}
            icon={'edit'}
          >
            Manage
          </Button>
        </div>
      </td>
    </tr>
  );
};

export const CreateCrewDialog: React.FC<{
  onCreate: (payload: CoreAPI.CreateClubPayload) => Promise<void>;
  onClose: () => void;
}> = ({ onCreate, onClose }) => {
  const [values, setValues] = React.useState<{ slug: string; name: string }>({
    slug: '',
    name: '',
  });

  return (
    <Dialog onClose={onClose} isOpen={true} title="Create Crew">
      <div className={Classes.DIALOG_BODY}>
        <div style={{ flex: 2 }}>
          <SingleLineInput
            label={'Name'}
            value={values.name}
            onChange={e => setValues({ ...values, name: e })}
            required={true}
          />
        </div>
        <div style={{ width: 30 }} />
        <div style={{ flex: 1 }}>
          <SingleLineInput
            label={'Slug'}
            value={values.slug}
            onChange={e => setValues({ ...values, slug: e })}
            required={true}
          />
          <em style={{ position: 'relative', top: -8, color: Colors.GRAY3 }}>
            eg: https://crew.withtheband.co/{values.slug || 'abbeyroad'}
          </em>
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER} style={{ display: 'flex' }}>
        <div style={{ flex: 1 }} />
        <Button onClick={onClose}>Cancel</Button>
        <div style={{ width: 20 }} />
        <Button
          onClick={() =>
            onCreate({
              ...values,
              config: { ...EMPTY_CLUB.config, welcomeCopy: null },
            })
          }
          intent="primary"
        >
          Create
        </Button>
      </div>
    </Dialog>
  );
};
