import React from 'react';
import styled from 'styled-components/macro';
import { ClubContext } from '../../App';
import { NativeBridge } from '../../NativeBridge';
import { ResourceOps } from '../../Resource';
import { hexWithOpacity } from '../Common';
import { ProfilePicture } from '../ProfilePicture';
import { Comment, CommentContainer, CommentForm } from './Comment';
import { checkMembership } from './PostPaywall';

export const Comments: React.FunctionComponent<{
  responses: CoreAPI.PostResponse[];
  poster: CoreAPI.User | null;
  ops: ResourceOps<CoreAPI.PostResponse[], CoreAPI.UpsertPostResponsePayload>;
  adminOptions: boolean;
}> = ({ responses, poster, ops, adminOptions }) => {
  const [expanded, setExpanded] = React.useState(false);
  const [replyingToId, setReplyingToId] = React.useState<null | number>(null);
  const club = React.useContext(ClubContext);
  const { isActive } = checkMembership(club);

  const comments = responses
    .filter(r => r.details.type === 'comment')
    .sort((a, b) => a.createdAt - b.createdAt);

  const replies = comments.filter(
    c => (c.details as CoreAPI.PostResponseDetailsComment).parentResponseId
  );

  let toplevel = comments.filter(
    c => !(c.details as CoreAPI.PostResponseDetailsComment).parentResponseId
  );

  const displayed: React.ReactNode[] = [];

  if (!expanded && toplevel.length > 7) {
    const cut = toplevel.length - 5;
    toplevel = toplevel.slice(toplevel.length - 5);
    displayed.push(
      <MoreCommentsBar key={'more'} onClick={() => setExpanded(true)}>
        {`Show older comments (${cut})`}
      </MoreCommentsBar>
    );
  }

  const pushCommentAndChildren = (comment: CoreAPI.PostResponse, indent: number) => {
    displayed.push(
      <Comment
        poster={poster}
        indent={indent}
        key={comment.id}
        response={comment}
        adminOptions={adminOptions}
        onEdit={d => ops.putItem({ ...d, id: comment.id }) as any}
        onReply={indent === 0 ? () => setReplyingToId(comment.id) : undefined}
        onDelete={() =>
          (NativeBridge.isAvailable() || window.confirm(CONFIRM_TEXT)) && ops.deleteItem(comment.id)
        }
      />
    );
    for (const c of replies) {
      if ((c.details as CoreAPI.PostResponseDetailsComment).parentResponseId === comment.id) {
        pushCommentAndChildren(c, indent + 1);
      }
    }
    if (poster && replyingToId === comment.id) {
      displayed.push(
        <CommentContainer key={'reply'} indent={indent + 1}>
          <div style={{ paddingTop: 6 }}>
            <ProfilePicture user={poster} size={25} />
          </div>
          <CommentForm
            key={`response-${comment.id}`}
            autoFocus
            initial={{
              text: `@${comment.user.username} `,
              type: 'comment',
              parentResponseId: comment.id,
            }}
            onSave={details => ops.post({ details, userId: poster.id })}
          />
        </CommentContainer>
      );
    }
  };

  for (const comment of toplevel) {
    pushCommentAndChildren(comment, 0);
  }

  return (
    <CommentsContainer style={{ filter: isActive ? 'unset' : 'blur(4px)' }}>
      {displayed}
      {poster ? (
        <CommentContainer indent={0}>
          <div style={{ paddingTop: 6 }}>
            <ProfilePicture user={poster} size={25} />
          </div>
          <CommentForm
            key={'new'}
            initial={{ text: '', type: 'comment', parentResponseId: null }}
            onSave={details => ops.post({ details, userId: poster.id })}
          />
        </CommentContainer>
      ) : (
        <CommentContainer indent={0}>
          <span>Sign in to post a comment.</span>
        </CommentContainer>
      )}
    </CommentsContainer>
  );
};

const CommentsContainer = styled.div`
  display: block;
  margin-top: 12px;
  padding-top: 4px;
  border-radius: 12px;
  background: ${({ theme }) => hexWithOpacity(theme.bgColor, 0.7)};
  background-blend-mode: multiply;
  background-image: ${({ theme }) =>
    theme.themeMode === 'dark'
      ? `linear-gradient(
    0deg,
    ${hexWithOpacity('#000000', 0.6)},
    ${hexWithOpacity('#000000', 0.6)}
  )`
      : `linear-gradient(
    0deg,
    ${hexWithOpacity('#ffffff', 0.4)},
    ${hexWithOpacity('#ffffff', 0.4)}
  )`};
  @media (prefers-color-scheme: dark) {
    color: white;
    background: rgb(30, 30, 30);
    .AdminRoot & {
      background: rgba(255, 255, 255, 0.55);
      color: black;
    }
  }
`;

const MoreCommentsBar = styled.div`
  padding: 6px 40px;
  opacity: 0.5;
  font-size: 0.8rem;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const CONFIRM_TEXT = `Are you sure you want to delete this comment?`;
