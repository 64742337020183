import React from 'react';

export function useScrollToFeedTarget(
  ref: React.ForwardedRef<any>,
  loaded: boolean,
  target: CoreAPI.FeedTarget | null,
  setTarget?: (t: CoreAPI.FeedTarget | null) => void
) {
  const containerRef = React.createRef<HTMLDivElement>();

  const scrollToTarget = React.useCallback(
    (target: CoreAPI.FeedTarget) => {
      if (target.type === 'post') {
        const post = containerRef.current?.querySelector(`[data-post-id="${target.id}"]`);
        if (!post) {
          console.warn(`Unable to find post: ${target.id}`);
          return;
        }
        post.scrollIntoView();
        setTarget?.(null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [containerRef]
  );
  // Bind a global to scrollToTarget so we can call it from iOS / Android native code.
  // Bind it to the public ref so you can say feedRef.scrollToTarget()
  React.useImperativeHandle(ref, () => ({ scrollToTarget }));
  React.useEffect(() => {
    (window as any).scrollToTarget = scrollToTarget;
  });

  // Scroll to initial target once posts load
  React.useEffect(() => {
    target && loaded && scrollToTarget(target);
  }, [scrollToTarget, target, loaded]);

  return { containerRef };
}
