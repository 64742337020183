import React from 'react';
import { Button, InputGroup, HTMLSelect } from '@blueprintjs/core';
import { BrandAlphaSteps } from '../../Colors';

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const PostPollComposer: React.FunctionComponent<{
  club: CoreAPI.Club;
  values: CoreAPI.PostDetailsPoll;
  onChange: (values: CoreAPI.PostDetailsPoll) => void;
  onRemove: () => void;
}> = ({ club, values, onChange, onRemove }) => {
  return (
    <div style={{ background: BrandAlphaSteps[0], marginTop: 18, padding: 10 }}>
      {values.choices.map((c, idx) => (
        <div key={idx} style={{ display: 'flex', alignItems: 'center', margin: 8 }}>
          <strong>
            {`${ALPHABET.split('')[idx]}):`}
            &nbsp;
          </strong>
          <InputGroup
            type="text"
            maxLength={200}
            fill
            value={c}
            onChange={(e: React.ChangeEvent<any>) =>
              onChange({
                ...values,
                choices: [
                  ...values.choices.slice(0, idx),
                  e.target.value,
                  ...values.choices.slice(idx + 1),
                ],
              })
            }
          />
          <div style={{ width: 30 }}>
            {values.choices.length > 2 && (
              <Button
                minimal
                icon="remove"
                onClick={() =>
                  onChange({
                    ...values,
                    choices: [...values.choices.slice(0, idx), ...values.choices.slice(idx + 1)],
                  })
                }
              />
            )}
          </div>
        </div>
      ))}
      <Button
        minimal
        style={{ marginLeft: 20 }}
        icon="add"
        onClick={() =>
          onChange({
            ...values,
            choices: [...values.choices, ''],
          })
        }
      >
        Add Option
      </Button>
      <div
        style={{
          borderTop: '1px solid rgba(0,0,0,0.2)',
          paddingTop: 18,
          marginTop: 8,
          display: 'flex',
        }}
      >
        <div style={{ flex: 2 }}>
          <strong>Poll Length</strong>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <div>Days</div>
              <HTMLSelect fill>
                {[0, 1, 2, 3, 4, 5, 6, 7].map(days => (
                  <option key={days} value={days}>
                    {days}
                  </option>
                ))}
              </HTMLSelect>
            </div>
            <div style={{ width: 5 }} />
            <div style={{ flex: 1 }}>
              <div>Hours</div>
              <HTMLSelect fill>
                {Array(24)
                  .fill(1)
                  .map((_, idx) => (
                    <option key={idx} value={idx}>
                      {idx}
                    </option>
                  ))}
              </HTMLSelect>
            </div>
            <div style={{ width: 5 }} />
            <div style={{ flex: 1 }}>
              <div>Minutes</div>
              <HTMLSelect fill>
                {Array(60)
                  .fill(1)
                  .map((_, idx) => (
                    <option key={idx} value={idx}>
                      {idx}
                    </option>
                  ))}
              </HTMLSelect>
            </div>
          </div>
        </div>
        <div style={{ width: 15 }} />
        <div style={{ flex: 1 }}>
          <strong>Minimum Membership Tier</strong>
          <div>Required to respond</div>
          <HTMLSelect fill>
            {club.tiers.map((tier, idx) => (
              <option key={idx} value={tier.key}>
                {tier.name}
              </option>
            ))}
          </HTMLSelect>
        </div>
      </div>
      <div
        style={{
          borderTop: '1px solid rgba(0,0,0,0.2)',
          paddingTop: 8,
          marginTop: 8,
          display: 'flex',
        }}
      >
        <Button onClick={onRemove} intent="danger" minimal fill>
          Remove Poll
        </Button>
      </div>
    </div>
  );
};
