import React from 'react';
import { useResource } from '../Resource';
import { PageContainer } from './PageHome';
import { PageListHeader, PageListTable } from './Components';
import { NonIdealState } from '@blueprintjs/core';
import { RouteComponentProps } from 'react-router-dom';

export const PageClubModeration: React.FunctionComponent<
  RouteComponentProps<{
    clubId: string;
  }>
> = props => {
  const url = `/api/admin/v1/clubs/${props.match.params.clubId}/content-reports`;
  const [reports] = useResource<CoreAPI.ContentReport[]>(url);
  const [searchVal, setSearchVal] = React.useState<string>('');

  return (
    <PageContainer>
      <PageListHeader
        title="Content Reports"
        filter={searchVal}
        onChangeFilter={setSearchVal}
      ></PageListHeader>
      <PageListTable>
        <thead>
          <tr>
            <th>Offensive Item</th>
            <th>Offensive Content</th>
            <th>Reported By</th>
          </tr>
        </thead>
        <tbody>
          {(reports || [])
            .filter(a =>
              `${a.contentText}|${a.reportingUser.username}|${a.contentUser?.username}`.includes(
                searchVal
              )
            )
            .map(item => (
              <tr key={item.id}>
                <td>
                  <strong>{item.contentUser?.username}</strong>
                  <br />
                  {item.contentType} {item.contentId}
                </td>
                <td>{item.contentText}</td>
                <td>{item.reportingUser.username}</td>
              </tr>
            ))}
        </tbody>
      </PageListTable>
      {reports && reports.length === 0 && (
        <div style={{ marginTop: 30 }}>
          <NonIdealState
            title="No Content Reports"
            description="No fans have reported content as inappropriate or blocked users."
          />
        </div>
      )}
    </PageContainer>
  );
};
