import styled from 'styled-components/macro';

export const MoreActions = styled.ul`
  display: none;
  position: absolute;
  margin: 0;
  padding-left: 0;
  background: rgba(255, 255, 255, 0.85);
  right: 0;
  top: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  text-shadow: none;
  z-index: 12;

  & li {
    color: #111;
    white-space: nowrap;
    text-transform: none;
    font-size: 14px;
    padding: 8px 10px;
    display: block;
  }
  & li:hover {
    background: rgba(125, 125, 125, 0.1);
  }
`;

export const MoreActionsDropdown = styled.div`
  position: relative;
  padding: 5px;
  border-radius: 3px;
  align-self: flex-start;
  & .title {
    opacity: 0.5;
  }
  &:hover,
  &:active {
    background: rgba(0, 0, 0, 0.1);
    & .title {
      opacity: 1;
    }
  }
  &:active ${MoreActions}, &:hover ${MoreActions} {
    display: block;
  }
`;
