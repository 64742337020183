import React from 'react';
import { RouteChildrenProps } from 'react-router';
import { CenteredContainer, CenteredMaxWidth, WhiteShadowedBox } from '../components/Common';

const PRIVACY_POLICY = `
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
@page WordSection1
	{size:8.5in 11.0in;
	margin:1.0in 1.0in 1.0in 1.0in;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style><div class="WordSection1">

<p class="MsoNormal" style="text-align:center" align="center"><b><span style="font-size:14.0pt">Privacy Policy</span></b></p>

<p class="MsoNormal" style="text-align:center" align="center">&nbsp;</p>

<p class="MsoNormal" style="text-align:center" align="center">&nbsp;</p>

<p class="MsoNormal">The Privacy Policy is placed in effect as of November 5,
2018 (the “Effective Date”). </p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoListParagraph" style="text-align:center;text-indent:
-.25in" align="center"><b>1.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b>Introduction</b></p>

<p class="MsoNormal" style="text-align:center" align="center">&nbsp;</p>

<p class="MsoNormal">With the Band always endeavors to protect and respect your
privacy. We offer an innovative way for music fans to interact with one
another, meet up with their favorite artists, and collaborate on fan projects
through our online Platform. Our “Platform” includes any website, application,
or service we offer, including content we offer and electronic communications
we send. The terms “With the Band,”, “we,” “us,” and “our,” include With the
Band, LLC and our affiliates. We use the terms “you” and “your” to mean any
person using our Platform.</p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">In this Privacy Policy (together with our Terms and
Conditions and other documents related to it), we describe how we collect,
collate, use, and disclose information obtained through our Platform from you
and other visitors. By visiting, viewing, downloading, and/or using our
Platform, you agree that you consent to such use. If you do not agree and
consent to the terms contained in this Privacy Policy, please stop using the
application immediately. </p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoListParagraph" style="text-align:center;text-indent:
-.25in" align="center"><b>2.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b>Collection of Information</b></p>

<p class="MsoNormal" style="text-align:center" align="center">&nbsp;</p>

<p class="MsoNormal">2.1 <u>Information You Provide to Us</u></p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">We collect information that you provide directly to us. For
example, we collect information that you provide when you create an account,
fill out a form, or communicate with us. The types of information we may collect
include: your name, username, password, email address, age, postal code,
payment method data, favorite artists, and any other information that you
choose to provide. </p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">2.2. <u>Information We Collect from Other Sources</u></p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">We may collect information about you from other sources,
such as through certain features on the Platform you elect to use, but only
where these third parties either have your consent or are otherwise legally
permitted or required to disclose your information to us. </p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoListParagraph" style="text-align:center;text-indent:
-.25in" align="center"><b>3.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b>Use of Information</b></p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">3.1 <u>Operating Our Platform</u></p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">We may use information about you for various purposes
related to operating our Platform, including to:</p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoListParagraphCxSpFirst" style="text-indent:-.25in"><span style="font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Provide, maintain, and improve our Platform, including to process
transactions, develop new products and services, and manage the performance of
our Platform;</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-.25in"><span style="font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Display information about you, for instance, your list of
favorite artists, which will be visible on your profile;</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-.25in"><span style="font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Personalize your Platform, i.e. – to suggest content or artists
in which we think you may be interested in;</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-.25in"><span style="font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Allow you to share information about your use of the Platform
through a third-party social media service if you connect your account through
that service; and</p>

<p class="MsoListParagraphCxSpLast" style="text-indent:-.25in"><span style="font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Detect, investigate, and prevent fraudulent transactions, abuse,
and other illegal activities; to protect the rights, property, or safety of
With the band and others; to enforce compliance with our policies; and to
comply with applicable laws and government requests. </p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">3.2 <u>Advertising and Other Uses</u></p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">We may use information about you for various other purposes.
Such purposes include, but are not limited to do the following:</p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoListParagraphCxSpFirst" style="text-indent:-.25in"><span style="font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Respond to your comments, questions and requests, and provide
customer service;</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-.25in"><span style="font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Facilitate promotions;</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-.25in"><span style="font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Combine with information we collect for the purposes described in
this Privacy Policy; and</p>

<p class="MsoListParagraphCxSpLast" style="text-indent:-.25in"><span style="font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Carry out any other purpose described to you at the time that we
collected the information.</p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">3.3 <u>Legal Basis for Processing Data</u></p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">Our legal basis for collecting and using the information
described above will depend on the type of information and the specific context
in which we collect it. Some examples include:</p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoListParagraphCxSpFirst" style="text-indent:-.25in"><span style="font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>We process information about you in order to provide our services
in accordance with our Terms and Conditions.</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-.25in"><span style="font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>We also process information about you where it is in our
legitimate interest to do so and not overridden by your rights.</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-.25in"><span style="font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>In some cases, we may also have a legal obligation to collect
information about you, or may otherwise need the information to protect your
vital interests or those of another person.</p>

<p class="MsoListParagraphCxSpMiddle" style="text-indent:-.25in"><span style="font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>We may also process information to comply with a legal
requirement or to perform a contract.</p>

<p class="MsoListParagraphCxSpMiddle" style="text-align:center;
text-indent:-.25in" align="center"><b>4.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b>Sharing of Information</b></p>

<p class="MsoListParagraphCxSpLast">&nbsp;</p>

<p class="MsoNormal">4.1 <u>Through Our Platform</u></p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">We share some of the information that we collect by
displaying it on our Platform according to your member profile and account settings.
We recommend that you check your account settings to determine which parts of
your profile you would like to make visible to other With the Band users.</p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">4.2 <u>Affiliated Artists and Record Labels</u></p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">All of the information we collect from users, which includes
but is not limited to: name, email address, postal code, age, and favorite
artists, may be given to Affiliated Artists and their management teams/record
labels. Such dissemination of data is necessary for the Affiliated Artists and
their management teams to ensure the Affiliated Artist will be available for
fan meet ups and fan projects. The Affiliated Artist’s management teams need
data like email addresses and name to contact With the Band users about
potential meet ups and may require data like postal addresses to determine
where, geographically such a meet up should occur. </p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">4.3 <u>Advertisers and Other Third Parties</u></p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">Users’ names and email address will not be given to
advertisers. However, users’ postal codes and favorite artists may be sold to
advertisers or other third parties. Advertisers use this data to potentially
target the users with advertisements relating to their favorite artists and to
find geographic trends. If you do not want us to use your data in this way, or
to pass your details on to third parties for marketing purposes, simply log in
to your account and click the appropriate “opt-out” box available in your user
dashboard to disable the information type you no longer wish to receive. </p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoListParagraph" style="text-align:center;text-indent:
-.25in" align="center"><b>5.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b>Storage of Information</b></p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">5.1 <u>How We Store Your Information</u></p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">All the information you provide may be stored on our servers
(including cloud-based server technology), or transferred or accessed by
entities around the world as described in this policy, including but not
limited to any With the Band company or affiliate, using commercially
reasonable standards and precautions. By submitting your information, you agree
to this transfer, storing or processing and we agree to take all reasonably
necessary steps to ensure that your data is treated securely and in accordance with
this privacy policy.</p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">5.2 <u>Security</u></p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">The security of your personal information is important to
us. We have put in place appropriate technical and organizational procedure to
safeguard the information we collect. All information you provide to us is stored
on secure servers. Any payment transactions will be encrypted using SSL
technology. If we provide and/or you choose a password which enables you to
access certain parts of our Platform, you are responsible for keeping this
password confidential and we ask you not to share a password with anyone.</p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">Unfortunately, no security measures can guarantee 100%
security and you should be aware that transmission of information via the
Internet is not completely secure. Although we will do our best to protect your
personal data per industry standards, we cannot guarantee the security of your
data transmitted over the Internet and any such transmission is at your own
risk. Once we have received your information, we will use strict procedures and
security features to try to prevent unauthorized access.</p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal" style="text-align:center" align="center"><b>Contact Us</b></p>

<p class="MsoNormal" style="text-align:center" align="center">&nbsp;</p>

<p class="MsoNormal">Questions, comments, and request regarding this Privacy
Policy are welcomed and should be addressed to With the Band, LLC, 1915
Wildwood Ave., Nashville, TN 37212, or by emailing us at info.withtheband.co. </p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">A copy of any legal notices to Rhythmic Rebellion shall also
be sent to our legal counsel, c/o Shrum &amp; Associates, 1222 16<sup>th</sup>&nbsp;Avenue
South, Suite 23, Nashville, Tennessee 37212. Any legal notices that you provide
without compliance with this section shall have no legal effect.</p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal">&nbsp;</p>

<p class="MsoNormal" style="text-align:center" align="center">&nbsp;</p>

<p class="MsoNormal" style="text-align:center" align="center">&nbsp;</p>

<p class="MsoNormal" style="text-align:center" align="center">&nbsp;</p>

</div>
`;
export const PrivacyPage: React.FunctionComponent<RouteChildrenProps<{}>> = ({ history }) => {
  return (
    <CenteredContainer>
      <CenteredMaxWidth style={{ maxWidth: 900 }}>
        <WhiteShadowedBox>
          <img
            alt="logo"
            style={{ maxWidth: 280, margin: 'auto', display: 'block' }}
            src={require('../images/logo-black.png').default}
          />
          <div dangerouslySetInnerHTML={{ __html: PRIVACY_POLICY }}></div>
        </WhiteShadowedBox>
      </CenteredMaxWidth>
    </CenteredContainer>
  );
};
