import { ResponsiveChoropleth } from '@nivo/geo';
import React from 'react';
import { BrandAlphaSteps } from '../../Colors';
import { DashboardBox } from '../../components/Common';
import { featuresUSA } from './features_usa';
import { featuresWorld } from './features_world';

const CHLOROPLETH_PROPS = {
  colors: BrandAlphaSteps,
  unknownColor: '#fff',
  label: 'properties.name',
  margin: { top: 0, right: 0, bottom: 0, left: 0 },
  fillColor: '#eeeeee',
  borderWidth: 1,
  borderColor: '#333333',
  enableGraticule: false,
};

export const CardSetGeographies: React.FunctionComponent<{
  data: CoreAPI.AdminClubStatsResponse | undefined;
}> = ({ data }) => {
  if (!data) {
    return (
      <div style={{ display: 'flex' }}>
        <DashboardBox style={{ flex: 1, padding: 0 }}>
          <div style={{ height: 300, background: BrandAlphaSteps[0] }} />
        </DashboardBox>
        <div style={{ width: 20 }} />
        <DashboardBox style={{ flex: 1, padding: 0 }}>
          <div style={{ height: 300, background: BrandAlphaSteps[0] }} />
        </DashboardBox>
      </div>
    );
  }

  const dataCountry = Object.entries(data.breakdown.country).map(c => ({
    id: featuresWorld.find(f => f.properties.name === c[0])?.id || 'Unknown',
    value: c[1],
  }));

  const dataState = Object.entries(data.breakdown.state).map(s => ({
    id: featuresUSA.find(f => f.properties.code === s[0])?.id || 'Unknown',
    value: s[1],
  }));

  return (
    <div style={{ display: 'flex' }}>
      <DashboardBox style={{ flex: 1, padding: 0 }}>
        <div style={{ height: 350, background: BrandAlphaSteps[0] }}>
          <ResponsiveChoropleth
            {...CHLOROPLETH_PROPS}
            features={featuresWorld}
            data={dataCountry}
            domain={[0, Math.max(...dataCountry.map(c => c.value))]}
            projectionTranslation={[0.5, 0.6]}
            projectionRotation={[0, 0, 0]}
          />
        </div>
      </DashboardBox>
      <div style={{ width: 20 }} />
      <DashboardBox style={{ flex: 1, padding: 0 }}>
        <div style={{ height: 350, background: BrandAlphaSteps[0] }}>
          <ResponsiveChoropleth
            {...CHLOROPLETH_PROPS}
            features={featuresUSA}
            data={dataState}
            domain={[0, Math.max(...dataState.map(s => s.value))]}
            projectionScale={450}
            projectionTranslation={[0.5, 1.45]}
            projectionRotation={[97, 0, 0]}
          />
        </div>
      </DashboardBox>
    </div>
  );
};
