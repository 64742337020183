type WindowWebkit = { messageHandlers?: { observer: { postMessage: (msg: any) => void } } };

const getWebkitObserver = () => {
  return (window as { webkit?: WindowWebkit }).webkit?.messageHandlers?.observer;
};

const androidBridge = (window as any)?.bridge;

export const BRIDGE_VERSION =
  androidBridge && 'version' in androidBridge
    ? androidBridge.version()
    : (window as any)?.bridgeVersion;

export const BRIDGE_APP_VERSION =
  androidBridge && 'appVersion' in androidBridge
    ? androidBridge.appVersion()
    : (window as any)?.bridgeAppVersion;

export const BRIDGE_EVENT_TOGGLE_SIDEBAR = 'bridge:toggle-sidebar';

export const NativeBridge = {
  isAvailable() {
    if (getWebkitObserver()) {
      console.warn('Native bridge: iOS');
      return true; // iOS
    }
    if (androidBridge) {
      console.warn('Native bridge: Android');
      return true; // Android
    }
    console.warn('Native bridge: not available.');
    return false;
  },
  isProvidingSidebarToggle() {
    return NativeBridge.isAvailable() && BRIDGE_VERSION >= 2;
  },
  setSidebarUnreadCount(unread: number) {
    if (androidBridge && 'setSidebarUnreadCount' in androidBridge) {
      androidBridge.setSidebarUnreadCount(unread);
    } else {
      getWebkitObserver()?.postMessage({
        action: 'setSidebarUnreadCount',
        unread: unread,
      });
    }
  },
  viewImages(urls: string[]) {
    if (androidBridge && 'viewImages' in androidBridge) {
      androidBridge.viewImages(urls);
    } else {
      getWebkitObserver()?.postMessage({
        action: 'viewImages',
        urls: urls,
      });
    }
  },
  viewProfile(id: number) {
    if (androidBridge && 'viewProfile' in androidBridge) {
      androidBridge.viewProfile(id);
    } else {
      getWebkitObserver()?.postMessage({
        action: 'viewProfile',
        id: id,
      });
    }
  },
  joinZoomCall(joinURL: string) {
    if (androidBridge && 'joinZoomCall' in androidBridge) {
      androidBridge.joinZoomCall(joinURL);
    } else {
      getWebkitObserver()?.postMessage({
        action: 'joinZoomCall',
        joinURL: joinURL,
      });
    }
  },
  viewMembershipOptions() {
    if (androidBridge) {
      androidBridge.viewMembershipOptions();
      return true;
    } else {
      getWebkitObserver()?.postMessage({
        action: 'viewMembershipOptions',
      });
      return true;
    }
  },
  readyForDisplay() {
    if (androidBridge && 'readyForDisplay' in androidBridge) {
      androidBridge.readyForDisplay();
    } else {
      getWebkitObserver()?.postMessage({
        action: 'ready',
      });
    }
  },
};
