import React from 'react';
import { LabeledInput, FormLabel } from './TextInputs';
import { Button } from '@blueprintjs/core';
import { SingleLineInput } from './TextInputs';

export const StringArrayInput: React.FunctionComponent<{
  label: string;
  values: string[];
  onChange: (newStrings: string[]) => void;
}> = ({ label, values, onChange }) => {
  return (
    <LabeledInput>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FormLabel>{label}</FormLabel>
        <Button
          style={{ marginLeft: 10, marginBottom: 5 }}
          onClick={() => {
            values.push('');
            onChange(values);
          }}
          icon="add"
          minimal
        />
      </div>
      {values.map((str, idx) => (
        <div key={idx} style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            onClick={() => {
              values.splice(idx, 1);
              onChange(values);
            }}
            icon="delete"
            style={{ marginRight: 10, marginBottom: 5 }}
            minimal
          />
          <div style={{ flex: 1 }}>
            <SingleLineInput
              onChange={e => {
                values[idx] = e;
                onChange(values);
              }}
              value={str}
            />
          </div>
        </div>
      ))}
    </LabeledInput>
  );
};
