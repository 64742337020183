import { Icon } from '@blueprintjs/core';
import { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { makeRequest } from './Resource';
import { pluralize } from './Utils';
import { MessageContainer, WARNING_TINT } from './components/Common';

export function useDebouncedCall<T>(
  callback: (value: T, timer: React.MutableRefObject<number | null>) => void,
  delay = 500
) {
  const timerRef = useRef<number | null>(null);

  const fireFunc = useMemo(
    () => async (value: T) => {
      if (timerRef.current) window.clearTimeout(timerRef.current);
      timerRef.current = window.setTimeout(async () => callback(value, timerRef), delay);
    },
    [callback, delay]
  );

  return fireFunc;
}

export function useViewerCount(keyPrefix?: number | string) {
  const [viewerCount, setViewerCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const { active } = useTabVisibility();

  const intervalSeconds = 10;

  const getViewerCount = async () => {
    if (loading === false && active && keyPrefix) {
      setLoading(true);
      const { count } = await makeRequest<{ count: number }>(
        `/api/v1/viewer-count/${keyPrefix}/${intervalSeconds}`
      );
      setLoading(false);
      setViewerCount(count);
    }
  };

  useEffect(() => {
    getViewerCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  useInterval(getViewerCount, keyPrefix && active ? intervalSeconds * 1000 : null);

  const banner =
    viewerCount > 1 ? (
      <MessageContainer style={{ marginBottom: 20 }}>
        <Icon icon="warning-sign" iconSize={16} style={{ marginRight: 8 }} color={WARNING_TINT} />
        {pluralize(viewerCount - 1, 'other user', undefined, {
          singular: 'is',
          plural: 'are',
        })}{' '}
        currently viewing this page.
      </MessageContainer>
    ) : undefined;
  return { banner };
}

function useTabVisibility() {
  const [active, setActive] = useState(true);

  document.addEventListener('visibilitychange', () => {
    setActive(!document.hidden);
  });

  return { active };
}

export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);
  const intervalIdRef = useRef<number>();
  const cancel = useCallback(() => {
    const intervalId = intervalIdRef.current;
    if (intervalId) {
      intervalIdRef.current = undefined;
      clearInterval(intervalId);
    }
  }, [intervalIdRef]);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!delay && delay !== 0) {
      return;
    }

    intervalIdRef.current = setInterval(() => savedCallback.current(), delay);
    return cancel;
  }, [delay, cancel]);

  return cancel;
}

export function useScrollToItem(selected: boolean) {
  const ref: any = createRef();

  useEffect(() => {
    if (selected && !!ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [ref, selected]);

  return ref;
}
