import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Icon } from '@blueprintjs/core';
import { DashboardBox } from '../components/Common';

export class PageHome extends Component {
  render() {
    return (
      <PageContainer>
        <div style={{ display: 'flex', width: '100%' }}>
          <Link to="/admin/clubs" style={{ flex: 1 }}>
            <DashboardBox style={{ textAlign: 'center' }}>
              <Icon icon={'social-media'} iconSize={40} />
              <div style={{ marginTop: 10 }}>Crews</div>
            </DashboardBox>
          </Link>
          <div style={{ width: 20 }} />
          <Link to="/admin/users" style={{ flex: 1 }}>
            <DashboardBox style={{ textAlign: 'center' }}>
              <Icon icon={'people'} iconSize={40} />
              <div style={{ marginTop: 10 }}>Users</div>
            </DashboardBox>
          </Link>
          <div style={{ width: 20 }} />
          <a href="https://dashboard.stripe.com/dashboard" style={{ flex: 1 }}>
            <DashboardBox style={{ textAlign: 'center' }}>
              <Icon icon={'credit-card'} iconSize={40} />
              <div style={{ marginTop: 10 }}>Stripe</div>
            </DashboardBox>
          </a>
        </div>
      </PageContainer>
    );
  }
}

export const PageContainer = styled.div`
  min-height: 100vh;
  max-width: 1300px;
  margin: auto;
  padding: 20px;
`;
