import qs from 'query-string';
import React from 'react';
import { RouteChildrenProps } from 'react-router';
import { Link } from 'react-router-dom';
import { SessionContext } from '../App';
import { Button } from '../components/Button';
import { Form } from '../components/Common';
import { makeRequest } from '../Resource';
import { AuthPageContainer } from './AuthPageContainer';

export const RegisterPage: React.FunctionComponent<RouteChildrenProps<{}>> = ({
  location,
  history,
}) => {
  const [, ops] = React.useContext(SessionContext);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const query = qs.parse(location.search);
  const next = (query['next'] as string) || '/explore';

  const usernameRef = React.createRef<HTMLInputElement>();
  const firstnameRef = React.createRef<HTMLInputElement>();
  const lastnameRef = React.createRef<HTMLInputElement>();
  const emailRef = React.createRef<HTMLInputElement>();
  const passwRef = React.createRef<HTMLInputElement>();
  const termsRef = React.createRef<HTMLInputElement>();
  const ageRef = React.createRef<HTMLInputElement>();
  const phoneRef = React.createRef<HTMLInputElement>();

  const onSubmit = async (e: React.FormEvent<any>) => {
    e.preventDefault();

    const payload = {
      username: usernameRef.current?.value,
      firstName: firstnameRef.current?.value,
      lastName: lastnameRef.current?.value,
      email: emailRef.current?.value,
      password: passwRef.current?.value,
      phoneNumber: phoneRef.current?.value,
    };
    if (!termsRef.current?.checked || !ageRef.current?.checked) {
      setError('Please agree to the terms of service and verify your age.');
      return;
    }
    if (payload.phoneNumber) {
      payload.phoneNumber = payload.phoneNumber.replace(/\D/g, '');
    }
    if (
      !payload.email ||
      !payload.password ||
      !payload.firstName ||
      !payload.lastName ||
      !payload.username ||
      !payload.phoneNumber
    ) {
      setError('Please fill out all the fields below.');
      return;
    }
    setLoading(true);
    const resp = await makeRequest<{ error?: string; success: boolean }>(
      `/api/v1/register`,
      'POST',
      payload
    );
    setLoading(false);
    if (resp.error) {
      setError(resp.error);
    } else {
      await ops.refresh();
      history.push(next);
    }
  };

  return (
    <AuthPageContainer location={location}>
      <Form onSubmit={onSubmit}>
        <h1 style={{ textAlign: 'center' }}>Create an Account</h1>

        {error ? <div className="alert alert-danger">{error}</div> : undefined}

        <div className="group" style={{ marginTop: 8, marginBottom: 8 }}>
          <label className="form-block" style={{ flex: 1 }} htmlFor="username">
            <div>Username</div>
            <input
              ref={usernameRef}
              className="field"
              type="text"
              name="username"
              id="username"
              autoFocus
              autoCapitalize="off"
              autoComplete="username"
              defaultValue={query['username'] || ''}
            />
            <em style={{ opacity: 0.5 }}>Other fans will only see your username!</em>
          </label>
          <div style={{ display: 'flex' }}>
            <label className="form-block" style={{ flex: 1 }} htmlFor="first-name">
              <div>First Name</div>
              <input
                ref={firstnameRef}
                className="field"
                type="text"
                id="first-name"
                name="firstName"
                autoComplete="given-name"
                defaultValue={query['firstName'] || ''}
              />
            </label>
            <div style={{ width: 20 }} />
            <label className="form-block" style={{ flex: 1 }} htmlFor="family-name">
              <div>Last Name</div>
              <input
                ref={lastnameRef}
                className="field"
                type="text"
                id="family-name"
                name="lastName"
                autoComplete="family-name"
                defaultValue={query['lastName'] || ''}
              />
            </label>
          </div>
          <label className="form-block" htmlFor="email">
            <div>Email</div>
            <input
              ref={emailRef}
              className="field"
              type="email"
              name="email"
              id="email"
              autoComplete="email"
              defaultValue={query['email'] || ''}
              placeholder="hello-world@domain.com"
            />
          </label>
          <label className="form-block" htmlFor="phone-number">
            <div>Phone Number</div>
            <input
              ref={phoneRef}
              className="field"
              type="text"
              id="phone-number"
              name="phoneNumber"
              autoComplete="tel"
              defaultValue={query['phoneNumber'] || ''}
              placeholder="123-456-7890"
            />
          </label>
          <label className="form-block" htmlFor="password">
            <div>Password</div>
            <input
              ref={passwRef}
              className="field"
              type="password"
              name="password"
              id="password"
              autoComplete="new-password"
            />
          </label>
        </div>
        <label htmlFor="terms" style={{ display: 'flex', marginTop: 20, alignItems: 'baseline' }}>
          <input ref={termsRef} type="checkbox" id="terms" required style={{ flexShrink: 0 }} />
          <div style={{ marginLeft: 8 }}>
            I agree to the With The Band{' '}
            <a href="http://app.withtheband.co/terms" target="_blank" rel="noopener noreferrer">
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              href="http://app.withtheband.co/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            .
          </div>
        </label>
        <label htmlFor="age" style={{ display: 'flex', marginTop: 15, alignItems: 'baseline' }}>
          <input ref={ageRef} type="checkbox" id="age" required style={{ flexShrink: 0 }} />
          <div style={{ marginLeft: 8 }}>I am over the age of 13</div>
        </label>
        <div style={{ clear: 'both', marginTop: 30 }}></div>

        <input type="submit" style={{ display: 'none' }} />
        <Button
          intent="primary"
          type="submit"
          style={{ width: '100%' }}
          onClick={onSubmit}
          disabled={loading}
        >
          {loading ? 'Joining...' : 'Create Account'}
        </Button>
        <div style={{ textAlign: 'center', paddingTop: 10 }}>
          <p>
            Already have an account? <Link to="/login">Log In</Link>
          </p>
        </div>
      </Form>
    </AuthPageContainer>
  );
};
