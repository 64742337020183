function toLocaleStringSupportsLocales() {
  var number = 0;
  try {
    number.toLocaleString('i');
  } catch (e) {
    return e.name === 'RangeError';
  }
  return false;
}

export function formatPrice(cents: number, precision: 'cents' | 'dollars' = 'cents') {
  const result = toLocaleStringSupportsLocales()
    ? Number(cents / 100).toLocaleString(undefined, { style: 'currency', currency: 'USD' })
    : `$${Math.floor(cents / 100)}.${cents % 100 < 10 ? '0' : ''}${cents % 100}`;

  return precision === 'cents' ? result : result.split('.')[0];
}

const Months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const formatBirthday = (bd: string | null) => {
  if (!bd) return '';
  const [month, year] = bd.split('-');
  return `${Months[Number(month)]} '${year}`;
};

export const SocialsConfig: {
  key: keyof CoreAPI.ClubConfig['social'];
  label: string;
  icon: string;
}[] = [
  { key: 'website', label: 'Website', icon: require('./images/icon-generic.svg').default },
  { key: 'twitter', label: 'Twitter', icon: require('./images/icon-twitter.svg').default },
  { key: 'instagram', label: 'Instagram', icon: require('./images/icon-instagram.svg').default },
  { key: 'facebook', label: 'Facebook', icon: require('./images/icon-fb.svg').default },
  { key: 'tiktok', label: 'TikTok', icon: require('./images/icon-tiktok.svg').default },
  { key: 'youtube', label: 'YouTube', icon: require('./images/icon-youtube.svg').default },
  { key: 'spotify', label: 'Spotify', icon: require('./images/icon-spotify.svg').default },
  { key: 'appleMusic', label: 'Apple Music', icon: require('./images/icon-itunes.svg').default },
  {
    key: 'amazonMusic',
    label: 'Amazon Music',
    icon: require('./images/icon-amazon-music.svg').default,
  },
];

export const getTierName = (tier: string, club: CoreAPI.Club) =>
  club.tiers.find(t => t.key === tier)?.name || tier || '-';

export const shareLinkForPost = (club: CoreAPI.Club, post: CoreAPI.Post, channelSlug: string) => {
  return `${window.location.origin}/${club.slug}/c/${encodeURI(channelSlug)}?utm=share&id=${
    post.id
  }&type=post`;
};

export const pluralize = (
  count: number,
  item: string,
  plural = item + 's',
  verb?: {
    beforeVerb?: string;
    singular: string;
    plural: string;
  }
) => {
  const isSingular = count === 1;
  let str = `${count} ${isSingular ? item : plural}`;
  if (verb) {
    const { beforeVerb, singular } = verb;
    if (beforeVerb) str += ` ${beforeVerb}`;
    str += ` ${isSingular ? singular : verb.plural}`;
  }
  return str;
};
