import qs from 'query-string';
import React from 'react';

// NOTE THIS COPY HAS BEEN EDITED TO SUPPORT PREFETCHING

/*
The `Resource` component is a Redux-connected component that takes a render prop as it's child.
Given a resource `path`, it calls the render prop with the currently cached data at that path,
(or the `placeholder` data if provided) and makes an API request to refresh it with the latest
data if necessary.

The `Resource` component also provides it's children with helper functions that they can call to
update or delete the resource. Calling these helper methods makes the appropriate API requests
and then updates the local cache.

If you pass true for the `collection` prop, the `Resource` component expects an array to be
available at the path provided and exposes functions that allow items to be created, deleted
and updated.

Examples:

```jsx

  <Resource path={'/me'} placeholder={{ emailAddress: 'Loading...' }}>
    {(me, { onSave }) => (
      <div>{me.emailAddress}</div>
    )}
  </Resource>
```

```jsx

  <Resource collection path={'/properties'} placeholder={[]}>
    {(properties, { onCreateItem, onUpdateItem, onDeleteItem}) => (
      ...
      ... (call onCreateItem to add an item, etc.)
      ...
    )}
  </Resource>
```

*/

// Collection data provider

export async function makeRequest<T>(
  path: string,
  method = 'GET',
  itemPayload: any = null
): Promise<T> {
  const options: any = {
    credentials: 'include',
    headers: { accept: 'application/json' },
    method,
  };

  if (itemPayload) {
    options.body = JSON.stringify(itemPayload);
    options.headers['content-type'] = 'application/json';
  }
  const response = await fetch(path, options);
  const text = await response.text();
  let json = { error: `${response.status} ${response.statusText}` };

  try {
    json = JSON.parse(text);
  } catch (err) {
    console.error(`${method} ${path} returned invalid JSON: ${text}`);
  }

  return json as any;
}

function getPrefetchFor<T>(url: string) {
  const prefetched = document.querySelector(`script[data-prefetch="${url}"]`);
  if (prefetched && prefetched.textContent) {
    try {
      const json = JSON.parse(prefetched.textContent) as T;
      console.log(`using prefetched data for ${url}`);
      return json;
    } catch (err) {
      // pass
    }
  }
}

export interface ResourceOps<T, U = Partial<T>> {
  post: (v: U) => Promise<any>;
  put: (v: U) => Promise<void>;
  putItem: (item: { id: string | number } & U) => Promise<void>;
  delete: () => Promise<void>;
  deleteItem: (item: string | number | { id: string | number }) => Promise<void>;
  applyLocalUpdates: (v: T) => void;
  refresh: () => void;
}
export function useResource<T, U = Partial<T>>(
  path: string,
  query?: { [key: string]: any },
  options?: {
    initial?: T;
  }
) {
  const [value, setValue] = React.useState<T | undefined>(options?.initial || undefined);
  const qss = query ? qs.stringify(query) : '';
  const url = qss.length ? `${path}${path.includes('?') ? '&' : '?'}${qss}` : path;

  React.useEffect(() => {
    const fetch = async () => {
      const prefetched = getPrefetchFor<T>(url);
      if (prefetched) {
        setValue(prefetched);
      } else if (options?.initial) {
        // no-op, set initial state above
      } else {
        setValue(undefined);
        setValue(await makeRequest<T>(url, 'GET'));
      }
    };
    fetch();
  }, [url]);

  const ops = React.useMemo<ResourceOps<T, U>>(
    () => ({
      post: async v => {
        try {
          const resp = await makeRequest<U>(path, 'POST', v);
          setValue(value => {
            if ('id' in resp && value instanceof Array) {
              value.push(resp);
              return [...value] as any;
            }
            return value;
          });
          return resp;
          // toaster.show({ message: 'Item created', intent: 'success' });
        } catch (err) {
          // toaster.show({ message: 'Failed to save, try again.', intent: 'danger' });
          throw err;
        }
      },
      put: async v => {
        try {
          const resp = await makeRequest<U>(path, 'PUT', v);
          if ('id' in resp) {
            setValue(value => Object.assign({}, value, resp));
          }
          // toaster.show({ message: 'Changes saved', intent: 'success' });
        } catch (err) {
          // toaster.show({ message: 'Failed to save, try again.', intent: 'danger' });
          throw err;
        }
      },
      putItem: async (item: { id: string | number } & U) => {
        const resp = await makeRequest<any>(`${path}/${item.id}`, 'PUT', item);

        setValue(value => {
          if (resp && 'id' in resp && value instanceof Array) {
            const nextValue: any = [];
            for (const item of value) {
              nextValue.push(item.id === resp.id ? resp : item);
            }
            return nextValue;
          }
          return value;
        });
        // toaster.show({ message: 'Updated successfully', intent: 'success' });
      },
      delete: async () => {
        await makeRequest<T>(path, 'DELETE');
        // toaster.show({ message: 'Deleted successfully', intent: 'success' });
      },
      deleteItem: async (item: string | number | { id: string | number }) => {
        const itemId = typeof item === 'object' && 'id' in item ? item.id : item;
        await makeRequest<T>(`${path}/${itemId}`, 'DELETE');
        // toaster.show({ message: 'Deleted successfully', intent: 'success' });
        setValue(value => {
          if (value && value instanceof Array) {
            return value.filter(i => i.id !== itemId) as any;
          }
          return value;
        });
      },
      applyLocalUpdates: (v: T) => setValue(v),
      refresh: async () => {
        setValue(await makeRequest<T>(url, 'GET'));
      },
    }),
    [path, url]
  );

  // Explicitly tell TS this is a tuple of two distinct types, not an array of (T | typeof Ops)
  return [value, ops] as [T | undefined, ResourceOps<T, U>];
}
