import { Button, InputGroup, MenuItem, NumericInput, Popover, TextArea } from '@blueprintjs/core';
import { IItemModifiers, ItemPredicate, MultiSelect, Select } from '@blueprintjs/select';
import React, { ReactNode } from 'react';
import { SketchPicker } from 'react-color';
import styled from 'styled-components/macro';
import { ImageDropzoneInput } from '../components/ImageDropzoneInput';

interface TextInputProps {
  value: string;
  onChange: (newVal: string) => void;
  label?: string;
  required?: boolean;
  type?: 'url';
  disabled?: boolean;
  style?: React.CSSProperties;
  placeholder?: string;
}

export const ColorInput: React.FunctionComponent<TextInputProps> = ({
  label,
  disabled,
  value,
  onChange,
  required,
}) => {
  return (
    <LabeledInput>
      {label && <FormLabel>{label}</FormLabel>}
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
        <Popover
          content={<SketchPicker color={value} onChange={e => onChange(e.hex.toUpperCase())} />}
        >
          <Button style={{ background: value, width: 15, height: 15 }} />
        </Popover>
        <div style={{ width: 7 }} />
        <InputGroup
          disabled={disabled}
          type="text"
          style={{ width: 116 }}
          required={required}
          value={value.toUpperCase()}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(e.target.value.toUpperCase());
          }}
        />
      </div>
    </LabeledInput>
  );
};

export const SingleLineInput: React.FunctionComponent<TextInputProps> = ({
  label,
  onChange,
  type,
  style,
  ...rest
}) => {
  return (
    <LabeledInput>
      {label && <FormLabel>{label}</FormLabel>}
      <InputGroup
        type={type ? type : 'text'}
        style={{ marginBottom: 10, ...style }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e.target.value);
        }}
        {...rest}
      />
    </LabeledInput>
  );
};

export const MultiLineInput: React.FunctionComponent<TextInputProps> = ({
  label,
  value,
  disabled,
  onChange,
  required,
  style,
}) => {
  return (
    <LabeledInput>
      <FormLabel>{label}</FormLabel>
      <TextArea
        required={required}
        fill={true}
        disabled={disabled}
        large={true}
        style={style}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          onChange(e.target.value);
        }}
        value={value}
      />
    </LabeledInput>
  );
};

interface NumericInputProps {
  label?: string;
  value?: number;
  onChange: (newVal: number) => void;
  min?: number;
  max?: number;
  required?: boolean;
}

export const NumberInput: React.FunctionComponent<NumericInputProps> = ({
  label,
  value,
  onChange,
  min,
  max,
  required,
}) => {
  const [val, setVal] = React.useState(`${value}`);
  React.useEffect(() => {
    setVal(`${value}`);
  }, [value]);

  return (
    <LabeledInput>
      {label && <FormLabel>{label}</FormLabel>}
      <NumericInput
        required={required}
        majorStepSize={1}
        minorStepSize={0.01}
        min={min}
        max={max}
        fill
        onBlur={() => onChange(Number(val))}
        onValueChange={(_, str) => setVal(str)}
        value={val}
      />
    </LabeledInput>
  );
};

interface EnumInputProps<T> {
  label?: string;
  value: string;
  options: T[];
  onChange: (newVal: T) => void;
  filterable?: boolean;
}

export function SingleSelectEnumInput<T extends string>({
  label,
  options,
  value,
  onChange,
  filterable,
}: EnumInputProps<T>) {
  const itemRenderer = (item: T) => {
    return (
      <MenuItem
        key={item}
        onClick={() => {
          onChange(item);
        }}
        label={item}
      ></MenuItem>
    );
  };

  const filterValue: ItemPredicate<T> = (query, item) => {
    return item.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  };

  return (
    <LabeledInput>
      {label && <FormLabel>{label}</FormLabel>}
      <Select<T>
        filterable={filterable || false}
        items={options}
        itemPredicate={filterable ? filterValue : undefined}
        itemRenderer={itemRenderer}
        onItemSelect={item => onChange(item)}
      >
        <Button rightIcon="caret-down" text={value} />
      </Select>
    </LabeledInput>
  );
}

interface MultiSelectEnumInputProps {
  label?: string;
  placeholder?: string;
  values: TagOption[];
  options: TagOption[];
  onChange: (newVals: TagOption[]) => void;
}
interface TagOption {
  label?: string;
  value: string;
}

const TagSelect = MultiSelect.ofType<TagOption>();

export const MultiSelectEnumInput = ({
  label,
  placeholder,
  options,
  values,
  onChange,
}: MultiSelectEnumInputProps) => {
  const itemRenderer = (item: TagOption, itemProps: { modifiers: IItemModifiers }) => {
    return (
      <MenuItem
        key={item.value}
        active={itemProps.modifiers.active}
        icon={values.some(v => item.value === v.value) ? 'tick' : 'blank'}
        onClick={() => {
          handleClick(item);
        }}
        label={item.label || item.value}
      />
    );
  };

  const handleClick = (clicked: TagOption) => {
    values.some(i => clicked.value === i.value)
      ? handleTagRemove({ tag: clicked })
      : onChange([...values, clicked]);
  };

  const handleTagRemove = ({ tag, idx }: { tag?: TagOption; idx?: number }) => {
    if (!tag && idx === undefined) return;
    onChange(values.filter((t, currIdx) => (tag ? t.value !== tag.value : currIdx !== idx)));
  };

  return (
    <LabeledInput>
      {label && <FormLabel>{label}</FormLabel>}
      <TagSelect
        placeholder={placeholder}
        items={options}
        tagRenderer={item => item.label || item.value}
        selectedItems={values}
        noResults={<MenuItem disabled={true} text="No results." />}
        itemRenderer={itemRenderer}
        tagInputProps={{
          onRemove: (_, idx) => {
            handleTagRemove({ idx });
          },
        }}
        itemListPredicate={(e: string) => {
          return options.filter(opt => opt.value.toLowerCase().includes(e.toLowerCase()));
        }}
        popoverProps={{ minimal: true }}
        onItemSelect={item => {
          handleClick(item);
        }}
      />
    </LabeledInput>
  );
};

export const ThumbnailInput: React.FunctionComponent<
  TextInputProps & {
    format?: 'jpg' | 'png';
    showRawURL?: boolean;
    desiredSize?: { width: number; height: number };
  }
> = ({ format, label, required, value, onChange, desiredSize, showRawURL }) => {
  const inputEl = React.createRef<HTMLInputElement>();

  return (
    <div style={{ display: 'inline-block' }}>
      <LabeledInput>
        {label && <FormLabel>{label}</FormLabel>}
        <ImageDropzoneInput
          style={
            desiredSize
              ? { width: (desiredSize.width / desiredSize.height) * 200, height: 200 }
              : { width: 150, height: 100 }
          }
          value={value}
          inputEl={inputEl}
          format={format || 'jpg'}
          desiredSize={desiredSize}
          onChange={e => onChange(e || '')}
        />
        <div style={{ margin: '10px 0', display: 'flex', alignItems: 'flex-end' }}>
          <Button onClick={() => inputEl.current && inputEl.current.click()}>
            Choose from File...
          </Button>
          {showRawURL && <div style={{ width: 10 }} />}
          {showRawURL && (
            <div style={{ flex: 1 }}>
              <strong>Raw URL:</strong>
              <InputGroup
                fill
                type={'text'}
                required={required}
                value={value || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onChange(e.target.value);
                }}
              />
            </div>
          )}
        </div>
      </LabeledInput>
    </div>
  );
};

export const LabeledInput = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const FormLabel = styled.div`
  font-weight: 600;
  margin-bottom: 5px;
`;
