import React from 'react';
import { Sheet } from './components/Common';
import { FriendlyErrorBoundary } from './components/FriendlyErrorBoundary';

const CURRENT_MODAL_CHANGED = 'modal-changed';

let CurrentModal: React.ReactElement | null = null;

const setModal = (modal: React.ReactElement | null) => {
  CurrentModal = modal;
  document.dispatchEvent(new CustomEvent(CURRENT_MODAL_CHANGED));
};

export const showModal = (body: React.ReactElement) => {
  setModal(body);
};

export const closeModal = () => {
  setModal(null);
};

export class AppModalsProvider extends React.Component {
  state = { modal: CurrentModal, visible: !!CurrentModal };

  bodyRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    document.addEventListener(CURRENT_MODAL_CHANGED, () => {
      this.setState(
        CurrentModal
          ? { modal: CurrentModal, visible: false }
          : { modal: this.state.modal, visible: false }
      );
      setTimeout(() => this.setState({ modal: CurrentModal, visible: !!CurrentModal }), 250);
    });
  }

  render() {
    return this.state.modal ? (
      <FriendlyErrorBoundary description="modal">
        <Sheet visible={this.state.visible} onClick={() => closeModal()}>
          {React.cloneElement(this.state.modal, { visible: this.state.visible })}
        </Sheet>
      </FriendlyErrorBoundary>
    ) : null;
  }
}
