import { Button, ButtonGroup, NonIdealState } from '@blueprintjs/core';
import qs from 'query-string';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { ClubContext } from '../App';
import { Sheet } from '../components/Common';
import { hasOnlyFreeTier } from '../components/JoinModal';
import { MembershipDetailsModal } from '../components/MembershipDetailsModal';
import { SortableTable, SortableTableColumn, filterWithSearch } from '../components/SortableTable';
import { useResource } from '../Resource';
import { formatBirthday, formatPrice, getTierName } from '../Utils';
import { PageListHeader } from './Components';
import { PageContainer } from './PageHome';
import { cloneDeep } from 'lodash';

export type Member = CoreAPI.ClubMembership & { user: CoreAPI.CurrentUser; lifetimeValue: number };

export const PageClubMembers: React.FunctionComponent<
  RouteComponentProps<{ clubId: string; memberId: string }>
> = props => {
  const club = useContext(ClubContext);
  const history = useHistory();
  const url = `/api/admin/v1/clubs/${props.match.params.clubId}/memberships`;
  const [billingState, setBillingState] = useState<'active' | 'cancelled'>('active');
  const [members = [], { applyLocalUpdates }] = useResource<Member[]>(url, { billingState });
  const [picked, setPicked] = React.useState<Member[]>([]);
  const [searchVal, setSearchVal] = React.useState<string>('');

  const unpicked = filterWithSearch(
    members.filter(m => !picked.some(r => r.id === m.id)),
    club,
    ActiveMembersTableColumns,
    searchVal
  );

  const randomButton = (
    <Button
      icon="random"
      onClick={() => {
        if (unpicked.length === 0) {
          return alert('Sorry, you have picked all of the users that match your search filter.');
        }
        setPicked([...picked, unpicked[Math.floor(Math.random() * unpicked.length)]]);
      }}
    >
      Pick Random
    </Button>
  );

  const onExport = (picked?: Member[]) => {
    window.location.href = `${url}.xlsx?${qs.stringify({
      mids: picked?.map(p => p.id).join(','),
      billingState,
    })}`;
  };

  const currMember = members.find(m => `${m.id}` === props.match.params.memberId);

  return (
    <PageContainer>
      <Sheet
        visible={!!props.match.params?.memberId}
        onClick={() => history.push(`/admin/clubs/${props.match.params.clubId}/members`)}
      >
        {!!currMember && (
          <MembershipDetailsModal
            member={currMember}
            onUpdateMember={m => {
              const update = cloneDeep(members);
              const index = update.findIndex(u => u.id === m.id);
              update[index] = m;
              applyLocalUpdates(update);
            }}
          />
        )}
      </Sheet>
      {picked.length > 0 && (
        <>
          <PageListHeader title={`Selected ${picked.length > 0 ? `(${picked.length})` : ''}`}>
            <Button onClick={() => onExport(picked)} icon="export">
              Export XLSX
            </Button>
            <div style={{ width: 10 }} />
            <Button icon="eraser" onClick={() => setPicked([])}>
              Clear Random
            </Button>
            <div style={{ width: 10 }} />
            {randomButton}
          </PageListHeader>
          <MembersTable members={picked} searchVal={''} billingState={billingState} />
          <div style={{ height: 50 }} />
        </>
      )}

      <PageListHeader
        title={`Members ${unpicked.length > 0 ? `(${unpicked.length})` : ''}`}
        filter={searchVal}
        onChangeFilter={setSearchVal}
        filterPlaceholder="Filter by details..."
      >
        <div style={{ width: 10 }} />
        <Button onClick={() => onExport()} icon="export">
          Export XLSX
        </Button>
        {picked.length === 0 && (
          <>
            <div style={{ width: 10 }} />
            {randomButton}
          </>
        )}
      </PageListHeader>
      <div style={{ marginBottom: 10 }}>
        <ButtonGroup>
          <Button onClick={() => setBillingState('active')} active={billingState === 'active'}>
            Active
          </Button>
          <Button
            onClick={() => setBillingState('cancelled')}
            active={billingState === 'cancelled'}
          >
            Cancelled
          </Button>
        </ButtonGroup>
      </div>

      <MembersTable members={unpicked} searchVal={searchVal} billingState={billingState} />

      {members && members.length === 0 && (
        <div style={{ marginTop: 30 }}>
          <NonIdealState
            title="No Members"
            description="No fans have paid for subscriptions yet. Check back soon."
          />
        </div>
      )}
    </PageContainer>
  );
};

const MembersTableColumns: SortableTableColumn<Member, CoreAPI.Club>[] = [
  {
    label: 'Name',
    raw: ({ user }) => `${user.username} (${user.firstName} ${user.lastName})`,
    render: ({ user, permissions }) => (
      <>
        {`${user.username} (${user.firstName} ${user.lastName})`}{' '}
        {permissions === 'owner' && (
          <span
            role="img"
            title="This user is a crew administrator."
            aria-label="This user is a crew administrator."
          >
            ⭐️
          </span>
        )}
        {permissions === 'moderator' && (
          <span
            role="img"
            title="This user is a crew moderator."
            aria-label="This user is a crew moderator."
          >
            ⭐️
          </span>
        )}
        <br />
        <a href={`mailto:${user.email}`}>{user.email}</a>
      </>
    ),
  },
  {
    label: 'Tier',
    raw: ({ tier }, club) => getTierName(tier, club),
    render: ({ tier }, club) => (
      <div
        style={{
          whiteSpace: 'nowrap',
          maxWidth: 200,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {getTierName(tier, club)}
      </div>
    ),
    width: 100,
  },
  {
    label: 'Shipping Address',
    render: ({ user }) =>
      user.shippingAddress ? (
        <>
          {user.shippingAddress.city && (
            <span style={{ textTransform: 'capitalize' }}>
              {user.shippingAddress.city.toLowerCase()}
              {', '}
            </span>
          )}
          <span style={{ textTransform: 'capitalize' }}>
            {user.shippingAddress.state.toUpperCase() || user.shippingAddress.country.toLowerCase()}
          </span>
        </>
      ) : (
        'None Provided'
      ),
    width: '15%',
  },
  {
    label: 'LTV',
    raw: ({ lifetimeValue }) => lifetimeValue,
    render: ({ lifetimeValue }) => formatPrice(lifetimeValue),
    width: 90,
  },
];

const ActiveMembersTableColumns: SortableTableColumn<Member, CoreAPI.Club>[] = [
  ...MembersTableColumns,
  {
    label: 'Birthday',
    render: ({ user }) => formatBirthday(user.birthday),
    width: 100,
  },
  {
    label: 'RR',
    raw: ({ tier }, club) => club.tiers.find(t => t.key === tier)?.price || 0,
    render: ({ tier }, club) =>
      club.tiers.find(t => t.key === tier)
        ? formatPrice(club.tiers.find(t => t.key === tier)!.price)
        : 'Old Tier',
    width: 90,
  },
  {
    label: 'Billing',
    render: ({ billing }) => (billing ? (billing.type === 'apple' ? 'Apple' : 'Stripe') : '-'),
    width: 120,
  },

  {
    label: 'Join Date',
    raw: ({ createdAt }) => new Date(createdAt),
    render: ({ createdAt }) => new Date(createdAt).toLocaleDateString(),
    width: 100,
  },
  {
    label: 'Last Seen',
    raw: ({ user }) => new Date(user.lastMeRefreshAt || 0),
    render: ({ user }) =>
      user.lastMeRefreshAt ? new Date(user.lastMeRefreshAt).toLocaleDateString() : '-',
    width: 100,
  },
];

const CancelledMembersTableColumns: SortableTableColumn<Member, CoreAPI.Club>[] = [
  ...MembersTableColumns,

  {
    label: 'Cancellation Reason',
    render: ({ feedback }) => (feedback ? feedback.cancelationReason : '-'),
    width: 220,
  },

  {
    label: 'Membership Ends',
    raw: ({ billingPeriodEnd }) => (billingPeriodEnd ? new Date(billingPeriodEnd) : '-'),
    render: ({ billingPeriodEnd }) =>
      billingPeriodEnd ? new Date(billingPeriodEnd).toLocaleDateString() : '-',
    width: 100,
  },
];

const MembersTable: React.FunctionComponent<{
  members: Member[];
  billingState: 'active' | 'cancelled';
  searchVal: string;
}> = ({ members, searchVal, billingState }) => {
  const club = useContext(ClubContext);
  const history = useHistory();

  let columns =
    billingState === 'active' ? ActiveMembersTableColumns : CancelledMembersTableColumns;

  const fanCreatedClub = hasOnlyFreeTier(club);
  if (fanCreatedClub) {
    const includedCols = ['Name', 'Join Date', 'Last Seen'];
    columns = columns.filter(c => includedCols.includes(c.label));
  }

  return (
    <SortableTable<Member, CoreAPI.Club>
      data={members}
      dataKey={m => m.user.id}
      context={club}
      searchVal={searchVal}
      columns={columns}
      onRow={m => history.push(`/admin/clubs/${club.id}/members/${m.id}`)}
    />
  );
};
