import { Icon, Toaster } from '@blueprintjs/core';
import qs from 'query-string';
import React, { useContext, useState } from 'react';
import { Redirect, RouteChildrenProps } from 'react-router';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import tinycolor from 'tinycolor2';
import { ClubContext } from '../App';
import { AcceptMeetModal } from '../components/AcceptMeetModal';
import { Button } from '../components/Button';
import {
  CharInContainer,
  ClubHero,
  ClubPageContainer,
  hexWithOpacity,
  Sheet,
} from '../components/Common';
import { Feed } from '../components/feed/Feed';
import { hasOnlyFreeTier, JoinModal } from '../components/JoinModal';
import { WelcomeModal } from '../components/WelcomeModal';
import { BRIDGE_EVENT_TOGGLE_SIDEBAR, NativeBridge } from '../NativeBridge';
import { PublicFooter } from '../pages/PublicFooter';
import { SocialsConfig } from '../Utils';
import { UnreadNotifs, useUnreadNotifs } from './useUnreadNotifs';

function parseInitialTarget(search: string): CoreAPI.FeedTarget | null {
  const args = qs.parse(search.slice(1));
  return 'id' in args && 'type' in args
    ? ({
        type: `${args.type}` as 'post' | 'response',
        channelKey: `${args.channelKey}`,
        id: Number(args.id),
      } as const)
    : null;
}

export const ClubPage: React.FunctionComponent<
  RouteChildrenProps<{
    page?: string;
    channelSlug?: string;
  }>
> = ({ match, history, location }) => {
  const [navOpenMobile, setNavOpenMobile] = React.useState(false);

  const club = useContext(ClubContext);
  const { page, channelSlug } = match!.params;
  const [target, setTarget] = useState(parseInitialTarget(location.search));

  const channels = club.config.channels || [];
  const channel =
    channels.find(c => c.slug === channelSlug || c.key === target?.channelKey) || channels[0];

  const seen = !window.localStorage || !!window.localStorage.getItem(`${club.slug}:welcomed`);

  const { unreadCountsByChannel, actions: unreadNotifOps } = useUnreadNotifs(club.id);

  // Sync page title to channel
  React.useEffect(() => {
    document.title = `${club.name} ${channel.label}`;
    setNavOpenMobile(false);
  }, [club, channel]);

  // Allow the bridge to open the sidebar
  React.useEffect(() => {
    const handler = () => setNavOpenMobile(v => !v);
    document.addEventListener(BRIDGE_EVENT_TOGGLE_SIDEBAR, handler);
    return () => document.removeEventListener(BRIDGE_EVENT_TOGGLE_SIDEBAR, handler);
  }, []);

  React.useEffect(() => {
    NativeBridge.isAvailable() && NativeBridge.readyForDisplay();
  }, []);

  // Show total unread count on the sidebar nav bar item on mobile
  const overallUnreadCount = Object.values(unreadCountsByChannel).reduce((a, b) => a + b, 0);
  React.useEffect(() => {
    NativeBridge.setSidebarUnreadCount(overallUnreadCount);
  }, [overallUnreadCount]);

  if (!seen && !page && !hasOnlyFreeTier(club)) {
    return (
      <Redirect
        to={`/${club.slug}/welcome?next=${encodeURIComponent(
          `${location.pathname}${location.search}`
        )}`}
      />
    );
  }

  const onDismissSheet = () => {
    history.push(`/${club.slug}`);
  };

  const onDimissWelcome = () => {
    window.localStorage.setItem(`${club.slug}:welcomed`, 'true');
    const query = qs.parse((location.search || '').slice(1));
    history.replace((query['next'] as string) || `/${club.slug}`);
  };

  const onMeetAccept = (page || '').startsWith('meet:');

  return (
    <ClubPageContainer>
      <Toaster />

      <Sheet visible={onMeetAccept} onClick={onDismissSheet}>
        {onMeetAccept && (
          <AcceptMeetModal
            club={club}
            invitationHash={(page || '').split(':').pop()!}
            onDismiss={onDismissSheet}
          />
        )}
      </Sheet>
      <Sheet visible={page === 'join'} onClick={onDismissSheet}>
        <JoinModal club={club} visible={page === 'join'} />
      </Sheet>
      <Sheet visible={page === 'welcome'} onClick={onDimissWelcome}>
        {page === 'welcome' && <WelcomeModal club={club} onDismiss={onDimissWelcome} />}
      </Sheet>
      <ClubHero club={club} />
      <ClubCenteredMaxWidth>
        <ClubNavigation
          unreadCountsByChannel={unreadCountsByChannel}
          club={club}
          openMobile={navOpenMobile}
          setOpenMobile={setNavOpenMobile}
        />
        <ClubNavigationToggle
          channel={channel}
          onToggleNavOpen={() => setNavOpenMobile(!navOpenMobile)}
        />
        <Feed
          unreadNotifOps={unreadNotifOps}
          club={club}
          channel={channel}
          target={target}
          setTarget={setTarget}
        />
        <ClubNavigationCenteringSpacer />
      </ClubCenteredMaxWidth>
      {!NativeBridge.isAvailable() && <PublicFooter />}
    </ClubPageContainer>
  );
};

const ClubNavigationCenteringSpacer = styled.div`
  width: 190px;
  flex-shrink: 0;
`;

const ClubNavigationToggle: React.FC<{
  channel: CoreAPI.ClubFeedChannel;
  onToggleNavOpen: () => void;
}> = props => {
  return (
    <ClubNavigationToggleContainer>
      <div style={{ height: 60, display: 'flex', alignItems: 'flex-start' }}>
        <Button
          onClick={props.onToggleNavOpen}
          style={{ padding: '5px 10px', display: 'flex', alignItems: 'center', gap: 8 }}
        >
          <Icon icon="list" iconSize={18} />
          {props.channel.label}
        </Button>
        <div style={{ flex: 1 }} />
      </div>
    </ClubNavigationToggleContainer>
  );
};

const ClubNavigationToggleContainer = styled.div`
  display: none;
  height: 40px;
  overflow: visible;

  & > div {
    font-size: 19px;
    padding: 10px;
    color: ${({ theme }) => theme.heroNavColor};
    background: ${({ theme }) => theme.bgColor};
  }
  @media (max-width: 650px) {
    z-index: 2;
    display: block;
  }
`;

const ClubNavigationMobileBackground = styled.div<{ openMobile: boolean }>`
  z-index: 3;
  inset: 0;
  position: fixed;
  backdrop-filter: ${({ openMobile }) => (openMobile ? 'blur(2px)' : 'none')};
  background: ${({ openMobile }) => (openMobile ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0)')};
  pointer-events: ${({ openMobile }) => (openMobile ? 'all' : 'none')};
  transition: background 200ms ease-out;
`;

const ClubNavigationContainer = styled.div<{ openMobile: boolean }>`
  width: 190px;
  flex-shrink: 0;
  z-index: 2;
  margin-right: 10px;
  margin-left: 20px;
  padding-top: 10px;

  @media (max-width: 650px) {
    position: fixed;
    width: 250px;
    top: 0;
    right: ${({ openMobile }) => (openMobile ? '0' : '-250px')};
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
    bottom: 0;
    margin: 0;
    z-index: 4;
    padding: 10px;
    background: ${({ theme }) => theme.bgColor};
    transition: right 200ms ease-out;
  }
`;

export const ClubCenteredMaxWidth = styled.div`
  max-width: 1000px;
  margin: auto;
  margin-top: 24px;
  display: flex;

  @media (max-width: 950px) {
    ${ClubNavigationCenteringSpacer} {
      display: none;
    }
  }

  @media (max-width: 650px) {
    margin-top: 0;
    flex-direction: column;
    ${ClubNavigationCenteringSpacer} {
      display: none;
    }
    hr {
      display: none;
    }
  }
`;

const ClubNavigation: React.FunctionComponent<{
  club: CoreAPI.Club;
  unreadCountsByChannel: UnreadNotifs['unreadCountsByChannel'];
  openMobile: boolean;
  setOpenMobile: (v: boolean) => void;
}> = ({ club, unreadCountsByChannel, openMobile, setOpenMobile }) => (
  <>
    <ClubNavigationMobileBackground
      openMobile={openMobile}
      onClick={() => openMobile && setOpenMobile(false)}
    />
    <ClubNavigationContainer openMobile={openMobile}>
      {club.config?.heroNavLogoURL && <WTBLogoImg src="/public/images/logo-white.png" />}

      {club.config.sections.map(s =>
        s.type === 'feed' ? (
          <React.Fragment key={'feed'}>
            {(club.config.channels || []).map(c => (
              <SidebarLink
                exact
                key={c.key}
                title={c.label}
                to={c.key === 'main' ? `/${club.slug}` : `/${club.slug}/c/${c.slug}`}
                data-channel-key={c.key}
                activeClassName={'active'}
              >
                {c.label.match(/^[A-Za-z0-9]/) ? (
                  <SidebarIcon src={require('../images/icon-hashtag.svg').default} />
                ) : null}
                {c.label}
                <div style={{ flex: 1 }} />
                <CharInContainer
                  char={
                    unreadCountsByChannel?.[c.key] > 0
                      ? unreadCountsByChannel[c.key] > 99
                        ? '99+'
                        : unreadCountsByChannel[c.key]
                      : ''
                  }
                />
              </SidebarLink>
            ))}
          </React.Fragment>
        ) : (
          <SidebarA
            key={s.url}
            title={s.label}
            href={s.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SidebarIcon src={require('../images/icon-generic.svg').default} />
            {s.label}
            <div style={{ flex: 1 }} />
            {ExternalLinkIcon}
          </SidebarA>
        )
      )}

      <hr style={{ opacity: 0.1, marginTop: 20, marginBottom: 20 }} />

      <SidebarSocialItems>
        {SocialsConfig.map(({ key, label, icon }) => (
          <SidebarSocialItem key={key} service={label} href={club.config.social[key]} icon={icon} />
        ))}
      </SidebarSocialItems>
    </ClubNavigationContainer>
  </>
);

const WTBLogoImg = styled.img`
  width: 182px;
  margin-bottom: 13px;
  ${({ theme }) => (tinycolor(theme.bgColor).isLight() ? 'filter: invert(1);' : '')}
  @media (max-width: 500px) {
    display: none;
  }
`;

const SidebarA = styled.a`
  padding: 10px 12px;
  border-radius: 10px;
  ${({ theme }) =>
    tinycolor(theme.bgColor).isLight()
      ? 'color: #000 !important;'
      : 'color: #fff !important; img { filter: invert(1); }'}
  display: flex;
  align-items: center;
  margin-right: 6px;
  margin-bottom: 6px;
  background: ${hexWithOpacity('#000000', 0.1)};
  &:hover {
    text-decoration: none;
    background: ${hexWithOpacity('#000000', 0.2)};
    color: ${({ theme }) => (tinycolor(theme.bgColor).isLight() ? '#000' : '#fff')} !important;
  }
`;

const SidebarLink = styled(NavLink)`
  padding: 10px 12px;
  border-radius: 10px;

  ${({ theme }) =>
    tinycolor(theme.bgColor).isLight()
      ? 'color: #000 !important;'
      : 'color: #fff !important; img { filter: invert(1); }'}

  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-bottom: 5px;
  background: ${({ theme }) =>
    hexWithOpacity(theme.themeMode === 'dark' ? '#000000' : '#ffffff', 0.1)};
  &:hover {
    background: ${({ theme }) =>
      hexWithOpacity(theme.themeMode === 'dark' ? '#000000' : '#ffffff', 0.2)};
    text-decoration: none;
    color: ${({ theme }) => (tinycolor(theme.bgColor).isLight() ? '#000' : '#fff')} !important;
  }
  &.active {
    font-weight: 700;
    background: ${({ theme }) =>
      hexWithOpacity(theme.themeMode === 'dark' ? '#000000' : '#ffffff', 0.4)};
    color: ${({ theme }) => theme.heroNavColor} !important;
    img {
      filter: invert(1);
    }
  }
`;

const SidebarIcon: React.FunctionComponent<{ src: string }> = props => (
  <div style={{ width: 23, paddingRight: 5, display: 'flex' }}>
    <img alt="icon" src={props.src} style={{ maxHeight: 16, margin: 'auto' }} />
  </div>
);

const SidebarSocialItems = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 500px) {
    justify-content: flex-start;
  }
`;
const SidebarSocialItem: React.FunctionComponent<{
  service: string;
  href: string | undefined;
  icon: string;
}> = props =>
  props.href ? (
    <SidebarA
      style={{ display: 'inline-block', padding: 0 }}
      title={props.service}
      href={props.href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div
        style={{
          width: 40,
          height: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img alt="icon" src={props.icon} />
      </div>
    </SidebarA>
  ) : null;

const ExternalLinkIcon = (
  <svg
    style={{ width: 14, opacity: 0.3 }}
    viewBox="0 0 24 30"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
    <polyline points="15 3 21 3 21 9" />
    <line x1="10" y1="14" x2="21" y2="3" />
  </svg>
);
