import { Colors, HTMLSelect, Icon, Navbar, NonIdealState, Spinner } from '@blueprintjs/core';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/select/lib/css/blueprint-select.css';
import React from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import { ClubContext, SessionContext } from '../App';
import { hasOnlyFreeTier } from '../components/JoinModal';
import { useResource } from '../Resource';
import { NavContainer, NavItems } from './AdminNav';
import { PageClubDashboard } from './PageClubDashboard';
import { PageClubMeetAndGreets } from './PageClubMeetAndGreets';
import { PageClubMembers } from './PageClubMembers';
import { PageClubModeration } from './PageClubModeration';
import { PageClubPosts } from './PageClubPosts';
import { PageClubs } from './PageClubs';
import { PageHome } from './PageHome';
import { PageUserActivity } from './PageUserActivity';
import { PageUsers } from './PageUsers';
import { PageClubSettings } from './settings/PageClubSettings';

export const AdminRoot: React.FunctionComponent<{}> = () => {
  const [{ user }] = React.useContext(SessionContext);
  const [clubs] = useResource<CoreAPI.Club[]>('/api/admin/v1/clubs');
  const history = useHistory();

  React.useEffect(() => {
    document.title = 'With the Band - Admin';
  });

  if (!user) {
    return <Redirect to="/login" />;
  }
  if (clubs && !clubs.length) {
    return (
      <div>
        <NavContainer user={user} />
        <div style={{ height: 100 }} />
        <NonIdealState
          icon="star-empty"
          title="No Crews"
          description="Request to manage a crew and try again."
        />
      </div>
    );
  }

  return (
    <div className="AdminRoot">
      <NavContainer user={user}>
        <Switch>
          <Route
            path="/admin/clubs/:clubId"
            render={({ match }) => (
              <>
                <HTMLSelect
                  value={match.params.clubId}
                  onChange={e =>
                    e.target.value === 'all'
                      ? history.push(`/admin/clubs`)
                      : history.push(`/admin/clubs/${e.target.value}/dashboard`)
                  }
                >
                  {(clubs || []).map(club => (
                    <option key={club.id} value={`${club.id}`}>
                      {club.name}
                    </option>
                  ))}
                  {user.role === 'admin' && <option disabled>──────────</option>}
                  {user.role === 'admin' && (
                    <option key="all" value="all">
                      View All
                    </option>
                  )}
                </HTMLSelect>
                <Navbar.Divider />
                <NavItems
                  items={[
                    {
                      name: 'Dashboard',
                      path: `/admin/clubs/${match.params.clubId}/dashboard`,
                    },
                    {
                      name: 'Posts',
                      path: `/admin/clubs/${match.params.clubId}/posts`,
                    },
                    {
                      name: 'Meet & Greets',
                      path: `/admin/clubs/${match.params.clubId}/meet-and-greets`,
                    },
                    {
                      name: 'Members',
                      path: `/admin/clubs/${match.params.clubId}/members`,
                    },
                    {
                      name: 'Settings',
                      path: `/admin/clubs/${match.params.clubId}/settings`,
                    },
                  ].filter(n => {
                    const currClub = clubs?.find(c => Number(match.params.clubId) === c.id);
                    return !!currClub && !(hasOnlyFreeTier(currClub) && n.name === 'Meet & Greets');
                  })}
                />
                <Navbar.Divider className="hide-small" />
                <a
                  className="hide-small"
                  href={`${process.env.NODE_ENV === 'production' ? '' : 'http://localhost:8001'}/${
                    clubs?.find(c => `${c.id}` === match.params.clubId)?.slug
                  }`}
                  rel="noopener noreferrer"
                  style={{ fontSize: 16, color: Colors.GRAY2 }}
                  target="_blank"
                >
                  View Page&nbsp;
                  <Icon icon="share" iconSize={13} style={{ verticalAlign: 'baseline' }} />
                </a>
              </>
            )}
          />
          {user.role === 'admin' && (
            <Route
              render={() => (
                <NavItems
                  items={[
                    { name: 'Crews', path: `/admin/clubs` },
                    { name: 'Users', path: `/admin/users` },
                  ]}
                />
              )}
            />
          )}
        </Switch>
      </NavContainer>
      {!clubs ? (
        <div style={{ marginTop: 50 }}>
          <Spinner />
        </div>
      ) : (
        <Switch>
          <Route
            key="1"
            path="/admin/clubs/:clubId"
            render={({ match }) => {
              const club = clubs.find(c => `${c.id}` === match.params.clubId)!;
              return (
                <ClubContext.Provider value={{ ...club, membership: null }}>
                  <Switch>
                    <Route
                      path="/admin/clubs/:clubId/meet-and-greets"
                      component={PageClubMeetAndGreets}
                    />
                    <Route path="/admin/clubs/:clubId/settings" component={PageClubSettings} />
                    <Route path="/admin/clubs/:clubId/dashboard" component={PageClubDashboard} />
                    <Route path="/admin/clubs/:clubId/posts" component={PageClubPosts} />
                    <Route
                      path="/admin/clubs/:clubId/members/:memberId?"
                      component={PageClubMembers}
                    />
                    <Route path="/admin/clubs/:clubId/moderation" component={PageClubModeration} />
                  </Switch>
                </ClubContext.Provider>
              );
            }}
          />

          {user.role === 'admin'
            ? [
                <Route key="1" path="/admin/clubs" component={PageClubs} />,
                <Route key="2" path="/admin/home" component={PageHome} />,
                <Route key="3" path="/admin/users/:userId/activity" component={PageUserActivity} />,
                <Route key="4" path="/admin/users" component={PageUsers} />,
                <Redirect key="5" to="/admin/home" />,
              ]
            : [<Redirect key="1" to={`/admin/clubs/${clubs[0].id}/dashboard`} />]}
        </Switch>
      )}
    </div>
  );
};
