import { Button, ButtonGroup, HTMLSelect } from '@blueprintjs/core';
import moment from 'moment';
import React, { useContext } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ClubContext, SessionContext } from '../App';
import { BrandAlphaSteps } from '../Colors';
import { DashboardBox } from '../components/Common';
import { Feed } from '../components/feed/Feed';
import { useResource } from '../Resource';

const SevenDaysAgo = moment().subtract(7, 'days').unix() * 1000;
const StartOfDay = moment().startOf('day').unix() * 1000;

export const PageClubPosts: React.FunctionComponent<
  RouteComponentProps<{
    clubId: string;
  }>
> = props => {
  const [session] = useContext(SessionContext);
  const club = useContext(ClubContext);
  const [channelKey, setChannelKey] = React.useState<string>('main');
  const channel = club.config.channels.find(c => c.key === channelKey);
  const adminId = club.config.artistUserId || session!.user!.id;

  const [previewTierKey, setPreviewTierKey] = React.useState<string | null>(
    club.tiers[club.tiers.length - 1]?.key || null
  );

  const [blocks] = useResource<CoreAPI.MeetAndGreetBlock[]>(
    `/api/admin/v1/clubs/${club.id}/meet-and-greets`
  );

  const [contentReports] = useResource<CoreAPI.ContentReport[]>(
    `/api/admin/v1/clubs/${club.id}/content-reports`
  );
  const contentReportsInWeek = (contentReports || []).filter(c => c.createdAt > SevenDaysAgo);
  const nextBlock = (blocks || []).find(b => b.startDate > StartOfDay);

  if (!channel) {
    return <span>Channel {channelKey} not found. Please reload the page.</span>;
  }

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1 }}></div>
      <div style={{ maxWidth: 1200, width: 1200, minWidth: 500, display: 'flex' }}>
        <PostsColumn>
          <div style={{ display: 'flex', alignItems: 'center', margin: 10, marginTop: 0 }}>
            <HTMLSelect onChange={e => setChannelKey(e.target.value)} value={channelKey}>
              {club.config.channels.map(c => (
                <option value={c.key} key={c.key}>
                  {c.label}
                </option>
              ))}
            </HTMLSelect>
            <div style={{ flex: 1 }} />
            <strong>View as:&nbsp;&nbsp;</strong>
            <ButtonGroup>
              <Button active={previewTierKey === null} onClick={() => setPreviewTierKey(null)}>
                Guest
              </Button>
              {club.tiers.map(tier => (
                <Button
                  key={tier.key}
                  active={previewTierKey === tier.key}
                  onClick={() => setPreviewTierKey(tier.key)}
                >
                  {tier.name}
                </Button>
              ))}
            </ButtonGroup>
          </div>
          <ClubContext.Provider
            value={{
              ...club,
              membership: previewTierKey
                ? {
                    id: 0,
                    tier: previewTierKey,
                    createdAt: 0,
                    permissions: 'member',
                    billing: null,
                    billingPeriodEnd: null,
                    feedback: null,
                    clubId: club.id,
                    userId: adminId,
                    settings: { lastSeenChannelsAt: {} },
                  }
                : null,
            }}
          >
            <Feed club={club} channel={channel} target={null} adminPage={true} />
          </ClubContext.Provider>
        </PostsColumn>
        <div style={{ width: 20 }} />
        <ExtrasColumn>
          <h3>Meet and Greets</h3>
          <DashboardBox>
            {nextBlock ? (
              <p>
                Your next meet and greet session is{' '}
                <strong>{moment(nextBlock.startDate).format('LLLL')}</strong>
              </p>
            ) : (
              <p>You have no upcoming meet and greet sessions.</p>
            )}
            <Link to={`/admin/clubs/${club.id}/meet-and-greets`}>
              <Button>View Schedule</Button>
            </Link>
          </DashboardBox>
          <div style={{ height: 20 }} />
          <h3>Moderation</h3>
          <DashboardBox>
            <p>There have been {contentReportsInWeek.length} reports in the last 7 days.</p>
            <Link to={`/admin/clubs/${club.id}/moderation`}>
              <Button>Review</Button>
            </Link>
          </DashboardBox>
        </ExtrasColumn>
      </div>
      <div style={{ flex: 1, background: BrandAlphaSteps[0] }}></div>
    </div>
  );
};

const PostsColumn = styled.div`
  width: 70%;
  padding: 20px;
  background: ${({ theme }) => theme.bgColor};
  @media (max-width: 700px) {
    width: 100%;
  }
`;

const ExtrasColumn = styled.div`
  width: 30%;
  max-width: 400px;
  padding: 20px;
  background: ${BrandAlphaSteps[0]};
  @media (max-width: 700px) {
    display: none;
  }
`;
