import React from 'react';
import styled from 'styled-components/macro';
import { Button } from './Button';
import { SheetModal } from './Common';
import { MuxVideo } from './embeds/MuxVideo';

export const WelcomeModal: React.FunctionComponent<{
  club: CoreAPI.ClubWithMembership;
  onDismiss: () => void;
}> = ({ club, onDismiss }) => {
  const [mobileStep, setMobileStep] = React.useState(club.config.welcomeVideo ? 1 : 2);

  return (
    <WelcomeModalContainer onClick={e => e.stopPropagation()}>
      <WelcomeFlex className={`mobileStep-${mobileStep}`}>
        {club.config.welcomeVideo && (
          <WelcomeVideoContainer>
            <div style={{ flex: 1, position: 'relative' }}>
              <MuxVideo autoPlay={true} aspectClassName="vjs-9-16" {...club.config.welcomeVideo} />
            </div>
            <Button
              intent="primary"
              size="normal"
              style={{ width: '100%' }}
              className={`mobileOnly`}
              onClick={() => setMobileStep(2)}
            >
              Continue
            </Button>
          </WelcomeVideoContainer>
        )}
        <WelcomeExplanation club={club} onDismiss={onDismiss} />
      </WelcomeFlex>
    </WelcomeModalContainer>
  );
};

const WelcomeExplanation: React.FunctionComponent<{
  club: CoreAPI.ClubWithMembership;
  onDismiss: () => void;
}> = ({ club, onDismiss }) => {
  const welcomeCopy = club.config.welcomeCopy;

  if (!welcomeCopy) {
    onDismiss();
    return <div />;
  }

  return (
    <WelcomeBullets>
      <h2 style={{ marginTop: 0, marginBottom: 30 }}>Welcome to the Crew</h2>
      <div style={{ flex: 1 }} />
      {welcomeCopy.sections[0] && (
        <div style={{ display: 'flex', marginBottom: 40 }}>
          <div style={{ marginRight: 15 }}>
            <div style={{ fontWeight: 700 }}>{welcomeCopy.sections[0].title}</div>
            {welcomeCopy.sections[0].description}
          </div>
          <img alt="vip" src={require('../images/vip.png').default} style={{ maxHeight: 70 }} />
        </div>
      )}
      {welcomeCopy.sections[1] && (
        <div style={{ display: 'flex', marginBottom: 40 }}>
          <img
            alt="ticket"
            src={require('../images/ticket.png').default}
            style={{ maxHeight: 70 }}
          />
          <div style={{ marginLeft: 15 }}>
            <div style={{ fontWeight: 700 }}>{welcomeCopy.sections[1].title}</div>{' '}
            {welcomeCopy.sections[1].description}
          </div>
        </div>
      )}
      {welcomeCopy.sections[2] && (
        <div style={{ display: 'flex', marginBottom: 40 }}>
          <div style={{ marginRight: 15 }}>
            <div style={{ fontWeight: 700 }}>{welcomeCopy.sections[2].title}</div>{' '}
            {welcomeCopy.sections[2].description}
          </div>
          <img
            alt="livestream"
            src={require('../images/livestream.png').default}
            style={{ maxHeight: 70, opacity: 0.7 }}
          />
        </div>
      )}
      {welcomeCopy.sections[3] && (
        <div style={{ display: 'flex', marginBottom: 40 }}>
          <img
            alt="tshirt"
            src={require('../images/tshirt.svg').default}
            style={{ maxHeight: 80, opacity: 0.8 }}
          />
          <div style={{ marginLeft: 15 }}>
            <div style={{ fontWeight: 700 }}>{welcomeCopy.sections[3].title}</div>{' '}
            {welcomeCopy.sections[3].description}
          </div>
        </div>
      )}
      <div style={{ flex: 1 }} />
      <Button
        intent="primary"
        size="normal"
        style={{ display: 'block', width: '100%' }}
        onClick={onDismiss}
      >
        Ok, Got it!
      </Button>
    </WelcomeBullets>
  );
};

const WelcomeBullets = styled.div`
  flex: 1;
  width: 350px;
  text-align: left;
  display: flex;
  flex-direction: column;

  & > ul {
    padding-left: 20px;
  }
  & li {
    margin-bottom: 15px;
  }
  & img {
    flex-shrink: 0;
  }
`;

const WelcomeVideoContainer = styled.div`
  width: 337px;
  height: 600px;
  max-height: 100%;
  display: flex;
  background: black;
  margin-right: 40px;
  flex-direction: column;
  position: relative;
  @media (max-width: 500px) {
    width: 320px;
    margin-right: 0;
  }
`;

const WelcomeFlex = styled.div`
  display: flex;
  & .mobileOnly {
    display: none;
  }

  @media (max-width: 500px) {
    & .mobileOnly {
      display: block;
    }
    &.mobileStep-1 ${WelcomeBullets} {
      display: none;
    }
    &.mobileStep-2 ${WelcomeVideoContainer} {
      display: none;
    }
  }
`;

const WelcomeModalContainer = styled(SheetModal)`
  padding: 30px;
  text-align: center;
  width: auto;

  @media (max-width: 500px) {
    width: 320px;
    padding: 10px;
  }
`;
