import { Icon } from '@blueprintjs/core';
import moment from 'moment-timezone';
import { default as React, useState } from 'react';
import styled from 'styled-components/macro';
import { ClubContext } from '../../App';
import { NativeBridge } from '../../NativeBridge';
import { makeRequest, useResource } from '../../Resource';
import { pluralize } from '../../Utils';
import { Button } from '../Button';
import { CondensedText, FeedItemBox, FeedItemContainer } from '../Common';
import { DecoratedTextBlock, DecoratedTextInput } from '../composer/DecoratedText';
import { Ellipsis, VerifiedIcon } from '../Icons';
import { hasOnlyFreeTier } from '../JoinModal';
import { ProfilePicture } from '../ProfilePicture';
import { Comments } from './Comments';
import { MoreActions, MoreActionsDropdown } from './MoreActionsDropdown';
import { PostBodyBasic } from './PostBodyBasic';
import { PostBodyLivestream } from './PostBodyLivestream';
import { PostBodyPoll } from './PostBodyPoll';
import { PostPaywall } from './PostPaywall';
import { Reactions } from './Reactions';

export async function reportContent(type: 'post' | 'response', clubId: number, id: number) {
  await makeRequest('/api/v1/report-content', 'POST', {
    clubId: clubId,
    contentId: id,
    contentType: type,
  });
  document.body.focus();
  window.alert(`Thank you. We will review this content and take action within 24 hours.`);
}

export const Post: React.FunctionComponent<{
  poster: CoreAPI.User | null;
  post: CoreAPI.Post;
  adminOptions: boolean;
  onUpdate: (post: CoreAPI.UpdatePostPayload) => void;
  onDelete?: () => void;
  onCopyLink?: () => void;
}> = ({ poster, post, adminOptions, onDelete, onUpdate, onCopyLink }) => {
  const club = React.useContext(ClubContext);
  const responsesURL = `/api/v1/clubs/${post.clubId}/posts/${post.id}/responses`;
  const [responses = [], ops] = useResource<
    CoreAPI.PostResponse[],
    CoreAPI.UpsertPostResponsePayload
  >(responsesURL, {}, { initial: post.responses });
  const [editing, setEditing] = useState(false);
  const [editInputValue, setEditInputValue] = useState(post.details.text);

  const {
    user,
    details: { text, previews },
  } = post;

  const formatText = (text: string) => {
    const wordsArr = text.split(' ');

    if (!previews?.length || wordsArr.length < 2) return text;

    const previewUrls = previews.map(p => p.url);

    let noMoreMatches = false;
    while (!noMoreMatches) {
      previewUrls.includes(wordsArr[wordsArr.length - 1]) ? wordsArr.pop() : (noMoreMatches = true);
    }
    return wordsArr.join(' ');
  };

  const formattedText = formatText(text);

  const onEditPost = () => {
    onUpdate({ ...post, details: { ...post.details, text: editInputValue } });
    setEditing(false);
  };

  const viewProfile = () =>
    NativeBridge.isAvailable() ? NativeBridge.viewProfile(user.id) : undefined;

  return (
    <FeedItemContainer data-post-id={post.id}>
      <FeedItemBox>
        <ProfilePicture
          user={user}
          size={window.innerWidth < 500 ? 40 : 50}
          onClick={viewProfile}
        />
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: 4, minWidth: 0 }}>
          <div style={{ display: 'flex', gap: 4, minWidth: 0, alignItems: 'center' }}>
            <Byline
              user={post.user}
              isArtist={club.config.artistUserId === post.user.id}
              onClick={viewProfile}
            />
            <div style={{ flex: 1 }} />
            {post.pinned ? <Icon icon="pin" style={{ opacity: 0.5 }} /> : ''}
            <MoreActionsDropdown tabIndex={0}>
              <img
                src={Ellipsis}
                alt="ellipsis"
                style={{ marginTop: 4, height: 16, rotate: '90deg' }}
              />
              <MoreActions>
                {adminOptions && onUpdate && (
                  <li onClick={() => onUpdate({ ...post, pinned: !post.pinned })}>
                    {post.pinned ? 'Unpin this Post' : 'Pin this Post'}
                  </li>
                )}
                <li onClick={() => reportContent('post', post.clubId, post.id)}>
                  Flag as Inappropriate
                </li>
                <li onClick={onCopyLink}>Copy Post Link</li>
                {adminOptions && post.details.type === 'text' && post.details.signup && (
                  <li onClick={() => (window.location.href = `${responsesURL}.xlsx`)}>
                    Download Responses
                  </li>
                )}
                {((adminOptions && !hasOnlyFreeTier(club)) || post.userId === poster?.id) && (
                  <li onClick={() => setEditing(true)}>Edit Post</li>
                )}
                {(adminOptions || post.userId === poster?.id) && onDelete && (
                  <li
                    onClick={() =>
                      (NativeBridge.isAvailable() ||
                        window.confirm('Are you sure you want to delete this post?')) &&
                      onDelete()
                    }
                  >
                    Delete Post
                  </li>
                )}
              </MoreActions>
            </MoreActionsDropdown>
          </div>
          <PostPaywall audience={post.audience}>
            {editing ? (
              <>
                <DecoratedTextInput
                  initialValue={post.details.text}
                  onChange={setEditInputValue}
                  placeholder="Write a post..."
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 6, marginTop: 10 }}>
                  <Button
                    onClick={() => {
                      setEditing(false);
                      setEditInputValue(post.details.text);
                    }}
                    size="small"
                  >
                    Cancel
                  </Button>
                  <Button intent="primary" onClick={onEditPost} size="small">
                    Update
                  </Button>
                </div>
              </>
            ) : (
              <DecoratedTextBlock text={formattedText} />
            )}
            {post.details.type === 'poll' ? (
              <PostBodyPoll
                details={post.details}
                responses={responses}
                onCreate={ops.post}
                poster={poster}
              />
            ) : post.details.type === 'livestream' ? (
              <PostBodyLivestream club={club} post={post} details={post.details} />
            ) : (
              <PostBodyBasic details={post.details} />
            )}
            {post.details.type === 'text' && post.details.signup && (
              <PostSignup
                signupDetails={post.details.signup}
                poster={poster}
                responses={responses}
                onDelete={ops.deleteItem}
                onCreate={ops.post}
                adminConfig={
                  adminOptions
                    ? {
                        downloadLink: `${responsesURL}.xlsx?type=signup`,
                        onUpdate: signup => {
                          if ('signup' in post.details)
                            onUpdate({ ...post, details: { ...post.details, signup } });
                        },
                      }
                    : undefined
                }
              />
            )}
          </PostPaywall>
          {post.audience.onlyTiers && (
            <div style={{ fontSize: 13, opacity: 0.5 }}>{`Exclusive to ${post.audience.onlyTiers
              .map(key => club.tiers.find(t => t.key === key)?.name || key)
              .join(' and ')} members`}</div>
          )}
          {post.audience.onlyLocations && (
            <div
              style={{ fontSize: 13, opacity: 0.5 }}
            >{`Exclusive to fans near ${post.audience.onlyLocations
              .map(l => l.text)
              .join(' or ')}`}</div>
          )}
          <PostFooter>
            <div style={{ display: 'flex', opacity: 0.5, gap: 4 }}>
              {moment(post.postedAt || post.scheduledAt).fromNow()}
              {!post.postedAt && <span>({moment(post.scheduledAt).format('llll')})</span>}
              {!!post.editedAt && <span>(edited)</span>}
            </div>
            {!editing && (
              <Reactions
                poster={poster}
                responses={responses}
                onDelete={ops.deleteItem}
                onCreate={ops.post}
              />
            )}
          </PostFooter>
          <Comments responses={responses} ops={ops} poster={poster} adminOptions={adminOptions} />
        </div>
      </FeedItemBox>
    </FeedItemContainer>
  );
};

const Byline: React.FunctionComponent<{
  user: CoreAPI.User;
  isArtist: boolean;
  onClick?: () => void;
}> = ({ user, isArtist, onClick }) => {
  return (
    <div onClick={onClick} style={{ display: 'flex', gap: 6, alignItems: 'baseline', minWidth: 0 }}>
      <BylineName>
        {user.firstName} {user.lastName}
      </BylineName>
      {isArtist && <img src={VerifiedIcon} alt="verified" />}
      <BylineUsername>@{user.username}</BylineUsername>
    </div>
  );
};

const PostSignup: React.FunctionComponent<{
  signupDetails: CoreAPI.SignupDetails;
  responses: CoreAPI.PostResponse[];
  poster: CoreAPI.User | null;
  onDelete: (r: CoreAPI.PostResponse) => void;
  onCreate: (r: CoreAPI.UpsertPostResponsePayload) => void;
  adminConfig?: {
    downloadLink: string;
    onUpdate: (d: CoreAPI.SignupDetails) => void;
  };
}> = ({ signupDetails, responses, poster, onDelete, onCreate, adminConfig }) => {
  const { label: signupLabel, closed } = signupDetails;
  const count = responses.filter(r => r.details.type === 'signup').length;

  let buttonProps: { activated: boolean; label: string; onClick: () => void };
  if (adminConfig) {
    buttonProps = {
      activated: !closed,
      onClick: () => adminConfig.onUpdate({ closed: !closed, label: signupLabel }),
      label: closed ? 'Re-open signup' : 'Close signup',
    };
  } else {
    const mine = responses.find(r => r.details.type === 'signup' && r.userId === poster?.id);

    buttonProps = {
      activated: !!mine,
      onClick: () =>
        mine
          ? onDelete(mine)
          : onCreate({ userId: poster?.id || undefined, details: { type: 'signup' } }),
      label: mine ? 'Thanks for entering!' : closed ? 'This signup is closed.' : signupLabel,
    };
  }

  const { activated, label, onClick } = buttonProps;

  return (
    <div style={{ display: 'flex', gap: 12, alignItems: 'baseline', flexShrink: 1 }}>
      <Button
        disabled={!adminConfig && closed}
        size="normal"
        intent={activated ? 'primary' : 'none'}
        onClick={onClick}
      >
        {label}
      </Button>
      {adminConfig?.downloadLink ? (
        <a href={adminConfig.downloadLink}>Download {pluralize(count, 'Response')}</a>
      ) : undefined}
    </div>
  );
};

const BylineName = styled(CondensedText)`
  opacity: 0.9;
  font-weight: 500;
  font-size: 17px;
  white-space: nowrap;
  @media (max-width: 500px) {
    font-size: 14px;
    font-weight: 600;
  }
`;

const BylineUsername = styled(CondensedText)`
  opacity: 0.7;
  font-size: 15px;
  @media (max-width: 500px) {
    font-size: 13px;
  }
`;

const PostFooter = styled.div`
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  @media (max-width: 500px) {
    font-size: 12px;
    flex-direction: column;
    gap: 12px;
  }
`;
