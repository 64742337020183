import { Radio, RadioGroup, TextArea } from '@blueprintjs/core';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { Button } from '../components/Button';
import { SheetModal } from '../components/Common';
import { makeRequest } from '../Resource';

const Reasons = [
  `I was overcharged`,
  `I'm not happy with With the Band's features or services`,
  `I only became a member to receive a specific benefit and now I've got it`,
  `It was too hard to find or access my benefits/rewards`,
  `I only intended to give *ARTIST* a specific amount of money and now I have`,
  `*ARTIST* wasn't as active as I expected`,
  `I didn't like the benefits/rewards that I received from *ARTIST*`,
  `I didn't receive the benefits/rewards described on *ARTIST*'s page`,
  `My financial situation changed`,
];

const OtherReason = `Some other reason`;

export const ManageModalCancelMembership: React.FunctionComponent<{
  club: CoreAPI.ClubWithMembership;
  onDone: () => void;
}> = ({ club, onDone }) => {
  const [feedback, setFeedback] = React.useState<CoreAPI.ClubMembershipFeedback>({
    cancelationReason: '',
  });

  const onConfirm = async () => {
    const resp = await makeRequest<{ error?: string }>(
      `/api/v1/clubs/${club.id}/cancel-membership`,
      'POST',
      feedback
    );
    if ('error' in resp) {
      alert(resp.error);
    } else {
      onDone();
    }
  };

  const date = moment(club.membership?.billingPeriodEnd).format('YYYY-MM-DD');
  const isStandardReason =
    feedback.cancelationReason === '' || Reasons.includes(feedback.cancelationReason);

  return (
    <CancelModalContainer onClick={e => e.stopPropagation()}>
      <h2>Cancel Subscription</h2>
      <p>
        Your membership to <strong>{club.name}</strong> will be cancelled at the end of your current
        billing period ({date}) and will not renew. Until then, you can continue participating in
        the fan crew!
      </p>
      <p>If you have a moment, let us know why you're leaving:</p>
      <RadioGroup
        selectedValue={isStandardReason ? feedback.cancelationReason : OtherReason}
        onChange={e => setFeedback({ ...feedback, cancelationReason: e.currentTarget.value })}
      >
        {[...Reasons, OtherReason].map(r => (
          <Radio value={r} label={r.replace('*ARTIST*', club.name)} key={r} />
        ))}
      </RadioGroup>
      {!isStandardReason && (
        <TextArea
          fill={true}
          large={true}
          onChange={e => setFeedback({ ...feedback, cancelationReason: e.currentTarget.value })}
          value={feedback.cancelationReason === OtherReason ? '' : feedback.cancelationReason}
        />
      )}
      <div style={{ display: 'flex', marginTop: 30 }}>
        <Button onClick={onDone} size="small">
          Back
        </Button>
        <div style={{ flex: 1 }} />
        <Button
          onClick={feedback.cancelationReason ? onConfirm : undefined}
          disabled={feedback.cancelationReason === ''}
          size="small"
          intent="primary"
        >
          Cancel Subscription
        </Button>
      </div>
    </CancelModalContainer>
  );
};

const CancelModalContainer = styled(SheetModal)`
  text-align: left;
  padding: 15px 30px 30px;
  max-width: 550px;
`;
