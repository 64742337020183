import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

export const JoinThankYou = () => {
  const ref = React.createRef<HTMLDivElement>();
  React.useEffect(() => {
    let script = document.getElementById('lottie-script');
    if (!script) {
      script = document.createElement('script');
      script.setAttribute(
        'src',
        'https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js'
      );
      document.body.appendChild(script);
    }
    const lottie = document.createElement('lottie-player');
    lottie.setAttribute('autoplay', 'true');
    lottie.setAttribute('background', 'transparent');
    lottie.setAttribute(
      'src',
      'https://assets10.lottiefiles.com/datafiles/OivQWebdu3tdxIt/data.json'
    );
    lottie.setAttribute('speed', '1');
    lottie.style.width = '400px';
    lottie.style.height = '400px';
    lottie.style.margin = 'auto';
    ref.current?.appendChild(lottie);
  }, [ref]);

  return (
    <ThankYouContainer>
      <div ref={ref} />
      <div style={{ position: 'relative', top: -140 }}>
        <h2>All Set!</h2>
        <div style={{ opacity: 0.6 }}>Redirecting you in a moment...</div>
      </div>
    </ThankYouContainer>
  );
};

const rotate = keyframes`
    from {
      opacity: 0;
      transform: translate(0, 40px);
    }
  
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  `;

const ThankYouContainer = styled.div`
  animation: ${rotate} 200ms linear;
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;
