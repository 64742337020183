import { Icon } from '@blueprintjs/core';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components/macro';
import tinycolor from 'tinycolor2';
import { SessionContext } from '../../App';
import { useInterval } from '../../Hooks';
import { UnreadNotifs } from '../../pages-club/useUnreadNotifs';
import { useCopyToClipboard } from '../../useCopyToClipboard';
import { shareLinkForPost } from '../../Utils';
import { AdminSelect } from '../AdminSelect';
import { ClickableItem, FeedItemBox } from '../Common';
import { MemberPostComposer } from '../composer/MemberPostComposer';
import { PostComposer } from '../composer/PostComposer';
import { hasOnlyFreeTier } from '../JoinModal';
import { MeetAndGreetBanners } from './MeetAndGreetBanner';
import { Post } from './Post';
import { PostOps, usePaginatedPosts } from './usePaginatedPosts';
import { useScrollToFeedTarget } from './useScrollToFeedTarget';

interface FeedProps {
  club: CoreAPI.ClubWithMembership;
  channel: CoreAPI.ClubFeedChannel;
  target: CoreAPI.FeedTarget | null;
  setTarget?: (t: CoreAPI.FeedTarget | null) => void;
  unreadNotifOps?: UnreadNotifs['actions'];
  adminPage?: true;
}

export const Feed = React.forwardRef(
  ({ club, channel, target, setTarget, unreadNotifOps, adminPage }: FeedProps, feedRef) => {
    const [session] = React.useContext(SessionContext);

    const fanCreatedClub = hasOnlyFreeTier(club);

    const { posts, postOps, hasMorePosts, loaded } = usePaginatedPosts({
      clubSlug: club.slug,
      channelKey: channel.key,
      includeScheduled: adminPage || false,
    });

    const { containerRef } = useScrollToFeedTarget(feedRef, loaded, target, setTarget);

    const membership = session.clubs?.find(c => c.id === club.id)?.membership;
    const isMember = !!membership;
    const isAdmin = membership?.permissions === 'owner' || session.user?.role === 'admin';
    const [poster, setPoster] = useState(isMember ? session.user : null);

    // Reload unread states every 30s
    useInterval(unreadNotifOps?.refreshNotifs || NO_OP, unreadNotifOps ? 30000 : 0);

    // Mark channel notifs as read once posts load
    React.useEffect(() => {
      loaded && unreadNotifOps?.readChannelNotif(channel.key);
    }, [loaded, unreadNotifOps, channel.key]);

    const onPost = (postId: number) => {
      postOps.load('latest');
      setTarget?.({ type: 'post', id: postId, channelKey: channel.key });
    };

    return (
      <FeedContainer ref={containerRef}>
        {poster ? (
          (!channel.restricted || isAdmin) && (
            <>
              {isAdmin && !fanCreatedClub && (
                <AdminSelect
                  artistUserId={club.config.artistUserId}
                  clubId={club.id}
                  posterId={poster.id}
                  onChange={setPoster}
                />
              )}
              {adminPage ? (
                <PostComposer
                  club={club}
                  channel={channel}
                  user={poster}
                  isAdmin={true}
                  canTest={session.user?.role === 'admin'}
                  onFinished={onPost}
                />
              ) : (
                <MemberPostComposer
                  club={club}
                  channel={channel}
                  user={poster}
                  isAdmin={isAdmin}
                  onFinished={onPost}
                />
              )}
            </>
          )
        ) : (
          <span />
        )}
        <MeetAndGreetBanners club={club} />
        <PostSection
          channelSlug={channel.slug}
          club={club}
          posts={posts}
          postConfig={{ poster, hasMorePosts, postOps, isAdmin, adminPage }}
        />
      </FeedContainer>
    );
  }
);

type PostSortOrder = 'trending' | 'newest';

const PostSection: React.FC<{
  channelSlug: string;
  club: CoreAPI.Club;
  posts: CoreAPI.Post[];
  postConfig: {
    poster: CoreAPI.CurrentUser | null;
    hasMorePosts: boolean;
    postOps: PostOps;
    isAdmin: boolean;
    adminPage?: true;
  };
}> = ({ posts, postConfig, club, channelSlug }) => {
  const { poster, hasMorePosts, postOps, isAdmin, adminPage } = postConfig;
  const copyToClipboard = useCopyToClipboard();
  const [sortOrder, setSortOrder] = useState<PostSortOrder>('newest');
  const sortedPosts = React.useMemo(() => sortPosts(posts, sortOrder), [posts, sortOrder]);

  return (
    <>
      {!adminPage && (
        <PostSortButtons>
          <ClickableItem
            onClick={() => setSortOrder('trending')}
            style={{ fontWeight: sortOrder === 'trending' ? 800 : 400 }}
          >
            Trending
          </ClickableItem>
          <Icon style={{ padding: '0 2px' }} icon="dot" />
          <ClickableItem
            onClick={() => setSortOrder('newest')}
            style={{ fontWeight: sortOrder === 'newest' ? 800 : 400 }}
          >
            Newest
          </ClickableItem>
        </PostSortButtons>
      )}
      <InfiniteScroll
        threshold={750}
        loadMore={() => postOps.load('next')}
        hasMore={hasMorePosts}
        initialLoad={false}
        loader={<div key="loading">Loading...</div>}
      >
        {sortedPosts.map(p => (
          <Post
            poster={poster}
            post={p}
            key={p.id}
            adminOptions={isAdmin}
            onDelete={() => postOps.onDelete(p.id)}
            onUpdate={p => postOps.onUpdate(p)}
            onCopyLink={() => copyToClipboard(shareLinkForPost(club, p, channelSlug))}
          />
        ))}
      </InfiniteScroll>
      {!poster && (
        <FeedItemBox style={{ textAlign: 'center', marginTop: 10 }}>Join to See More</FeedItemBox>
      )}
      {poster && !!posts.length && !hasMorePosts && (
        <FeedItemBox style={{ textAlign: 'center', margin: '0 12px' }}>
          You've seen all the posts!
        </FeedItemBox>
      )}
    </>
  );
};

const FeedContainer = styled.div`
  flex: 1;
  @media (max-width: 500px) {
    margin-top: 32px;
  }
`;

const PostSortButtons = styled.div`
  display: flex;
  color: ${({ theme }) => (tinycolor(theme.bgColor).isLight() ? '#000' : '#fff')} !important;
  margin-right: 12px;
  justify-content: flex-end;
  position: relative;
`;

function sortPosts(posts: CoreAPI.Post[], sortOrder: PostSortOrder) {
  return sortOrder === 'newest'
    ? posts
    : [...posts].sort((a, b) => {
        if (a.pinned && !b.pinned) return -1;
        if (!a.pinned && b.pinned) return 1;

        const now = moment();
        const avgResponseDate = (responses: CoreAPI.PostResponse[]) => {
          const sum = responses.reduce((acc, r) => acc + r.createdAt, 0);
          return sum / responses.length;
        };
        const numOfDaysSince = (date: number) => now.diff(moment(date), 'days');

        const recencyScore = (id: number) => {
          const recency = (createdAt: number, responses: CoreAPI.PostResponse[]) => {
            const sinceCreated = numOfDaysSince(createdAt);
            return !!responses.length
              ? (sinceCreated + numOfDaysSince(avgResponseDate(responses))) / 2
              : sinceCreated;
          };
          // switch a + b scores bc lower recency is weighted higher
          const aRaw = recency(b.createdAt, b.responses);
          const bRaw = recency(a.createdAt, a.responses);
          const total = Math.max(aRaw, bRaw);
          return ((id === a.id ? aRaw : bRaw) / total) * 0.6 || 0; // recency weight is 60%
        };

        const responseScore = (id: number) => {
          const aRaw = a.responses.length;
          const bRaw = b.responses.length;
          const total = Math.max(aRaw, bRaw);
          return ((id === a.id ? aRaw : bRaw) / total) * 0.4 || 0; // response weight is 40%
        };
        const aScore = responseScore(a.id) + recencyScore(a.id);
        const bScore = responseScore(b.id) + recencyScore(b.id);

        return bScore - aScore;
      });
}

const NO_OP = () => {};
