import React from 'react';
import { Button } from '@blueprintjs/core';
import { TimePicker, DateInput } from '@blueprintjs/datetime';
import { BrandAlphaSteps } from '../../Colors';
import moment from 'moment-timezone';
import styled from 'styled-components/macro';

export const PostStreamComposer: React.FunctionComponent<{
  values: CoreAPI.PostDetailsZoom;
  onChange: (values: CoreAPI.PostDetailsZoom) => void;
  onRemove: () => void;
}> = ({ values, onChange, onRemove }) => {
  const date = new Date(values.start);
  const setDate = (date: Date) => onChange({ ...values, start: date.getTime() });

  return (
    <div style={{ background: BrandAlphaSteps[0], marginTop: 18, padding: 10 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <strong>Start at:</strong>
        <div style={{ width: 5 }} />
        <DateInput
          formatDate={date => moment(date).format('MM/DD/YYYY')}
          onChange={setDate}
          parseDate={str => new Date(str)}
          placeholder={'M/D/YYYY'}
          value={date}
        />
        <div style={{ width: 5 }} />
        <TimePickerStyled value={date} onChange={setDate} useAmPm />
        <div style={{ width: 5 }} />
        <div style={{ opacity: 0.6 }}>{moment.tz.guess()}</div>
      </div>
      <div
        style={{
          borderTop: '1px solid rgba(0,0,0,0.2)',
          paddingTop: 8,
          marginTop: 8,
          display: 'flex',
        }}
      >
        <Button onClick={onRemove} intent="danger" minimal fill>
          Remove Live Stream
        </Button>
      </div>
    </div>
  );
};

const TimePickerStyled = styled(TimePicker)`
  & .bp3-timepicker-input {
    font-size: 14px;
  }
`;
