import React from 'react';
import styled from 'styled-components';
import { AddressForm, EmptyAddressValues } from '../components/AddressForm';
import { Button } from '../components/Button';
import { ErrorMessage, Form, SheetModal } from '../components/Common';
import { ThumbnailInput } from '../components/TextInputs';
import { makeRequest } from '../Resource';

export const ManageModalEditProfile: React.FunctionComponent<{
  initial: CoreAPI.CurrentUser;
  onDone: () => void;
}> = props => {
  const [error, setError] = React.useState<string | null>(null);
  const [saving, setSaving] = React.useState(false);
  const [values, setValues] = React.useState(props.initial);

  const onSave = async (e: React.FormEvent<any> | React.MouseEvent<any>) => {
    e.preventDefault();
    setSaving(true);
    setError(null);
    const result = await makeRequest<CoreAPI.CurrentUser | { error: string }>(
      `/api/v1/users/${values.id}`,
      'PUT',
      values
    );
    setSaving(false);
    if ('id' in result) {
      props.onDone();
    } else {
      setError(result.error);
    }
  };

  return (
    <EditProfileModalContainer onClick={e => e.stopPropagation()}>
      <Form onSubmit={onSave} style={{ padding: '5px 15px' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <h3>Public Profile</h3>
            {error && <ErrorMessage style={{ marginBottom: 10 }}>{error}</ErrorMessage>}
            <div style={{ display: 'flex' }}>
              <ThumbnailInput
                label={'Profile Picture'}
                format={'jpg'}
                showRawURL={false}
                desiredSize={{ width: 150, height: 150 }}
                value={values.profilePictureURL || ''}
                onChange={u => setValues({ ...values, profilePictureURL: u || null })}
              />
              <div style={{ marginLeft: 20, flex: 1 }}>
                <label className="form-block" style={{ flex: 1 }}>
                  <div>Username</div>
                  <input
                    className="field"
                    type="text"
                    value={values.username}
                    onChange={e => setValues({ ...values, username: e.target.value })}
                  />
                </label>
                <label className="form-block" style={{ flex: 1 }}>
                  <div>Bio</div>
                  <textarea
                    className="field"
                    value={values.bio}
                    onChange={e => setValues({ ...values, bio: e.target.value })}
                  />
                </label>
                <label className="form-block" style={{ flex: 1 }}>
                  <div>Hometown</div>
                  <input
                    className="field"
                    type="text"
                    value={values.hometown || ''}
                    onChange={e => setValues({ ...values, hometown: e.target.value })}
                  />
                </label>
              </div>
            </div>
          </div>
          <div style={{ width: 25, borderRight: '1px solid #ddd', marginRight: 25 }} />
          <div style={{ flex: 1 }}>
            <h3>Contact Info</h3>
            <div style={{ display: 'flex' }}>
              <label className="form-block" style={{ flex: 1 }}>
                <div>First Name</div>
                <input
                  className="field"
                  type="text"
                  value={values.firstName}
                  onChange={e => setValues({ ...values, firstName: e.target.value })}
                />
              </label>
              <div style={{ width: 10 }} />
              <label className="form-block" style={{ flex: 1 }}>
                <div>Last Name</div>
                <input
                  className="field"
                  type="text"
                  value={values.lastName}
                  onChange={e => setValues({ ...values, lastName: e.target.value })}
                />
              </label>{' '}
            </div>
            <div style={{ display: 'flex' }}>
              <label className="form-block" style={{ flex: 1 }}>
                <div>Email Address</div>
                <input
                  className="field"
                  type="email"
                  value={values.email}
                  onChange={e => setValues({ ...values, email: e.target.value })}
                />
              </label>
              <div style={{ width: 10 }} />
              <label className="form-block" style={{ flex: 1 }}>
                <div>Phone Number</div>
                <input
                  className="field"
                  type="text"
                  defaultValue={values.phoneNumber?.slice(2) || undefined}
                  onChange={e => setValues({ ...values, phoneNumber: e.target.value })}
                />
              </label>
            </div>

            <AddressForm
              value={values.shippingAddress || EmptyAddressValues}
              onChange={shippingAddress => setValues({ ...values, shippingAddress })}
            />
          </div>
        </div>
        <hr style={{ marginTop: 20, marginBottom: 20 }} />
        <input type="submit" style={{ display: 'none' }} />
        <div style={{ textAlign: 'center' }}>
          <Button onClick={onSave} size="small" disabled={saving}>
            {saving ? 'Saving... ' : 'Save Changes'}
          </Button>
        </div>
      </Form>
    </EditProfileModalContainer>
  );
};

const EditProfileModalContainer = styled(SheetModal)`
  text-align: left;
  max-width: 950px;
  padding-bottom: 15px;
`;
