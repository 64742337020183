import moment from 'moment';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { AppToaster, ClubContext } from '../App';
import { Member } from '../pages-dashboard/PageClubMembers';
import { makeRequest, useResource } from '../Resource';
import { formatPrice, getTierName } from '../Utils';
import { InfoRow, SheetModal } from './Common';
import { ProfileDetails } from './ProfileDetails';
import { Checkbox, Button, Popover, PopoverInteractionKind, Intent } from '@blueprintjs/core';

export const MembershipDetailsModal: React.FC<{
  member: Member;
  onUpdateMember: (member: Member) => void;
}> = ({ member, onUpdateMember }) => {
  const club = useContext(ClubContext);
  const [activities = []] = useResource<(CoreAPI.Post | CoreAPI.PostResponse)[]>(
    `/api/admin/v1/users/${member.user.id}/activities/${club.id}`
  );
  const [payments = []] = useResource<CoreAPI.Charge[]>(
    `/api/admin/v1/users/${member.user.id}/charges/${club.id}`
  );

  const tierRewards =
    member.permissions === 'member'
      ? club.config.rewards?.filter(r => r.tiers.includes(member.tier))
      : [];

  return (
    <SheetModal style={{ maxHeight: '90vh' }} onClick={e => e.stopPropagation()}>
      <div style={{ display: 'flex', padding: 30 }}>
        <div style={{ flex: 1, paddingRight: 30, borderRight: `1px solid #ccc`, minWidth: 0 }}>
          <h3 style={{ textTransform: 'uppercase', marginBottom: 0 }}>Profile</h3>
          <ProfileDetails user={member.user} />
          <InfoRow>
            <label>User Activity</label>
            <div style={{ marginTop: 5, minWidth: 0, wordWrap: 'break-word' }}>
              {!!activities.length ? <ActivitiesTable activities={activities} /> : <em>None</em>}
            </div>
          </InfoRow>
        </div>
        <div style={{ flex: 1, paddingLeft: 30 }}>
          <h3 style={{ textTransform: 'uppercase' }}>Membership</h3>
          <InfoRow>
            <label>Tier</label>
            <div>{getTierName(member.tier, club)}</div>
          </InfoRow>
          {!!tierRewards.length && (
            <UserRewardsEditor
              tierRewards={tierRewards}
              member={member}
              onUpdate={settings => onUpdateMember({ ...member, settings })}
            />
          )}
          <InfoRow>
            <label>
              Payment History{' '}
              {!!payments.length && (
                <a
                  href="https://dashboard.stripe.com/subscriptions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View on Stripe
                </a>
              )}
            </label>
            <div style={{ marginTop: 5 }}>
              {!!payments.length ? <ChargesTable charges={payments} /> : <em>None</em>}
            </div>
          </InfoRow>
        </div>
      </div>
    </SheetModal>
  );
};

const UserRewardsEditor = ({
  tierRewards,
  member,
  onUpdate,
}: {
  tierRewards: CoreAPI.ClubReward[];
  member: Member;
  onUpdate: (settings: CoreAPI.ClubMembershipSettings) => void;
}) => {
  const { rewardsReceived = [] } = member.settings;
  const rewardsReceivedWithNames = rewardsReceived.reduce((acc, received) => {
    const rewardName = tierRewards.find(r => r.rewardId === received.rewardId)?.name;
    if (rewardName) acc.push({ ...received, name: rewardName });
    return acc;
  }, [] as (CoreAPI.MembershipReward & { name: string })[]);
  const [selected, setSelected] = useState<string[]>(rewardsReceived.map(r => r.rewardId));

  const handleUpdate = async () => {
    const updatedRewards: CoreAPI.MembershipReward[] = selected.map(r => {
      const existing = rewardsReceived.find(received => received.rewardId === r);
      return existing || { rewardId: r, receivedAt: Date.now() };
    });

    const update = await makeRequest<{ error?: string }>(
      `/api/v1/users/membership-rewards/${member.id}`,
      'PUT',
      { rewardsReceived: updatedRewards }
    );
    if (!update.error) {
      AppToaster.show({
        intent: Intent.SUCCESS,
        message: 'Rewards updated',
      });
      onUpdate({ ...member.settings, rewardsReceived: updatedRewards });
    }
  };

  return (
    <InfoRow>
      <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <label>Rewards Received</label>
        <Popover
          interactionKind={PopoverInteractionKind.CLICK}
          popoverClassName="bp3-popover-content-sizing"
        >
          <Button icon="edit" minimal small />
          <div>
            <h4 style={{ marginTop: 0 }}>Track Rewards Received</h4>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              <div>
                {tierRewards.map(r => (
                  <Checkbox
                    key={r.rewardId}
                    label={r.name}
                    checked={selected.some(s => s === r.rewardId)}
                    onChange={e =>
                      setSelected(prev =>
                        prev.includes(r.rewardId)
                          ? prev.filter(s => s !== r.rewardId)
                          : [...prev, r.rewardId]
                      )
                    }
                  />
                ))}
              </div>
              <div style={{ display: 'flex', gap: 10, justifyContent: 'flex-end' }}>
                <Button className="bp3-popover-dismiss">Dismiss</Button>
                <Button
                  className="bp3-popover-dismiss"
                  intent="danger"
                  onClick={() => handleUpdate()}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </Popover>
      </div>
      <div>
        {rewardsReceivedWithNames.length ? (
          <ul style={{ listStylePosition: 'outside', marginLeft: -20, marginTop: 0 }}>
            {rewardsReceivedWithNames.map(r => (
              <li key={r.rewardId}>
                {r.name}: {moment(r.receivedAt).format('L')}
              </li>
            ))}
          </ul>
        ) : (
          <em>None</em>
        )}
      </div>
    </InfoRow>
  );
};

const ActivitiesTable: React.FC<{ activities: (CoreAPI.Post | CoreAPI.PostResponse)[] }> = ({
  activities,
}) => {
  return (
    <table style={{ width: '100%', minWidth: 0, border: '1px solid #ccc' }}>
      <thead style={{ background: '#f0f5fd' }}>
        <tr>
          <td>Date</td>
          <td>Activity</td>
          <td>Description</td>
        </tr>
      </thead>
      <tbody>
        {activities.map((a, id) => {
          let activityType = 'post';
          let text = '';

          if ('postId' in a) activityType = a.details.type;
          if ('text' in a.details) text = a.details.text;

          return (
            <tr key={`activity-${id}`}>
              <DataCell>{moment(a.createdAt).format('MM/DD/YYYY hh:mm a')}</DataCell>
              <DataCell>{activityType}</DataCell>
              <DataCell style={{ wordBreak: 'break-word' }}>{text}</DataCell>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const ChargesTable: React.FC<{ charges: CoreAPI.Charge[] }> = ({ charges }) => {
  return (
    <table style={{ width: '100%', border: '1px solid #ccc' }}>
      <thead style={{ background: '#f0f5fd' }}>
        <tr>
          <td>Date</td>
          <td>Amount</td>
          <td>Description</td>
        </tr>
      </thead>
      <tbody>
        {charges.map(c => (
          <tr key={`charge-${c.id}`}>
            <DataCell>{c.paidAt ? moment.unix(c.paidAt).format('MM/DD/YYYY') : ''}</DataCell>
            <DataCell>{formatPrice(c.amount)}</DataCell>
            <DataCell>{c.status}</DataCell>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const DataCell = styled.td`
  padding-right: 10px;
  font-size: 12px;
  vertical-align: top;
`;
