import { HTMLSelect } from '@blueprintjs/core';
import React, { useEffect } from 'react';
import { useResource } from '../Resource';

export const AdminSelect: React.FC<{
  artistUserId: number | null;
  clubId: number;
  posterId: number;
  onChange: (admin: CoreAPI.CurrentUser) => void;
}> = ({ artistUserId, clubId, posterId, onChange }) => {
  const [admins = []] = useResource<{ user: CoreAPI.CurrentUser }[]>(
    `/api/admin/v1/clubs/${clubId}/admins`
  );

  const onSelect = (id: number) => {
    const user = admins.find(a => a.user.id === id)?.user;
    user && onChange(user);
  };

  useEffect(() => {
    if (artistUserId) onSelect(artistUserId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artistUserId, admins.length]);

  return (
    <HTMLSelect
      style={{ marginLeft: 10 }}
      onChange={e => onSelect(Number(e.target.value))}
      value={posterId}
    >
      {(admins || []).map(a => (
        <option value={a.user.id} key={a.user.id}>
          {a.user.username}
        </option>
      ))}
    </HTMLSelect>
  );
};
