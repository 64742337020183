import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import moment from 'moment';
import qs from 'query-string';
import React, { useContext } from 'react';
import { RouteChildrenProps } from 'react-router';
import { Link, Redirect } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ClubContext, SessionContext } from '../App';
import { Button } from '../components/Button';
import { CenteredMaxWidth, ClubHero } from '../components/Common';
import { hasOnlyFreeTier } from '../components/JoinModal';
import { makeRequest } from '../Resource';
import { formatPrice } from '../Utils';
import { JoinForm } from './JoinForm';
import { JoinFreeTierForm } from './JoinFreeTierForm';

const STRIPE_PUBLIC =
  (process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_STRIPE_PUBLIC_PROD
    : process.env.REACT_APP_STRIPE_PUBLIC_DEV) || '';

export const JoinPage: React.FunctionComponent<
  RouteChildrenProps<{
    tier: string;
  }>
> = props => {
  const [session] = useContext(SessionContext);
  const tier = props.match?.params.tier;
  const club = useContext(ClubContext);
  const tierInfo = club.tiers.find(t => t.key === tier);
  const stripePromise = React.useRef<Promise<Stripe | null>>(loadStripe(STRIPE_PUBLIC));

  const query = qs.parse(props.location.search.slice(1));
  const couponCode = (query.coupon || query.code || query.c || null) as string | null;

  const [couponInfo, setCouponInfo] = React.useState<null | CoreAPI.ClubCouponInfoResponse>(null);
  React.useEffect(() => {
    if (couponCode) {
      makeRequest<CoreAPI.ClubCouponInfoResponse>(
        `/api/v1/clubs/${club.slug}/coupon?code=${couponCode}&tier=${tier}`
      ).then(resp => setCouponInfo(resp));
    }
  }, [club.slug, couponCode, tier]);

  const onJoined = React.useCallback(async () => {
    // We do a full page change rather than a router transition so that queries
    // are fully re-run and we can be sure your membership is reflected everywhere.
    const next = (query.next as string) || `/${club.slug}`;
    window.location.href = next;
  }, [club.slug, query.next]);

  if (session && !session.user) {
    return (
      <Redirect
        to={`/register?${qs.stringify({
          next: `/${club.slug}/join/${tier}?${query}`,
        })}`}
      />
    );
  }

  return (
    <div>
      <ClubHero club={club} />
      {tierInfo ? (
        hasOnlyFreeTier(club) ? (
          <JoinCenteredMaxWidth className="page-container">
            <JoinFlexbox>
              <JoinFreeTierForm club={club} onJoined={onJoined} />
            </JoinFlexbox>
          </JoinCenteredMaxWidth>
        ) : (
          <JoinCenteredMaxWidth className="page-container">
            <JoinFlexbox>
              <JoinFormBox>
                <Elements stripe={stripePromise.current}>
                  <JoinForm
                    tier={tierInfo}
                    club={club}
                    couponCode={couponInfo && 'id' in couponInfo ? couponCode : null}
                    onJoined={onJoined}
                  />
                </Elements>
              </JoinFormBox>
              <JoinInfoBox>
                <SubscriptionTierInfo>
                  <img
                    alt="tier"
                    style={{ width: 40, paddingRight: 15 }}
                    src={require('../images/ticket.png').default}
                  />
                  <div style={{ flex: 1 }}>
                    <div style={{ fontWeight: 600, padding: '5px 0' }}>{tierInfo.name}</div>
                    <Link to={`/${club.slug}/join${couponCode ? `?coupon=${couponCode}` : ''}`}>
                      <Button size="small">Change Tier</Button>
                    </Link>
                  </div>
                  <div>
                    <div style={{ fontSize: '1.3rem', textAlign: 'right', fontWeight: 600 }}>
                      {formatPrice(tierInfo.price)}
                    </div>
                    <div style={{ opacity: 0.5, textAlign: 'right', fontSize: 14 }}>
                      <i>Cancel anytime</i>
                    </div>
                  </div>
                </SubscriptionTierInfo>

                {tierInfo.price ? (
                  <>
                    {couponCode && (
                      <CouponInfo>
                        {!couponInfo ? (
                          `Checking code...`
                        ) : 'error' in couponInfo ? (
                          couponInfo.error
                        ) : (
                          <>
                            <span
                              style={{ fontSize: 30, marginRight: 10 }}
                              role="img"
                              aria-label="gift"
                            >
                              🎁
                            </span>
                            <span>
                              Coupon{' '}
                              <strong style={{ fontFamily: 'monospace' }}>{couponCode}</strong>{' '}
                              applied! You'll receive{' '}
                              <strong>
                                {couponInfo.percent_off
                                  ? `${couponInfo.percent_off}%`
                                  : `$${couponInfo.amount_off}`}{' '}
                                off
                              </strong>{' '}
                              the membership price above for {couponInfo.duration_in_months} months.
                            </span>
                          </>
                        )}
                      </CouponInfo>
                    )}

                    {(!couponCode || (couponInfo && 'error' in couponInfo)) && (
                      <div>
                        Have a promo code? Apply it here:
                        <div style={{ display: 'flex', gap: 12, paddingTop: 6 }}>
                          <input
                            style={{
                              background: 'rgba(235,235,235,0.9)',
                              border: 'none',
                              padding: '8px 5px',
                              flex: 1,
                            }}
                            type="text"
                            defaultValue=""
                            id="promo-code-input"
                          />
                          <Button
                            size="small"
                            onClick={() => {
                              // Don't judge me i'm very lazy
                              const el = document.getElementById(
                                'promo-code-input'
                              ) as HTMLInputElement;
                              window.location.href = `./${tierInfo.key}?code=${el.value}`;
                            }}
                          >
                            Apply
                          </Button>
                        </div>
                      </div>
                    )}
                    <div style={{ flex: 1 }} />
                    <div style={{ fontSize: 13 }}>
                      <p style={{ fontWeight: 600 }}>
                        {`Your crew membership will renew automatically on ${moment()
                          .add(
                            1,
                            club.config.earnings?.billingCycle === 'monthly' ? 'month' : 'year'
                          )
                          .format('MMMM DD, YYYY')} and will include a payment processing fee of  ${
                          Math.round(((tierInfo.price * 0.029 + 30) / tierInfo.price) * 1000) / 10
                        }%.`}
                      </p>
                      <p>
                        You can cancel auto-renew at any time or edit your payment. By making this
                        payment, you agree to{' '}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="http://app.withtheband.co/terms"
                        >
                          With the Band's Terms of Use
                        </a>
                        .
                      </p>
                    </div>
                  </>
                ) : (
                  <p style={{ fontSize: 13 }}>
                    By joining this membership, you agree to{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://app.withtheband.co/terms"
                    >
                      With the Band's Terms of Use
                    </a>
                    .
                  </p>
                )}
              </JoinInfoBox>
            </JoinFlexbox>
            <p style={{ opacity: 0.6, fontSize: 13, marginTop: 5 }}>
              With the Band does not issue refunds on behalf of artists.{' '}
              <a target="_blank" rel="noopener noreferrer" href="http://app.withtheband.co/terms">
                Learn more.
              </a>
            </p>
          </JoinCenteredMaxWidth>
        )
      ) : (
        <div>Sorry, the membership tier could not be found.</div>
      )}
    </div>
  );
};

export const Box = styled.div`
  background: white;
  padding: 25px;
  flex: 1;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
`;

const SubscriptionTierInfo = styled.div`
  padding-bottom: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
`;

const JoinFormBox = styled(Box)`
  margin-right: -25px;
  padding-right: 60px;
  flex: 1.4;
`;

const JoinInfoBox = styled(Box)`
  margin-top: 30px;
  margin-bottom: 60px;
  z-index: 2;
  display: flex;
  flex-direction: column;
`;

const JoinFlexbox = styled.div`
  display: flex;
`;

const CouponInfo = styled.div`
  padding: 8px 8px;
  background: antiquewhite;
  border: 1px solid goldenrod;
  margin-bottom: 15px;
  display: flex;
`;

const JoinCenteredMaxWidth = styled(CenteredMaxWidth)`
  position: relative;
  top: -200px;
  max-width: 900px;

  @media (max-width: 600px) {
    top: 0;

    ${JoinFlexbox} {
    flex-direction: column;
    }
    ${JoinFormBox} {
      margin: 0;
      padding: 30px;
      flex: 1;
      order: 1;
    }
    ${JoinInfoBox} {
      margin: 0;
      order: 0;
      padding: 30px;
      box-shadow: none;
      border-bottom 1px solid #ccc;
    }
  }
`;
