import React from 'react';
import styled from 'styled-components/macro';

export const PublicFooter = () => {
  return (
    <div style={{ height: 100 }}>
      <FooterContainer>
        <div style={{ maxWidth: 1000, margin: 'auto', padding: 20, display: 'flex' }}>
          &copy; 2018-{new Date().getFullYear()} With the Band LLC
          <div style={{ flex: 1 }} />
          <div>
            <a href="/terms" target="_blank" rel="noopener noreferrer">
              Terms and Conditions
            </a>{' '}
            |{' '}
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>{' '}
            | <a href="mailto: support@withtheband.co">Support</a>
          </div>
        </div>
      </FooterContainer>
    </div>
  );
};

const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  color: ${({ theme }) => theme.tintColor};
  background: ${({ theme }) => theme.heroNavColor}CC;

  & a {
    color: black;
    text-decoration: underline;
    color: ${({ theme }) => theme.tintColor};
    &:visited {
      color: ${({ theme }) => theme.tintColor};
    }
    &:active {
      color: ${({ theme }) => theme.tintColor};
    }
  }
`;
