import React from 'react';
import _ from 'lodash';
import S3Upload from 'react-s3-uploader/s3upload';
import Cropper from 'react-easy-crop';

import './DropzoneInput.css';
import { Area } from 'react-easy-crop/types';
import { Dialog, Button } from '@blueprintjs/core';
import { CSSProperties } from 'styled-components';

const S3_BUCKET_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://withtheband-dev.herokuapp.com/api/s3-asset/'
    : 'http://localhost:8000/api/s3-asset/';

export interface ImageDropzoneInputProps {
  value: string | null;
  format: 'jpg' | 'png';
  onChange: (value: string | null) => void;

  inputEl?: React.Ref<HTMLInputElement>;
  desiredSize?: { width: number; height: number };
  instructions?: string;
  invalid?: boolean;
  style: CSSProperties;
  label?: string;
}

export interface ImageDropzoneInputState {
  progress: number;
  cancelCount: number;
  localFileURL: string | null;
  localFileRegion: Area | null;
  localImage: HTMLImageElement | null;
  crop: { x: number; y: number };
  zoom: number;
  aspect: number;
}

export function uploadImage(
  localImage: HTMLImageElement,
  localFileRegion: Area,
  desiredSize: { width: number; height: number } | undefined,
  format: 'jpg' | 'png',
  onUploadError: (err: Error) => void,
  onUploadFinished: (url: string) => void,
  onProgress: (progress: number) => void
) {
  // Crop the actual image
  const canvas = document.createElement('canvas');
  canvas.width = desiredSize ? desiredSize.width : localImage.width;
  canvas.height = desiredSize ? desiredSize.height : localImage.height;
  const ctx = canvas.getContext('2d');
  if (!ctx) return;

  ctx.drawImage(
    localImage,
    localFileRegion.x,
    localFileRegion.y,
    localFileRegion.width,
    localFileRegion.height,
    0,
    0,
    canvas.width,
    canvas.height
  );

  // Upload the image
  canvas.toBlob(
    blob => {
      if (!blob) return;
      (blob as any).name = `picture.${format}`;
      new S3Upload({
        files: [blob],
        contentDisposition: 'auto',
        uploadRequestHeaders: { 'x-amz-acl': 'public-read' },
        onError: onUploadError,
        onFinishS3Put: ({ fileKey }: { fileKey: string }) => {
          return onUploadFinished(`${S3_BUCKET_URL}${fileKey}`);
        },
        onProgress: onProgress,
        server: '/api',
        signingUrl: '/s3/sign',
        signingUrlQueryParams: { path: 'uploaded-image/' },
        s3Url: S3_BUCKET_URL,
      });
    },
    format === 'jpg' ? 'image/jpeg' : 'image/png',
    0.8
  );
}

export class ImageDropzoneInput extends React.Component<
  ImageDropzoneInputProps,
  ImageDropzoneInputState
> {
  inputId = _.uniqueId('image-input-');
  cropperRef = React.createRef<Cropper>();

  constructor(props: ImageDropzoneInputProps) {
    super(props);

    this.state = {
      progress: 0,
      cancelCount: 0,
      localFileURL: null,
      localFileRegion: null,
      localImage: null,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: this.props.desiredSize
        ? this.props.desiredSize.width / this.props.desiredSize.height
        : 1,
    };
  }

  onDragOver = (e: React.DragEvent<any>) => {
    e.preventDefault();
  };

  onDrop = (e: React.DragEvent<any>) => {
    e.preventDefault();
    this.onProcessFile(e.dataTransfer.files[0]);
  };

  onPickedFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      this.onProcessFile(e.target.files[0]);
    }
  };

  onProcessFile = (file: FileList[0]) => {
    if (!file) return;

    if (!['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)) {
      alert('Sorry, please select a PNG or JPG image.');
      return;
    }
    if (file.size > 1024 * 1024 * 12) {
      alert(
        'This image is more than 12MB. Editing and uploading may work, but your browser may refuse to load the image. If editing fails, consider choosing another image or shrinking this one and trying again.'
      );
    }

    // Open the image editor with this file!
    var fr = new FileReader();
    fr.onload = ({ target }) => {
      if (!target) return;
      this.props.onChange('');

      const img = new Image();
      img.onload = () => {
        this.setState(
          {
            localFileRegion: this.props.desiredSize
              ? null
              : { x: 0, y: 0, width: img.width, height: img.height },
            localFileURL: target.result as string,
            localImage: img,
          },
          () => {
            setTimeout(() => {
              this.cropperRef.current?.computeSizes();
            }, 200);
          }
        );
        if (!this.props.desiredSize) {
          this.onEditDone();
        }
      };
      img.src = target.result as string;
    };
    fr.readAsDataURL(file);
  };

  onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    this.setState({ localFileRegion: croppedAreaPixels });
  };

  onReset = () => {
    this.setState({
      progress: 0,
      localFileURL: null,
      localFileRegion: null,
      localImage: null,
      crop: { x: 0, y: 0 },
      zoom: 1,
      cancelCount: this.state.cancelCount + 1,
    });
  };

  onEditDone = () => {
    const { localFileRegion, localImage } = this.state;
    const { desiredSize, format } = this.props;

    if (
      this.getMaximumZoom() < 1 &&
      !window.confirm(
        'Are you sure you want to upload this image? Upscaling will occur when it is displayed on the device. You may want to choose a larger image.'
      )
    ) {
      return;
    }

    if (!localFileRegion || !localImage) return;

    this.setState({ progress: 5 });

    uploadImage(
      localImage,
      localFileRegion,
      desiredSize,
      format,
      this.onUploadError,
      this.onUploadFinished,
      (progress: number) => {
        this.setState({ progress: Math.max(1, progress) });
      }
    );
  };

  onUploadError = (err: Error) => {
    alert(
      `An error occurred while uploading the image: ${err.toString()}. You may want to disable ad-blockers and try again.`
    );
    this.onReset();
  };

  onUploadFinished = (url: string) => {
    this.onReset();
    this.props.onChange(url);
  };

  getMaximumZoom() {
    const { desiredSize } = this.props;
    const { localImage } = this.state;

    return localImage && desiredSize
      ? Math.min(localImage.width / desiredSize.width, localImage.height / desiredSize.height)
      : 1;
  }

  render() {
    const { desiredSize, invalid, label, inputEl, style } = this.props;
    const { progress, localFileURL, localFileRegion } = this.state;
    const maxZoom = this.getMaximumZoom();

    return (
      <div
        className={`ImageDropzoneInput ${invalid && 'is-invalid'}`}
        style={style}
        onDrop={this.onDrop}
        onDragOver={this.onDragOver}
      >
        <input
          id={this.inputId}
          ref={inputEl}
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          key={this.state.cancelCount}
          onChange={this.onPickedFile}
        />
        {this.props.value ? (
          <div className="DropzoneInput populated">
            <label htmlFor={this.inputId} style={{ width: 'auto' }}>
              <img
                src={this.props.value}
                alt="Upload Preview"
                style={{
                  width: '100%',
                  height: '100%',
                  maxHeight: style.height,
                  objectFit: 'contain',
                }}
              />
            </label>
          </div>
        ) : (
          <label
            className="DropzoneInput"
            style={{ flexDirection: 'column', height: '100%' }}
            htmlFor={this.inputId}
          >
            <div style={{ padding: '0 10px' }}>
              {this.props.instructions || `Drag and drop a photo`}
            </div>
            <div style={{ opacity: 0.5, fontSize: '0.9em' }}>
              {desiredSize && `(min ${desiredSize.width} x ${desiredSize.height}px)`}
            </div>
          </label>
        )}

        {localFileURL && desiredSize && (
          <Dialog isOpen={true} title="Edit Image">
            <div
              style={{ height: '500px', position: 'relative' }}
              onWheelCapture={e => {
                // There is a bug in Cropper which causes the image to "jump" too small /
                // move offcenter if you zoom out and the maxZoom is <1. We just prevent
                // the scroll events from reaching the component.
                if (maxZoom < 1) {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
            >
              <Cropper
                image={localFileURL}
                crop={this.state.crop}
                zoom={this.state.zoom}
                maxZoom={maxZoom}
                aspect={this.state.aspect}
                onCropChange={crop => this.setState({ crop })}
                onZoomChange={zoom => this.setState({ zoom })}
                onCropComplete={this.onCropComplete}
                ref={this.cropperRef}
              />
              {progress > 0 && (
                <div
                  style={{
                    zIndex: 100,
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: `rgba(255,255,255,0.7)`,
                    textAlign: 'center',
                    fontSize: 18,
                    fontWeight: 500,
                    paddingTop: 240,
                    position: 'absolute',
                  }}
                >
                  {`Uploading... ${progress}%`}
                </div>
              )}
            </div>
            {maxZoom < 1 && (
              <div style={{ color: 'darkred', padding: '17px 10px' }}>
                This image is too small. Upscaling will occur when it is displayed on the device.
              </div>
            )}
            <div style={{ display: 'flex', padding: '17px 10px 0 10px' }}>
              {maxZoom > 1 && (
                <input
                  type="range"
                  value={this.state.zoom}
                  step={0.05}
                  onChange={e => this.setState({ zoom: Number(e.target.value) })}
                  min={1}
                  max={maxZoom}
                />
              )}
              <div style={{ flex: 1 }} />
              <Button onClick={this.onReset}>Cancel</Button>
              <div style={{ width: 10 }} />
              <Button intent="primary" disabled={!localFileRegion} onClick={this.onEditDone}>
                Upload
              </Button>
            </div>
          </Dialog>
        )}
        {progress > 0 && <div className="progress" style={{ width: `${progress}%` }} />}
      </div>
    );
  }
}
