import React from 'react';
import { Button } from '../components/Button';
import { ErrorMessage } from '../components/Common';
import { makeRequest } from '../Resource';
import { Box } from './JoinPage';
import { JoinThankYou } from './JoinThankYou';

export const JoinFreeTierForm: React.FunctionComponent<{
  club: CoreAPI.Club;
  onJoined: () => void;
}> = ({ club, onJoined }) => {
  const [error, setError] = React.useState<string | undefined>();
  const [state, setState] = React.useState<'none' | 'loading' | 'thank-you'>('none');

  const handleFreeSubmit = React.useCallback(async () => {
    setState('loading');
    setTimeout(async () => {
      const resp = await makeRequest<{ error?: string }>(
        `/api/v1/clubs/${club.slug}/join-free-tier`,
        'POST',
        {}
      );
      if (resp.error) {
        setError(resp.error);
      } else {
        setState('thank-you');

        setTimeout(() => {
          onJoined();
        }, 2000);
      }
    }, 1000);
  }, [club, onJoined]);

  React.useEffect(() => {
    if (state === 'none') {
      handleFreeSubmit();
    }
  }, [handleFreeSubmit, state]);

  return (
    <>
      {state === 'thank-you' ? (
        <JoinThankYou />
      ) : state === 'loading' ? (
        <div style={{ textAlign: 'center', flex: 1, marginTop: 140 }}>
          <h2>Joining...</h2>
        </div>
      ) : error ? (
        <Box style={{ textAlign: 'center' }}>
          <h2>Sorry, an error occurred</h2>
          <ErrorMessage>{error}</ErrorMessage>
          <Button intent="primary" onClick={handleFreeSubmit} style={{ width: '100%' }}>
            Try Again
          </Button>
        </Box>
      ) : (
        <span />
      )}
    </>
  );
};
