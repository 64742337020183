import { Toaster } from '@blueprintjs/core';
import React from 'react';
import { Route, Switch, useHistory } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { AppModalsProvider } from './AppModals';
import { AdminRoot } from './pages-dashboard/AdminRoot';
import { PublicRoot } from './pages/PublicRoot';
import { ResourceOps, useResource } from './Resource';

const EMPTY_SESSION: CoreAPI.SessionResponse = {
  user: null,
  clubs: [],
};

export const SessionContext = React.createContext<
  [CoreAPI.SessionResponse, Pick<ResourceOps<CoreAPI.SessionResponse>, 'refresh'>]
>([EMPTY_SESSION, { refresh: async () => undefined }]);

export const ClubContext = React.createContext<CoreAPI.ClubWithMembership>(
  new Error() as any as CoreAPI.ClubWithMembership
);

export const AppToaster = Toaster.create();

export const App: React.FunctionComponent = () => {
  const [session, ops] = useResource<CoreAPI.SessionResponse>('/api/v1/session-optional');
  const history = useHistory();

  history.listen(location => {
    if ((window as any).gtag) {
      (window as any).gtag('config', 'G-CG5M6CFQ3M');
    } else {
      // may have been blocked by an ad-blocker, etc.
    }
  });

  if (!session) {
    return <span />;
  }

  return (
    <ThemeProvider theme={{ tintColor: '#5B62BE', bgColor: '#000000', heroNavColor: '#FFFFFF' }}>
      <SessionContext.Provider value={[session, ops]}>
        <AppModalsProvider />
        <Switch>
          <Route path="/admin" component={AdminRoot} />
          <Route component={PublicRoot} />
        </Switch>
      </SessionContext.Provider>
    </ThemeProvider>
  );
};
