import { Spinner } from '@blueprintjs/core';
import React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { SessionContext } from '../App';
import { Button } from '../components/Button';
import { CenteredContainer, CenteredMaxWidth } from '../components/Common';
import { CreateCrewDialog } from '../pages-dashboard/PageClubs';
import { makeRequest, useResource } from '../Resource';
import { PublicNav } from './PublicNav';

export const ExplorePage: React.FunctionComponent = () => {
  const [session] = React.useContext(SessionContext);
  const history = useHistory();
  const [explore] = useResource<CoreAPI.ExploreResponse>('api/v1/clubs/explore');
  const [creating, setCreating] = React.useState(false);

  if (!explore) {
    return <Spinner size={50} />;
  }

  const onCreate = async (payload: CoreAPI.CreateClubPayload) => {
    await makeRequest('/api/v1/clubs', 'POST', payload);
    'slug' in payload && history.push(`/${payload.slug}`);
  };

  return (
    <>
      <PublicNav />
      <CenteredContainer>
        <CenteredMaxWidth>
          {!!creating && (
            <CreateCrewDialog onCreate={onCreate} onClose={() => setCreating(false)} />
          )}
          {!!session.user && (
            <Button onClick={() => setCreating(true)} style={{ float: 'right', marginBottom: 30 }}>
              + Create Crew
            </Button>
          )}
          {explore.map(section => (
            <div key={section.title}>
              <div style={{ textAlign: 'center' }}>
                {section.items.map(item => (
                  <ClubCardLink to={`/${item.slug}`} key={item.slug}>
                    <div
                      className="chip"
                      style={{
                        top: 0,
                        left: 0,
                        borderTop: `22px solid ${item.config.tintColor}`,
                        borderLeft: `22px solid ${item.config.tintColor}`,
                      }}
                    />
                    <div
                      className="chip"
                      style={{
                        right: 0,
                        bottom: 0,
                        borderRight: `22px solid ${item.config.tintColor}`,
                        borderBottom: `22px solid ${item.config.tintColor}`,
                      }}
                    />
                    <img
                      alt="Hero"
                      src={item.config.heroImageURL}
                      style={{ maxWidth: '80vw', width: '100%' }}
                    />
                    <h2 style={{ color: item.config.tintColor, marginBottom: 0 }}>{item.name}</h2>
                  </ClubCardLink>
                ))}
              </div>
            </div>
          ))}
        </CenteredMaxWidth>
      </CenteredContainer>
    </>
  );
};

const ClubCardLink = styled(Link)`
  display: inline-block;
  padding: 15px;
  margin-bottom: 30px;
  background: white;
  position: relative;

  & .chip {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 45px;
    height: 45px;
    border: 22px solid transparent;
  }

  &:hover {
    filter: brightness(90%);
    text-decoration: none;
  }
`;
