import moment from 'moment-timezone';
import React from 'react';
import { BrandAlphaSteps } from '../../Colors';
import { NativeBridge } from '../../NativeBridge';
import { useResource } from '../../Resource';
import { Button } from '../Button';
import { FeedItemBox, FeedItemContainer } from '../Common';

export const MeetAndGreetBanners: React.FC<{ club: CoreAPI.Club }> = ({ club }) => {
  const [meets = []] = useResource<CoreAPI.MeetAndGreet[]>(
    `/api/v1/clubs/${club.slug}/meet-and-greets`
  );
  return (
    <>
      {meets.map(meet => (
        <MeetAndGreetBanner key={meet.id} postMeetMessage={club.config.postMeetCopy} meet={meet} />
      ))}
    </>
  );
};
export const MeetAndGreetBanner: React.FC<{ meet: CoreAPI.MeetAndGreet; postMeetMessage: string }> =
  ({ meet, postMeetMessage }) => {
    const state = meet.zoomstate && !('error' in meet.zoomstate) ? meet.zoomstate : null;
    const url = state?.join_url || '';
    const date = moment(meet.startDate);
    const likelyOver = (state && state.participants_count > 1) || (date.isBefore(moment()) && !url);

    return (
      <FeedItemContainer>
        <FeedItemBox
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            border: `2px solid ${BrandAlphaSteps[14]}`,
            boxShadow: `0 0 40px ${BrandAlphaSteps[6]}`,
            padding: 10,
          }}
        >
          <div>
            <h4 style={{ margin: 0, marginBottom: 10 }}>
              {likelyOver ? 'Thank You' : "It's almost time!"}
            </h4>
            {likelyOver
              ? postMeetMessage
              : "Your meet and greet is scheduled and starting soon! We'll send you a reminder to join 10 minutes early and make sure you're ready."}
            <div style={{ marginTop: 20 }}>
              <strong>{date.format('LLLL')}</strong>
            </div>
            {url ? (
              <div style={{ textDecoration: 'none', marginTop: 20 }}>
                <Button
                  intent="primary"
                  style={{ textDecoration: 'none' }}
                  onClick={e => {
                    if (NativeBridge.isAvailable()) {
                      NativeBridge.joinZoomCall(url);
                      e.preventDefault();
                    } else {
                      window.open(url, '_blank');
                    }
                  }}
                >
                  Join Now
                </Button>
              </div>
            ) : (
              <span />
            )}
          </div>
          <img
            src={require('../../images/vip.png').default}
            style={{ flexShrink: 0, padding: 20, width: 120 }}
            alt="VIP icon"
          />
        </FeedItemBox>
      </FeedItemContainer>
    );
  };
