import qs from 'query-string';
import React from 'react';
import { RouteChildrenProps } from 'react-router';
import { Button } from '../components/Button';
import { Form } from '../components/Common';
import { makeRequest } from '../Resource';
import { AuthPageContainer } from './AuthPageContainer';

export const ForgotCompletePage: React.FunctionComponent<RouteChildrenProps<{}>> = ({
  location,
}) => {
  const email = qs.parse(location.search)['email'] as string;
  const key = qs.parse(location.search)['key'] as string;
  const [password, setPassword] = React.useState('');
  const [passwordConfirm, setPasswordConfirm] = React.useState('');
  const [result, setResult] = React.useState('');

  const onSubmit = async (e: React.FormEvent<any>) => {
    e.preventDefault();

    if (!passwordConfirm || !password || password !== passwordConfirm) {
      setResult('Please fill both password fields and make sure that they match.');
      return;
    }
    const resp = await makeRequest<{ error?: string; success: boolean }>(
      `/api/v1/forgot-complete`,
      'POST',
      {
        email,
        key,
        password,
        passwordConfirm,
      }
    );
    if (resp.error) {
      setResult(resp.error);
    } else {
      setResult('All set! Return to the sign in page to log in to your account.');
    }
  };

  return (
    <AuthPageContainer location={location}>
      <Form onSubmit={onSubmit}>
        <h1 style={{ textAlign: 'center' }}>Choose a Password</h1>

        {result ? <div className="alert alert-danger">{result}</div> : undefined}

        <div className="group" style={{ marginTop: 8, marginBottom: 8 }}>
          <label className="form-block">
            <div>Email</div>
            <input
              disabled
              defaultValue={email}
              className="field"
              type="text"
              name="username"
              autoCapitalize="off"
              autoComplete="email"
              placeholder="hello-world@domain.com"
            />
          </label>
          <label className="form-block">
            <div>Password</div>
            <input
              value={password}
              onChange={e => setPassword(e.target.value)}
              className="field"
              type="password"
              name="password"
              id="password"
            />
          </label>
          <label className="form-block">
            <div>Password (Confirm)</div>
            <input
              value={passwordConfirm}
              onChange={e => setPasswordConfirm(e.target.value)}
              className="field"
              type="password"
              name="password"
              id="password"
            />
          </label>
        </div>
        <div style={{ clear: 'both', height: 30 }} />
        <input type="submit" style={{ display: 'none' }} />
        <Button intent="primary" style={{ width: '100%' }} onClick={onSubmit}>
          <div className="loader-container"></div>
          Update Password
        </Button>
      </Form>
    </AuthPageContainer>
  );
};
