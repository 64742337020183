import { Checkbox } from '@blueprintjs/core';
import moment from 'moment';
import React, { useContext } from 'react';
import { RouteChildrenProps } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { SessionContext } from '../App';
import { closeModal, showModal } from '../AppModals';
import { Button } from '../components/Button';
import {
  CenteredContainer,
  CenteredMaxWidth,
  InfoRow,
  WhiteShadowedBox,
} from '../components/Common';
import { JoinModal } from '../components/JoinModal';
import { ProfileDetails } from '../components/ProfileDetails';
import { makeRequest, useResource } from '../Resource';
import { formatPrice } from '../Utils';
import { ManageModalCancelMembership } from './ManageModalCancelMembership';
import { ManageModalEditCard } from './ManageModalEditCard';
import { ManageModalEditProfile } from './ManageModalEditProfile';
import { PublicNav } from './PublicNav';

export const ManagePage: React.FunctionComponent<RouteChildrenProps<{}>> = ({ match, history }) => {
  const [session, { refresh: refreshSession }] = useContext(SessionContext);
  const [billingDetails, { refresh: refreshBilling }] =
    useResource<CoreAPI.SessionBillingDetailsResponse>('/api/v1/session/billing-details');

  if (!session.user) {
    return <span />;
  }

  return (
    <>
      <PublicNav />
      <CenteredContainer>
        <CenteredMaxWidth style={{ width: 700, maxWidth: '100vw' }}>
          <WhiteShadowedBox>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <h3 style={{ flex: 1, margin: 0 }}>Profile</h3>
              <Button
                size="small"
                onClick={() =>
                  showModal(
                    <ManageModalEditProfile
                      initial={session.user!}
                      onDone={() => {
                        closeModal();
                        refreshSession();
                      }}
                    />
                  )
                }
              >
                Edit
              </Button>
            </div>
            <ProfileDetails user={session.user} />
            <div style={{ height: 30 }} />
            <h3 style={{ marginBottom: 0 }}>Memberships</h3>
            {session.clubs.length === 0 && (
              <div style={{ color: 'gray' }}>
                You have not joined any crews. Find a crew to join <Link to={`/explore`}>here</Link>
                .
              </div>
            )}
            {session.clubs.map(club => (
              <MembershipRow
                key={club.slug}
                club={club}
                notice={billingDetails?.membershipNotices[club.id]}
                onCancel={async () => {
                  showModal(
                    <ManageModalCancelMembership
                      club={club}
                      onDone={() => {
                        closeModal();
                        void refreshSession();
                        void refreshBilling();
                      }}
                    />
                  );
                }}
                onSaveSettings={async settings => {
                  await makeRequest<{ error?: string }>(
                    `/api/v1/clubs/${club.id}/settings`,
                    'PUT',
                    settings
                  );
                  void refreshSession();
                }}
                onSwitchTiers={async tierKey => {
                  const resp = await makeRequest<{ error?: string }>(
                    `/api/v1/clubs/${club.id}/switch-tiers`,
                    'POST',
                    {
                      tier: tierKey,
                    } as CoreAPI.SwitchClubTiersPayload
                  );
                  if ('error' in resp) {
                    alert(resp.error);
                  } else {
                    void refreshSession();
                    void refreshBilling();
                  }
                }}
              />
            ))}
            <span style={{ color: 'gray' }}>
              Need help with a membership? Visit our{' '}
              <a href="https://www.withtheband.co/faqs" target="_blank" rel="noopener noreferrer">
                knowledge base
              </a>{' '}
              for support and answers to popular questions.
            </span>
            <div style={{ height: 30 }} />
            <h3 style={{ marginBottom: 0 }}>Billing</h3>
            <div
              style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}
            >
              <InfoRow>
                <label>Default Payment Method:</label>
                {billingDetails?.method ? (
                  <PaymentMethod method={billingDetails.method} />
                ) : (
                  <Placeholder>None</Placeholder>
                )}
              </InfoRow>
              <Button
                size="small"
                onClick={() =>
                  showModal(
                    <ManageModalEditCard
                      onDone={() => {
                        closeModal();
                        refreshBilling();
                      }}
                    />
                  )
                }
              >
                Add / Update Card
              </Button>
            </div>
            <div style={{ height: 30 }} />
            <table style={{ width: '100%', border: '1px solid #ccc' }}>
              <thead style={{ background: '#f0f5fd' }}>
                <tr>
                  <td>Date</td>
                  <td>Amount</td>
                  <td>Description</td>
                </tr>
              </thead>
              <tbody>
                {!billingDetails && (
                  <tr>
                    <td colSpan={3}>Loading...</td>
                  </tr>
                )}
                {billingDetails?.charges.map(c => (
                  <tr key={`charge-${c.created}`}>
                    <td>{moment.unix(c.created).format('MM/DD/YYYY')}</td>
                    <td>{formatPrice(c.amount)}</td>
                    <td>{c.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </WhiteShadowedBox>
        </CenteredMaxWidth>
      </CenteredContainer>
    </>
  );
};

const MembershipRow: React.FunctionComponent<{
  club: CoreAPI.ClubWithMembership;
  notice?: string;
  onSwitchTiers: (tierKey: string) => void;
  onSaveSettings: (settings: CoreAPI.ClubMembership['settings']) => void;
  onCancel: () => void;
}> = ({ club, onSwitchTiers, onSaveSettings, onCancel, notice }) => {
  const tierKey = club.membership!.tier;
  const tier = club.tiers.find(t => tierKey === t.key)!;
  const membership = club.membership!;

  const switchTier = () => {
    showModal(
      <JoinModal
        club={club}
        visible={true}
        currentTierKey={tierKey}
        currentTierLocked={membership.billing?.type !== 'stripe'}
        onSwitchTiers={async tierKey => {
          await onSwitchTiers(tierKey);
          closeModal();
        }}
      />
    );
  };

  const isCanceled =
    (membership.billing && moment(membership.billingPeriodEnd).isBefore(moment())) ||
    notice?.includes('will be cancelled');

  return (
    <MembershipRowContainer>
      <div style={{ display: 'flex', padding: 10 }}>
        <img
          alt="artist hero"
          src={club.config.heroImageURL}
          style={{ width: 200, height: 100, objectFit: 'cover' }}
        />
        <div style={{ flex: 1, paddingLeft: 15 }}>
          <strong>
            {club.name} - {tier ? tier.name : 'Unknown Tier'}
          </strong>
          <div>
            <div>Member since {moment(membership.createdAt).format('MMMM YYYY')}</div>
            {membership.billing && (
              <div>
                {membership.billing?.type === 'apple'
                  ? 'Managed by Apple'
                  : !notice
                  ? `Renews ${moment(membership.billingPeriodEnd!).format('MMMM DD YYYY')}`
                  : ''}
              </div>
            )}
            {notice ? (
              <div style={{ color: '#ce6837' }}>{notice}</div>
            ) : (
              <a href="#/" onClick={switchTier}>
                View Tier Details
              </a>
            )}
          </div>
          <div style={{ paddingTop: 15 }}>
            <Checkbox
              label="Notify me about artist posts"
              checked={!membership.settings.noPostNotifications}
              onChange={e =>
                onSaveSettings({
                  ...membership.settings,
                  noPostNotifications: !membership.settings.noPostNotifications,
                })
              }
            />
            <Checkbox
              label="Notify me about replies and @mentions"
              checked={!membership.settings.noMentionNotifications}
              onChange={e =>
                onSaveSettings({
                  ...membership.settings,
                  noMentionNotifications: !membership.settings.noMentionNotifications,
                })
              }
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          padding: 10,
          borderTop: '1px solid #ccc',
          background: '#f0f5fd',
        }}
      >
        {membership.billing?.type === 'stripe' && !isCanceled ? (
          <>
            <Button size="small" onClick={switchTier}>
              Switch Tiers
            </Button>
            <div style={{ width: 10 }} />
            <Button size="small" onClick={onCancel}>
              Cancel Subscription
            </Button>
          </>
        ) : membership.billing?.type === 'stripe' && isCanceled ? (
          <Link to={`/${club.slug}/join`}>
            <Button size="small">Re-join Crew</Button>
          </Link>
        ) : membership.billing?.type === 'apple' ? (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://support.apple.com/en-us/HT204939"
          >
            <Button size="small">Manage with Apple</Button>
          </a>
        ) : (
          <span></span>
        )}
        <div style={{ flex: 1 }} />
        <Link to={`/${club.slug}`}>
          <Button size="small">View</Button>
        </Link>
      </div>
    </MembershipRowContainer>
  );
};

const PaymentMethod: React.FunctionComponent<{ method: CoreAPI.SessionBillingMethod }> = ({
  method,
}) => (
  <div style={{ whiteSpace: 'pre-wrap' }}>
    <span style={{ textTransform: 'capitalize' }}>{method.type}</span>
    {method.card
      ? ` - ${method.card.brand.toUpperCase()} - ${method.card.exp_month}/${method.card.exp_year} ${
          method.card.last4
        }`
      : ''}
  </div>
);

const Placeholder = styled.div`
  opacity: 0.6;
`;

const MembershipRowContainer = styled.div`
  border: 1px solid #ccc;
  margin-bottom: 10px;
`;
