import React from 'react';

export const TikTok: React.FunctionComponent<{ url: string }> = ({ url }) => {
  const tikTok = React.createRef<HTMLDivElement>();
  React.useEffect(() => {
    if (!tikTok.current) return;

    const id = url.split('/video/').pop()?.split('?')[0];

    console.log(id);
    tikTok.current.innerHTML = `
    <blockquote class="tiktok-embed" cite="${url}" data-video-id="${id}" style="max-width: 605px; min-width: 325px;">
    <section> </section> </blockquote>`;

    const script = document.createElement('script');
    script.src = 'https://www.tiktok.com/embed.js';
    document.body.appendChild(script);
  }, [url]);

  return <div ref={tikTok} />;
};
