import React from 'react';
import { RouteChildrenProps } from 'react-router';
import { CenteredContainer, CenteredMaxWidth, WhiteShadowedBox } from '../components/Common';

const TERMS = `
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;
	mso-font-charset:0;
	mso-generic-font-family:roman;
	mso-font-pitch:variable;
	mso-font-signature:3 0 0 0 1 0;}
@font-face
	{font-family:Cambria;
	panose-1:2 4 5 3 5 4 6 3 2 4;
	mso-font-charset:0;
	mso-generic-font-family:roman;
	mso-font-pitch:variable;
	mso-font-signature:-536870145 1073743103 0 0 415 0;}
@font-face
	{font-family:"Segoe UI";
	panose-1:2 11 6 4 2 2 2 2 2 4;
	mso-font-alt:Cambria;
	mso-font-charset:0;
	mso-generic-font-family:swiss;
	mso-font-pitch:variable;
	mso-font-signature:-469750017 -1073683329 9 0 511 0;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:"";
	margin:0in;
	mso-pagination:widow-orphan;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	mso-fareast-font-family:Cambria;
	mso-fareast-theme-font:minor-latin;}
a:link, span.MsoHyperlink
	{mso-style-priority:99;
	color:blue;
	mso-themecolor:hyperlink;
	text-decoration:underline;
	text-underline:single;}
a:visited, span.MsoHyperlinkFollowed
	{mso-style-noshow:yes;
	mso-style-priority:99;
	color:purple;
	mso-themecolor:followedhyperlink;
	text-decoration:underline;
	text-underline:single;}
p.MsoAcetate, li.MsoAcetate, div.MsoAcetate
	{mso-style-noshow:yes;
	mso-style-priority:99;
	mso-style-link:"Balloon Text Char";
	margin:0in;
	mso-pagination:widow-orphan;
	font-size:9.0pt;
	font-family:"Segoe UI",sans-serif;
	mso-fareast-font-family:Cambria;
	mso-fareast-theme-font:minor-latin;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	mso-add-space:auto;
	mso-pagination:widow-orphan;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	mso-fareast-font-family:Cambria;
	mso-fareast-theme-font:minor-latin;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-type:export-only;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	mso-add-space:auto;
	mso-pagination:widow-orphan;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	mso-fareast-font-family:Cambria;
	mso-fareast-theme-font:minor-latin;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-type:export-only;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	mso-add-space:auto;
	mso-pagination:widow-orphan;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	mso-fareast-font-family:Cambria;
	mso-fareast-theme-font:minor-latin;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-type:export-only;
	margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	mso-add-space:auto;
	mso-pagination:widow-orphan;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	mso-fareast-font-family:Cambria;
	mso-fareast-theme-font:minor-latin;}
span.BalloonTextChar
	{mso-style-name:"Balloon Text Char";
	mso-style-noshow:yes;
	mso-style-priority:99;
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"Balloon Text";
	mso-ansi-font-size:9.0pt;
	mso-bidi-font-size:9.0pt;
	font-family:"Segoe UI",sans-serif;
	mso-ascii-font-family:"Segoe UI";
	mso-hansi-font-family:"Segoe UI";
	mso-bidi-font-family:"Segoe UI";}
span.SpellE
	{mso-style-name:"";
	mso-spl-e:yes;}
.MsoChpDefault
	{mso-style-type:export-only;
	mso-default-props:yes;
	font-family:"Cambria",serif;
	mso-ascii-font-family:Cambria;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:Cambria;
	mso-fareast-theme-font:minor-latin;
	mso-hansi-font-family:Cambria;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:minor-bidi;}
@page WordSection1
	{size:8.5in 11.0in;
	margin:1.0in 1.0in 1.0in 1.0in;
	mso-header-margin:.5in;
	mso-footer-margin:.5in;
	mso-paper-source:0;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 @list l0
	{mso-list-id:1;
	mso-list-type:hybrid;
	mso-list-template-ids:1 1 -1 -1 -1 -1 -1 -1 -1 -1;}
@list l0:level1
	{mso-level-number-format:bullet;
	mso-level-text:•;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-.25in;}
@list l0:level2
	{mso-level-start-at:0;
	mso-level-text:"";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:0in;
	text-indent:0in;}
@list l0:level3
	{mso-level-start-at:0;
	mso-level-text:"";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:0in;
	text-indent:0in;}
@list l0:level4
	{mso-level-start-at:0;
	mso-level-text:"";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:0in;
	text-indent:0in;}
@list l0:level5
	{mso-level-start-at:0;
	mso-level-text:"";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:0in;
	text-indent:0in;}
@list l0:level6
	{mso-level-start-at:0;
	mso-level-text:"";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:0in;
	text-indent:0in;}
@list l0:level7
	{mso-level-start-at:0;
	mso-level-text:"";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:0in;
	text-indent:0in;}
@list l0:level8
	{mso-level-start-at:0;
	mso-level-text:"";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:0in;
	text-indent:0in;}
@list l0:level9
	{mso-level-start-at:0;
	mso-level-text:"";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:0in;
	text-indent:0in;}
@list l1
	{mso-list-id:668942561;
	mso-list-type:hybrid;
	mso-list-template-ids:-1068715876 67698703 67698713 67698715 67698703 67698713 67698715 67698703 67698713 67698715;}
@list l1:level1
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-.25in;}
@list l1:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-.25in;}
@list l1:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l1:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-.25in;}
@list l1:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-.25in;}
@list l1:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l1:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-.25in;}
@list l1:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-.25in;}
@list l1:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l2
	{mso-list-id:2141267806;
	mso-list-type:hybrid;
	mso-list-template-ids:-1743467928 67698713 67698689 67698715 67698703 67698713 67698715 67698703 67698713 67698715;}
@list l2:level1
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-.25in;}
@list l2:level2
	{mso-level-number-format:bullet;
	mso-level-text:;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-.25in;
	font-family:Symbol;
	mso-ascii-font-family:Symbol;
	mso-bidi-font-family:"Times New Roman";}
@list l2:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l2:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-.25in;}
@list l2:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-.25in;}
@list l2:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
@list l2:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-.25in;}
@list l2:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	text-indent:-.25in;}
@list l2:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	text-indent:-9.0pt;}
ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>
<!--[if gte mso 10]>
<style>
 /* Style Definitions */
 table.MsoNormalTable
	{mso-style-name:"Table Normal";
	mso-tstyle-rowband-size:0;
	mso-tstyle-colband-size:0;
	mso-style-noshow:yes;
	mso-style-priority:99;
	mso-style-parent:"";
	mso-padding-alt:0in 5.4pt 0in 5.4pt;
	mso-para-margin:0in;
	mso-pagination:widow-orphan;
	font-size:12.0pt;
	font-family:"Cambria",serif;
	mso-ascii-font-family:Cambria;
	mso-ascii-theme-font:minor-latin;
	mso-hansi-font-family:Cambria;
	mso-hansi-theme-font:minor-latin;}
</style>
<![endif]-->
</head>

<body lang=EN-US link=blue vlink=purple style='tab-interval:.5in;word-wrap:
break-word'>

<div class=WordSection1>

<p class=MsoNormal align=center style='text-align:center'><b style='mso-bidi-font-weight:
normal'><span style='font-size:14.0pt'>With the Band <o:p></o:p></span></b></p>

<p class=MsoNormal align=center style='text-align:center'><b style='mso-bidi-font-weight:
normal'><span style='font-size:14.0pt'>Conditions and Terms of Use<o:p></o:p></span></b></p>

<p class=MsoNormal><o:p>&nbsp;</o:p></p>

<p class=MsoNormal><o:p>&nbsp;</o:p></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>This Agreement is placed in
effect as of December&nbsp;1, 2020 (the “Effective Date”). <o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal align=center style='text-align:center;mso-pagination:none;
mso-layout-grid-align:none;text-autospace:none'><b style='mso-bidi-font-weight:
normal'><span style='color:#353535'>1. Introduction<o:p></o:p></span></b></p>

<p class=MsoNormal align=center style='text-align:center;mso-pagination:none;
mso-layout-grid-align:none;text-autospace:none'><b style='mso-bidi-font-weight:
normal'><span style='color:#353535'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>1.1 <u>This Agreement<o:p></o:p></u></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>Thank you for using With the
Band, an electronic platform designed, among other things, to bring dedicated
music fans together with their favorite artists as well as other businesses and
business models we may enter into from time to time. The terms “With the
Band,”, “we,” “us,” and “our,” include With the Band, LLC and our affiliates.
Our “Platform” includes any website, application, or service we offer,
including content we offer and electronic communications we send. We use the
terms “you” and “your” to mean any person using our Platform. We provide our
Platform to you subject to these Terms and Conditions. This agreement includes
our Terms and Conditions of Use, our Privacy Policy, and agreements pertaining
to payment amounts or other services (all the foregoing collectively the
“Agreement”). <span style='mso-spacerun:yes'> </span>We will maintain an
up-to-date version of them on our website and on any mobile versions. <o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>By creating a user account on
our Platform, or otherwise making use of any service we provide online or
through our mobile application(s), you agree that you have read and understand
the Terms and Conditions of the Agreement, and agree to enter into and be bound
by the <span class=SpellE>Agreementand</span> If you do not or are unable to
agree to the Agreement, you are neither granted permission nor authorized to
use our Platform.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>1.2 <u>Revisions to This
Agreement<o:p></o:p></u></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>We may modify the Agreement
from time to time at our sole discretion with or without notice. When we do, we
will provide notice to you by publishing the most current version and revising
the date at the top of this page. If we make any material change to this
Agreement, we may provide additional notice to you<span
style='mso-spacerun:yes'>  </span>such as sending to you an email or displaying
a prominent notice on our Platform. By continuing to use the Platform after any
changes come into effect, you agree to and are bound by the revised Agreement.
Thus, please make sure you read any notice of change carefully to fully
understand the changes to the Agreement. <o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal align=center style='text-align:center;mso-pagination:none;
mso-layout-grid-align:none;text-autospace:none'><b style='mso-bidi-font-weight:
normal'><span style='color:#353535'>2. Your Account and Membership<o:p></o:p></span></b></p>

<p class=MsoNormal align=center style='text-align:center;mso-pagination:none;
mso-layout-grid-align:none;text-autospace:none'><b style='mso-bidi-font-weight:
normal'><span style='color:#353535'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>2.1 <u>Eligibility</u> <o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>In order to use With the Band
or any and all of its affiliated services, you must be: (A) eighteen (18) years
of age or older or be thirteen (13) years of age or older and have the written
consent of at least one parent or legal guardian; (B) have the power and
ability to enter into a binding contract with us and are not prohibited from
doing so under any and all applicable laws; and (C) are a resident of a country
where With the Band and its collective services are available. <o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>2.2 <u>Account Information and
Security</u><o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>When you register, you provide
us with basic information, including an email address and a password. Keep your
email address, password, other account information current,<span
style='mso-spacerun:yes'>  </span>accurate, and private. You alone are
responsible for anything that happens as a result of your failure to maintain
the security and confidentiality of your login credentials. If an unauthorized
party is using your login credentials, notify us immediately.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>2.3 <u>Suspension of Your
Account</u><o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>We may modify, suspend, or
terminate your account or access to the Platform if, in our sole discretion
with or without prior notice, we determine that you have violated the
Agreement, including any of the policies or guidelines that are a part of this
Agreement or otherwise may be enacted by us, that it is in the best interest of
the With the Band community, or to protect our brand, intellectual property, or
Platform. We also may remove accounts of members who are inactive for an
extended period of time without prior notice. <o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><b style='mso-bidi-font-weight:normal'><span
style='color:#353535'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal align=center style='text-align:center;mso-pagination:none;
mso-layout-grid-align:none;text-autospace:none'><b style='mso-bidi-font-weight:
normal'><span style='color:#353535'>3. Costs for Our Services<o:p></o:p></span></b></p>

<p class=MsoNormal align=center style='text-align:center;mso-pagination:none;
mso-layout-grid-align:none;text-autospace:none'><b style='mso-bidi-font-weight:
normal'><span style='color:#353535'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>3.1 <u>Annual Membership Fee</u><o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>With the Band charges a fee
for its services, which is collected from you on an annual basis. Unless you
opt out, this annual fee with be charged automatically from whichever method of
payment is stored on our Platform.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>3.2 <u>Other Fees and Associated
Costs</u><o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>We may, in the future,
implement a new fee, or modify an existing fee, for certain current or future
features of our Platform. If we do implement a new or modified fee, we will
give you advanced notice by posting changes on our Planform or sending you an
email. You agree to pay those fees and any associated taxes for your continued
use of our Platform. Unless otherwise stated, all fees and all transactions are
in U.S. dollars. All fees are exclusive of applicable federal, state, local, or
other taxes.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal align=center style='text-align:center;mso-pagination:none;
mso-layout-grid-align:none;text-autospace:none'><b style='mso-bidi-font-weight:
normal'><span style='color:#353535'>4. User Content and Guidelines<o:p></o:p></span></b></p>

<p class=MsoNormal align=center style='text-align:center;mso-pagination:none;
mso-layout-grid-align:none;text-autospace:none'><b style='mso-bidi-font-weight:
normal'><span style='color:#353535'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>4.1 <u>User Content</u><o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>You are solely responsible for
your Content. For purpose of the <span class=SpellE>Agrement</span>, the word
“Content”<span style='mso-spacerun:yes'>  </span>means any information,
material, photographs, images, or other content posted to our Platform or
otherwise provided to us. This Content includes, but is not limited to information
posted on our Platform relating to fan meet ups with artists and fan projects. You
agree that you and your Content shall not violate the rights of any third-party
(such as copyrights, trademarks, contract rights, privacy rights, or publicity
rights), or this Agreement. <o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>We do not claim ownership of
your Content. However, to enable us to operate, improve, promote, and protect
With the Band and our Platform, and to ensure we do not violate any rights you
may have in your Content, you hereby grant With the Band a non-exclusive,
worldwide, perpetual, irrevocable, royalty-free, sub-licensable, transferable
right and license (including a waiver of any moral rights) to use, host, store,
reproduce, modify, publish, publicly display, publicly perform, distribute, and
create derivative works of, your Content and to commercialize and exploit the
copyright, trademark, publicity, and database rights you have in your Content.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>4.2 <u>User Guidelines<o:p></o:p></u></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>We at With the Band have set
some standards and rules for you to follow whenever you use our Platform to
ensure that our Platform remains enjoyable for all. When using our Platform,
follow these rules and when possible, encourage others to do the same.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>The following acts are not
permissible for any reason at any time:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;mso-pagination:
none;mso-list:l0 level1 lfo2;mso-layout-grid-align:none;text-autospace:none'><![if !supportLists]><span
style='mso-fareast-font-family:"Times New Roman";color:#353535'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='color:#353535'>Giving your password
to another With the Band user or non-With the Band user, or using any other
user’s username (or Login ID) and password;<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;mso-pagination:
none;mso-list:l0 level1 lfo2;mso-layout-grid-align:none;text-autospace:none'><![if !supportLists]><span
style='mso-fareast-font-family:"Times New Roman";color:#353535'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='color:#353535'>Imitating, acting,
or pretending to be a different person than who you really are on our Platform,
which includes but is not limited to age, gender (the gender you currently
identify with), or other identifying physical features;<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;mso-pagination:
none;mso-list:l0 level1 lfo2;mso-layout-grid-align:none;text-autospace:none'><![if !supportLists]><span
style='mso-fareast-font-family:"Times New Roman";color:#353535'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='color:#353535'>Using any and all
automated means to reap information from With the Band (including but not
limited to bots, scrapers, and spiders); <o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;mso-pagination:
none;mso-list:l0 level1 lfo2;mso-layout-grid-align:none;text-autospace:none'><![if !supportLists]><span
style='mso-fareast-font-family:"Times New Roman";color:#353535'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='color:#353535'>Selling any With the
Band account to a third-party, or otherwise receiving compensation, financial
or otherwise, with the purpose of influencing the name of a With the Band
account or the content included on such account, unless otherwise permitted
under this Agreement; <o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;mso-pagination:
none;mso-list:l0 level1 lfo2;mso-layout-grid-align:none;text-autospace:none'><![if !supportLists]><span
style='mso-fareast-font-family:"Times New Roman";color:#353535'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='color:#353535'>Depicting,
glamourizing, depicting, or encouraging illegal drugs, drug use, and/or
paraphernalia;<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;mso-pagination:
none;mso-list:l0 level1 lfo2;mso-layout-grid-align:none;text-autospace:none'><![if !supportLists]><span
style='mso-fareast-font-family:"Times New Roman";color:#353535'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='color:#353535'>Sexualized communications
with between users under the age of eighteen (18) years old; or<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;mso-pagination:
none;mso-list:l0 level1 lfo2;mso-layout-grid-align:none;text-autospace:none'><![if !supportLists]><span
style='mso-fareast-font-family:"Times New Roman";color:#353535'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='color:#353535'>Instigating,
enflaming, glamorizing, encouraging, and/or planning any act of violence,
insurrection, and/or domestic or foreign terrorism. <o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>We ask that you respect With
the Band and all other persons who use our Platform. Do not partake in any
activity, post any contact information, or apply for and/or use a username,
which itself is or includes material that:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;mso-pagination:
none;mso-list:l0 level1 lfo2;mso-layout-grid-align:none;text-autospace:none'><![if !supportLists]><span
style='mso-fareast-font-family:"Times New Roman";color:#353535'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='color:#353535'>Is offensive,
abusive, defamatory, threatening, pornographic, or obscene;<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;mso-pagination:
none;mso-list:l0 level1 lfo2;mso-layout-grid-align:none;text-autospace:none'><![if !supportLists]><span
style='mso-fareast-font-family:"Times New Roman";color:#353535'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='color:#353535'>Is illegal, or is
intended to commit or promote the doing of an illegal act of any kind,
including though not limited to violations of intellectual property rights,
privacy rights, publicity rights, or proprietary rights of With the Band or any
third-party;<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;mso-pagination:
none;mso-list:l0 level1 lfo2;mso-layout-grid-align:none;text-autospace:none'><![if !supportLists]><span
style='mso-fareast-font-family:"Times New Roman";color:#353535'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='color:#353535'>Includes either your
or another person’s user password intentionally or intentionally includes
personal data of third-parties or is done to gain knowledge of such personal
data;<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;mso-pagination:
none;mso-list:l0 level1 lfo2;mso-layout-grid-align:none;text-autospace:none'><![if !supportLists]><span
style='mso-fareast-font-family:"Times New Roman";color:#353535'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='color:#353535'>Includes malicious
technological content like malware, viruses, Trojan horses, or otherwise
interferes with or infects any other person’s technological devices;<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;mso-pagination:
none;mso-list:l0 level1 lfo2;mso-layout-grid-align:none;text-autospace:none'><![if !supportLists]><span
style='mso-fareast-font-family:"Times New Roman";color:#353535'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='color:#353535'>Is done with the
purpose of harassing or bullying any other person, or does in fact bully
another person;<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;mso-pagination:
none;mso-list:l0 level1 lfo2;mso-layout-grid-align:none;text-autospace:none'><![if !supportLists]><span
style='mso-fareast-font-family:"Times New Roman";color:#353535'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='color:#353535'>Misrepresents your
relationship or affiliation with another person, impersonates another person or
group/entity, or is in any way fraudulent, deceitful, false, or misleading,
whether for commercial or noncommercial purposes;<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;mso-pagination:
none;mso-list:l0 level1 lfo2;mso-layout-grid-align:none;text-autospace:none'><![if !supportLists]><span
style='mso-fareast-font-family:"Times New Roman";color:#353535'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='color:#353535'>Involves commercial
activity such as advertisements, promotions, contests, sweepstakes, or other schemes
(whether legal or illegal under applicable law) that are not expressly
consented to by With the Band; or<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:.5in;text-indent:-.25in;mso-pagination:
none;mso-list:l0 level1 lfo2;mso-layout-grid-align:none;text-autospace:none'><![if !supportLists]><span
style='mso-fareast-font-family:"Times New Roman";color:#353535'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='color:#353535'>Conflicts with the
Agreement in any way, as viewed under the sole discretion of With the Band. <o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>You have been made aware of
the fact that, and acknowledge and agree that posting Content which meets any
of the above criteria can likely result in the immediate termination or
suspension of your account on With the Band without prior notice. You also
acknowledge and agree that With the Band maintains the right to reclaim your
username at any time and for any reason, as judged by the sole discretion of
With the Band.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>4.3 <u>Users Interactions with
Others</u><o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>With the Band does not control
the Content of other members. When we become aware of impermissible and/or
inappropriate Content on our Platform, we reserve the right to investigate and
take appropriate action to be determined at our sole discretion, but we do not
have any obligation to monitor, nor do we take responsibility for the Content
of any members.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>With the Band is not a party
to any offline arrangements made through our Platform. With the Band does not
attempt to verify the truth or accuracy of statements made by users and makes
no representations or warranties concerning the conduct or Content of any users
or their interactions with you. Any Content on the Platform should not be
considered a news source and should not be relied upon in making any decisions
or developing any opinions on any scientific, political, or other issue.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal align=center style='text-align:center;mso-pagination:none;
mso-layout-grid-align:none;text-autospace:none'><b style='mso-bidi-font-weight:
normal'><span style='color:#353535'>5. Warranties and Limitation of Liability<o:p></o:p></span></b></p>

<p class=MsoNormal align=center style='text-align:center;mso-pagination:none;
mso-layout-grid-align:none;text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>5.1 <u>Disclaimer of
Warranties</u><o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>Although With the Band does
make reasonable efforts to give our users the best possible service, it is
hereby understood that our services are provided “as is” and as they are
available. Therefore, you agree and understand that the services provided by
With the Band are provided as such and that we do not give an express or
implied warranty, promise, or guarantee of any kind regarding any of the
services we provide or the availability and lack of disruption in services.
Thus, your use thereof is done at your own risk. To the greatest extent allowed
under applicable law, With the Band makes no promises or guarantees and
disclaims any warranty or conditions pertaining to the satisfactory quality of
any of our services, or their fitness for any particular purpose,
merchantability, or non-infringement. <o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>With the Band does not
guarantee that its services are free of any and all malware or anything other
harmful viruses or components. Furthermore, With the Band does not guarantee,
support, warrant, or assume responsibility for any and all third-party
applications (or content thereby generated) accessible via our Platform, or any
other product or service offered or advertised by any and all third-parties on
or via With the Band’s services or any hyperlinked website, or featured in any
advertisement or banner.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>5.2 <u>Limitation of Liability</u><o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535;text-transform:uppercase'>To
the greatest possible extent permitted by applicable law, in no case or event
will With the Band and, its officers, directors, members, shareholders, agents,
employees, consultants, board, board members, investors, affiliates,
subsidiaries, sponsors, assigns, suppliers, licensors, Affiliated Artists, and
any other third-party partners (referred to hereafter as “With the Band
Parties”) be held liable for any special, indirect, punitive, incidental,
exemplary or consequential damages, including but not limited to damages for
loss of profits, goodwill, data, or other intangible losses (even if any With
the Band Parties have been advised of the possibility of such damages) arising
ouT of or in connection with: (A) our Platform, or this Agreement, or the
inability to use our Platform (however arising, including our negligence); (B)
statements or conduct of or transactions with any member or third-PARTY on the
Platform; (C) your use of our Platform or transportation to or from With the
Band fan meet ups or the actions of you and or others at With the Band fan meet
ups or fan projects; (D) any availability or disruption of services; (E) any
other matter relating to the Platform; or (F) force of nature, natural
disaster, ARTIFICIAL disaster, virus-spread, pandemic, civil disruption, riots,
or war. <o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>THE MAXIMUM AGGREGATE
LIABILITY, IF ANY, OF WITH THE BAND PARTIES TO YOU FOR ANY REASON AND UPON ANY
CAUSE OF ACTION ARISING OUT OF OR RELATING TO THIS AGREEMENT, THE PLATFORM, AND
ANY SERVICES AND/OR PRODUCTS OFFERED BY WITH THE BAND PARTIES SHALL BE LIMITED
TO DIRECT DAMAGES IN AN AMOUNT NOT TO EXCEED THE TOTAL AMOUNT OF THE
COMPENSATION PAYABLE AND ACTUALLY PAID UNDER THIS AGREEMENT (AS THE SAME MAY BE
ADJUSTED FROM TIME TO TIME PURSUANT TO THE TERMS OF THIS AGREEMENT). THE
FOREGOING LIMITATIONS SHALL APPLY REGARDLESS OF THE CAUSE OR FORM OF ACTION,
WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, WARRANTY OR
OTHERWISE.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>The foregoing paragraphs,
which explicitly limit liability for With the Band Parties applies with equal
force to all artists and their representatives/affiliates whom collaborate in
any way with <span class=SpellE>With</span> the Band (“Affiliated Artists”).
Affiliated Artists are in no way responsible for any event, transaction, interaction
(directly, indirectly, virtually, or in-person), or occurrence arising out of
or relating to any With the Band fan meet up or fan project. <o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>There are some aspects of this
Section that may not apply in certain jurisdictions if they are prohibited
under applicable law. Unless otherwise prohibited, all aspects shall apply.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal align=center style='text-align:center;mso-pagination:none;
mso-layout-grid-align:none;text-autospace:none'><b style='mso-bidi-font-weight:
normal'><span style='color:#353535'>6. Release and Indemnification<o:p></o:p></span></b></p>

<p class=MsoNormal align=center style='text-align:center;mso-pagination:none;
mso-layout-grid-align:none;text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>6.1<u> Release of Liability</u>
<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>You agree to release With the
Band Parties, Affiliated Artists, and any other third-party partners from
claims, demand, and damages of every kind and nature, known and unknown, now
and in the future (referred to hereafter as “Claims”), arising out of or in any
way connected with any transaction with a third-party, your interactions with
other members, or in connection with a With the Band event. <o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>You also agree to release
organizers from Claims based on an organizer’s negligence arising out of or in
any way connected with their content or a With the Band event. Further, you
waive any and all rights and benefits otherwise conferred by any statutory or
non-statutory law of any jurisdiction that would purport to limit the scope of
a release or waiver to the fullest extent permitted by law.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>6.2 <u>Agreement to Indemnify</u><o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>To the greatest extent
permissible under applicable law, you agree to indemnify all With the Band
Parties and hold any With the Band Parties completely blameless and harmless
from and against any and all damages, losses, and/or expenses of any kind
(including but not limited to reasonable attorney’s fees and related costs)
which arise out of: (A) a breach of the Agreement which you commit; (B) any
user content on With the Band; and (C) any violation which you commit of any
law or the rights of any third-party.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal align=center style='text-align:center;mso-pagination:none;
mso-layout-grid-align:none;text-autospace:none'><b style='mso-bidi-font-weight:
normal'><span style='color:#353535'>7. Dispute Resolution<o:p></o:p></span></b></p>

<p class=MsoNormal align=center style='text-align:center;mso-pagination:none;
mso-layout-grid-align:none;text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>7.1 <u>Governing Law</u><o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>Unless prohibited by mandatory
law in a member state of the European Union or any other jurisdiction, the
Agreement (and any noncontractual disagreements or any claims due to or related
to them) is subject to the laws of the State of Tennessee in the United States
of America, without regard to any choice of law provisions of any other state
or country. <o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>7.2 Disputes; Arbitration<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>Unless otherwise prohibited by
law, if a dispute should arise between you and With the Band, we will <span
style='mso-spacerun:yes'> </span>attempt to resolve the dispute discussion if
possible. If unsuccessful in doing so, then you agree that any dispute with <span
class=SpellE>With</span> the Band in any way and any type, or any dispute
regarding With the Band Parties in relation to With the Band’s Platform,
services, products, and the Agreement, you agree to settle any dispute by
prompt, confidential, and binding arbitration pursuant to the Commercial
Dispute Resolution Process and the Supplementary Procedures for Consumer
Related Disputes of the American Arbitration Association (“AAA”) and then in
effect (“the AAA rules”) using a single arbitrator. The arbitration shall occur
in Nashville, Davidson County, Tennessee, and shall the governing law set forth
in Section 7.1 above. If With the Band or With the Band Parties prevail, you
shall be required to pay attorneys’ fees, expenses, filing fees, arbitration
fees, and cost incurred therein. The arbitration shall be conducted in the
English language and any party who does not speak the English language will be
solely responsible for any translations and/or translators so as to comply with
this requirement. The arbitration shall be the sole and exclusive means for
resolving disputes related to the Agreement, With the Band in any way and any
type, or any dispute regarding With the Band Parties in relation to With the
Band’s Platform, services, products, and the Agreement..<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>Any and all arbitrations must
be started by filing a demand for arbitration within the time frame of three
(3) months after the date the party of the plaintiff/claimant asserting the
claim first knows or reasonably should have known of the act, omission, or
default which game rise to the claim itself; and there will be no right to
remedy any claim that is not made within the time frame agreed upon in this
section. If, under applicable law, the one year time frame is not permissible,
then any claim must be asserted and filed within the shortest time period allowed
under the applicable law.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>7.3 Arbitration Enforcement<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>Enforcement the arbitration
provisions herein and enforcement of any judgment, determination, and/or awards
from the arbitration shall solely and exclusively be made in the state or
federal courts for Davidson County, Tennessee USA and, unless otherwise
prohibited by law, you hereby waive any and all objections and defenses of lack
of personal jurisdiction, improper venue, inconvenient venue, and lack of
subject-matter jurisdiction..<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>7. 4 Jury Waiver<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>EACH PARTY HEREBY IRREVOCABLY
WAIVES ITS RIGHTS TO A JURY TRIAL OF ANY CLAIM OR CAUSE OF ACTION BASED UPON OR
ARISING OUT OF THIS AGREEMENT,THE SUBJECT-MATTER OF THIS AGREEMENT, OR ANY .
THE SCOPE OF THIS WAIVER IS INTENDED TO BE ALL-ENCOMPASSING OF ANY AND ALL
DISPUTES THAT MAY BE FILED IN ANY COURT AND THAT RELATE TO THE SUBJECT-MATTER
OF THIS AGREEMENT, INCLUDING, WITHOUT LIMITATION, CONTRACT CLAIMS, TORT CLAIMS
(INCLUDING NEGLIGENCE), AND ALL OTHER COMMON LAW AND STATUTORY CLAIMS. THIS
SECTION HAS BEEN FULLY DISCUSSED BY EACH OF THE PARTIES HERETO AND THESE
PROVISIONS WILL NOT BE SUBJECT TO ANY EXCEPTIONS. EACH PARTY HERETO HEREBY
FURTHER WARRANTS AND REPRESENTS THAT SUCH PARTY HAS HAD THE OPPORTUNITY TO REVIEW
THIS WAIVER WITH ITS LEGAL COUNSEL, AND THAT SUCH PARTY KNOWINGLY AND
VOLUNTARILY WAIVES ITS JURY TRIAL RIGHTS .<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>7.2 <u>Class Action Waiver</u><o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>Where allowed under applicable
law, you and With the Band agree that each party may file complaints against
the other only through your or our Individual ability and never as a plaintiff
or class member in any class or representative action. Unless you and With the
Band both agree, neither a judge nor an arbitrator may ever consolidate the
claims of more than one person or preside in any other manner over any
variation of a representative or class proceeding.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal align=center style='text-align:center;mso-pagination:none;
mso-layout-grid-align:none;text-autospace:none'><b style='mso-bidi-font-weight:
normal'><span style='color:#353535'>8. Miscellaneous<o:p></o:p></span></b></p>

<p class=MsoNormal align=center style='text-align:center;mso-pagination:none;
mso-layout-grid-align:none;text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>8.1 <u>The Agreement</u><o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>Aside from anything stated to
the contrary in this Section, or explicitly agreed upon in writing between you
and With the Band, the Agreement constitutes all the conditions and terms that
you and With the Band have agreed upon, and supersedes any prior agreements
made pertaining to the subject-matter of the Agreement, whether oral or
written. <o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>8.2 <u>Severability and Waiver</u><o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>Unless otherwise stated in the
agreement, the invalidity of any specific section of the agreement for any
reason or to any extent is not to be understood as voiding any or all remaining
valid sections of the agreement, nor shall the validity or enforceability of
any and all valid sections be lessened by the invalidity of one specific
section or subsection, or stipulation therein.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>Failure by With the Band to
enforce the Agreement, or any stipulation held therein, shall not waive With
the Band’s rights or the rights any With the Band third-party beneficiary’s
right to do so presently or in the future. <o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>. If any provision of the
Agreement is held in whole or in part to be unenforceable for any reason, the
remainder of that provision and of the entire Agreement will be severable and
remain in effect. Paragraph headings are for organization only and shall not
impact the Terms and Conditions in the Agreement<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>8.3 <u>Assignment</u><o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>With the Band maintains the
right to assign the Agreement or any part found therein. With the Band also
maintains the right to delegate any specific obligation held under the
Agreement. <o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>You may not assign the
Agreement or any section found therein, nor may you transfer or sublicense any
of your rights granted to you under the Agreement to any third-party. <o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal align=center style='text-align:center;mso-pagination:none;
mso-layout-grid-align:none;text-autospace:none'><b style='mso-bidi-font-weight:
normal'><span style='color:#353535'>Contact Us</span></b><span
style='color:#353535'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><u><span style='color:#353535'>Mailing Address<o:p></o:p></span></u></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><u><span style='color:#353535'><o:p><span
 style='text-decoration:none'>&nbsp;</span></o:p></span></u></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>With the Band, LLC<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>41 Peabody St.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>Nashville, TN 37210<o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><u><span style='color:#353535'>Email Address<o:p></o:p></span></u></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-pagination:none;mso-layout-grid-align:none;
text-autospace:none'><span style='color:#353535'>Info@withtheband.co<o:p></o:p></span></p>


</div>
`;
export const TermsPage: React.FunctionComponent<RouteChildrenProps<{}>> = ({ history }) => {
  return (
    <CenteredContainer>
      <CenteredMaxWidth style={{ maxWidth: 900 }}>
        <WhiteShadowedBox>
          <img
            alt="logo"
            style={{ maxWidth: 280, margin: 'auto', display: 'block' }}
            src={require('../images/logo-black.png').default}
          />
          <div dangerouslySetInnerHTML={{ __html: TERMS }}></div>
        </WhiteShadowedBox>
      </CenteredMaxWidth>
    </CenteredContainer>
  );
};
