import React from 'react';
import {
  ImageDropzoneInputProps,
  ImageDropzoneInputState,
  uploadImage,
} from '../ImageDropzoneInput';
import { VideoDropzoneInput } from '../VideoDropzoneInput';
import _ from 'lodash';
import { Spinner } from '@blueprintjs/core';
import { PostImages } from './PostImages';

interface PostComposerMultimediaProps {
  details: CoreAPI.PostDetails;
  setDetails: (details: CoreAPI.PostDetails) => void;
}

type PostImageInputProps = Pick<ImageDropzoneInputProps, 'onChange' | 'inputEl'> & {
  images: {
    url: string;
    alt: string;
  }[];
  setLoading: (loading: boolean) => void;
};

export const PostComposerMultimedia = React.forwardRef(
  ({ details, setDetails }: PostComposerMultimediaProps, ref) => {
    const [loading, setLoading] = React.useState(false);

    const imageInputEl = React.createRef<HTMLInputElement>();
    const videoInputEl = React.createRef<HTMLInputElement>();

    const images = 'images' in details ? details.images : [];
    const video = 'video' in details ? details.video : null;

    React.useImperativeHandle(ref, () => ({
      attachImage: () => {
        imageInputEl.current?.click();
      },
      attachVideo: () => {
        videoInputEl.current?.click();
      },
    }));

    return (
      <>
        <div
          style={{
            display: images.length || loading ? 'flex' : 'none',
            marginTop: 10,
            marginBottom: 10,
            gap: 10,
            flex: 1,
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <PostImages
            images={images}
            onRemove={url => {
              setDetails({
                ...details,
                type: 'text',
                images: images.filter(img => img.url !== url),
              });
            }}
            onReorder={images => {
              setDetails({ ...details, type: 'text', images });
            }}
          />
          {loading && <Spinner />}
        </div>
        <PostImageInput
          images={images}
          inputEl={imageInputEl}
          setLoading={setLoading}
          onChange={url => {
            url &&
              setDetails({
                ...details,
                type: 'text',
                images: [...images, { url, alt: '' }],
              });
          }}
        />
        <div style={{ display: video ? 'flex' : 'none', marginTop: 10, marginBottom: 10 }}>
          <VideoDropzoneInput
            style={{ marginRight: 10, width: 186, height: 140 }}
            value={video}
            inputEl={videoInputEl}
            onChange={video =>
              setDetails({
                ...details,
                type: 'text',
                images: images,
                video: video ? video : undefined,
              })
            }
          />
        </div>
      </>
    );
  }
);

export class PostImageInput extends React.Component<PostImageInputProps, ImageDropzoneInputState> {
  inputId = _.uniqueId('image-input-');

  constructor(props: PostImageInputProps) {
    super(props);

    this.state = {
      progress: 0,
      cancelCount: 0,
      localFileURL: null,
      localFileRegion: null,
      localImage: null,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1,
    };
  }

  onPickedFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      this.onProcessFiles(e.target.files);
    }
  };

  onProcessFiles = (files: FileList) => {
    this.props.setLoading(true);
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (!file) continue;
      if (!['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)) {
        continue;
      }

      // Open the image editor with this file!
      const fr = new FileReader();
      fr.onload = ({ target }) => {
        if (!target) return;
        this.props.onChange('');

        const img = new Image();
        img.onload = () => {
          this.setState({
            localFileRegion: { x: 0, y: 0, width: img.width, height: img.height },
            localFileURL: target.result as string,
            localImage: img,
          });

          this.onUpload();
        };
        img.src = target.result as string;
      };
      fr.readAsDataURL(file);
    }
    this.props.setLoading(false);
  };

  onReset = () => {
    this.setState({
      progress: 0,
      localFileURL: null,
      localFileRegion: null,
      localImage: null,
      crop: { x: 0, y: 0 },
      zoom: 1,
      cancelCount: this.state.cancelCount + 1,
    });
  };

  onUpload = () => {
    const { localFileRegion, localImage } = this.state;

    if (!localFileRegion || !localImage) return;

    uploadImage(
      localImage,
      localFileRegion,
      undefined,
      'jpg',
      this.onUploadError,
      this.onUploadFinished,
      (currProgress: number) => {
        this.setState({ progress: Math.max(1, currProgress) });
      }
    );
  };

  onUploadError = (err: Error) => {
    alert(
      `An error occurred while uploading the image: ${err.toString()}. You may want to disable ad-blockers and try again.`
    );
    this.onReset();
  };

  onUploadFinished = (url: string) => {
    this.onReset();
    this.props.onChange(url);
  };

  render() {
    const { inputEl } = this.props;

    return (
      <input
        id={this.inputId}
        ref={inputEl}
        type="file"
        accept="image/*"
        multiple
        style={{ display: 'none' }}
        key={this.state.cancelCount}
        onChange={this.onPickedFiles}
      />
    );
  }
}
