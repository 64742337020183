import React from 'react';
import { Colors, Button, InputGroup } from '@blueprintjs/core';
import styled from 'styled-components/macro';

interface PageListHeaderProps {
  onAdd?: () => void;
  title: string;
  filter?: string;
  filterPlaceholder?: string;
  onChangeFilter?: (filter: string) => void;
  children?: React.ReactNode;
}

export const PageListHeader = ({
  onAdd,
  onChangeFilter,
  title,
  filter,
  filterPlaceholder,
  children,
}: PageListHeaderProps) => (
  <div
    style={{
      display: 'flex',
      marginBottom: 10,
      alignItems: 'center',
    }}
  >
    <h2 className="bp3-heading">{title}</h2>
    <div style={{ flex: 1 }} />
    {onChangeFilter && (
      <InputGroup
        value={filter}
        onChange={(e: React.FormEvent<HTMLInputElement>) => onChangeFilter(e.currentTarget.value)}
        leftIcon={'filter'}
        placeholder={filterPlaceholder || 'Filter...'}
      />
    )}
    {onAdd && <div style={{ width: 10 }} />}
    {onAdd && (
      <Button icon="add" onClick={onAdd}>
        Add New {title.replace(/s$/, '')}
      </Button>
    )}
    {children}
  </div>
);

const PageListTableContainer = styled.table`
  background: ${Colors.WHITE};
  width: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 2px;

  & > tbody > tr > td {
    vertical-align: middle;
  }
`;

export const PageListTable: React.FunctionComponent<{}> = props => (
  <PageListTableContainer
    className="bp3-html-table bp3-html-table-striped"
    style={{ boxShadow: 'none', border: '1px solid #ccc' }}
  >
    {props.children}
  </PageListTableContainer>
);
