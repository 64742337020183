import React from 'react';
import styled from 'styled-components/macro';
import { Button } from '../Button';
import { hexWithOpacity } from '../Common';

const Emoji = ['🙌', '🙏', '🎉', '😓'];

type MyEmoji = { [emoji: string]: CoreAPI.PostResponse };

export const Reactions: React.FunctionComponent<{
  poster: CoreAPI.User | null;
  responses: CoreAPI.PostResponse[];
  onDelete: (r: CoreAPI.PostResponse) => void;
  onCreate: (r: CoreAPI.UpsertPostResponsePayload) => void;
}> = ({ poster, responses, onDelete, onCreate }) => {
  const [showingTray, setShowingTray] = React.useState(false);
  const mine: MyEmoji = {};
  const reactionCounts: { [emoji: string]: number } = {};

  responses.forEach(r => {
    if (r.details.type !== 'reaction') return;
    reactionCounts[r.details.emoji] = (reactionCounts[r.details.emoji] || 0) + 1;
    if (r.userId === poster?.id) {
      mine[r.details.emoji] = r;
    }
  });

  const onToggleEmoji = (emoji: string) => {
    mine[emoji]
      ? onDelete(mine[emoji])
      : onCreate({ userId: poster?.id || undefined, details: { type: 'reaction', emoji } });
  };

  const addReactionsEnabled = Object.keys(reactionCounts).length < 4;

  return (
    <div
      style={{ display: 'flex', gap: 6, margin: addReactionsEnabled ? '0 32px' : 0, minHeight: 24 }}
    >
      {Object.keys(reactionCounts).map(emoji => (
        <Button
          key={emoji}
          style={{
            padding: '0 6px 1px 6px',
            transition: 'ease-in',
            zIndex: 1,
            color: 'white',
            ...(!!mine[emoji]
              ? {
                  border: `1px solid ${hexWithOpacity('#ffffff', 0.8)}`,
                  boxShadow: `0 0px 6px rgba(255, 255, 255, 0.5)`,
                }
              : { background: hexWithOpacity('#ffffff', 0.3), border: 'unset' }),
          }}
          size="small"
          intent={!!mine[emoji] ? 'primary' : 'none'}
          onClick={() => {
            onToggleEmoji(emoji);
          }}
        >
          {emoji}&nbsp;{reactionCounts[emoji]}
        </Button>
      ))}
      {addReactionsEnabled && (
        <AddEmojiContainer>
          {showingTray && (
            <ReactionsTray
              position="left"
              mine={mine}
              onClick={(emoji: string) => {
                onToggleEmoji(emoji);
                setShowingTray(false);
              }}
            />
          )}
          <Button style={{ padding: 4 }} size="small" onClick={() => setShowingTray(!showingTray)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="14"
              width="14"
              viewBox="0 0 48 48"
              style={{
                transform: showingTray ? 'rotate(45deg)' : 'rotate(0)',
                transition: 'transform 200ms linear',
              }}
            >
              <path d="M22.5 38V25.5H10v-3h12.5V10h3v12.5H38v3H25.5V38Z" />
            </svg>
          </Button>
          {showingTray && (
            <ReactionsTray
              position="right"
              mine={mine}
              onClick={(emoji: string) => {
                onToggleEmoji(emoji);
                setShowingTray(false);
              }}
            />
          )}
        </AddEmojiContainer>
      )}
    </div>
  );
};

const ReactionsTray: React.FC<{
  position: 'left' | 'right';
  onClick: (emoji: string) => void;
  mine: MyEmoji;
}> = ({ onClick, mine, position }) => {
  return (
    <ReactionsTrayContainer position={position}>
      {Emoji.map(emoji => (
        <Button
          key={emoji}
          style={{ padding: '2px 6px' }}
          size="small"
          intent={!!mine[emoji] ? 'primary' : 'none'}
          onClick={() => onClick(emoji)}
        >
          {emoji}
        </Button>
      ))}
    </ReactionsTrayContainer>
  );
};

const AddEmojiContainer = styled.div`
  position: absolute;
  display: flex;
  right: 16px;

  @media (max-width: 500px) {
    right: unset;
    left: 64px;
  }
`;

const ReactionsTrayContainer = styled.div<{ position: 'left' | 'right' }>`
  display: flex;
  gap: 6px;
  position: relative;
  right: 6px;
  bottom: 4px;
  background: white;
  box-shadow: 0 8px 13px rgba(0, 0, 0, 0.2);
  border-radius: 22px;
  padding: 4px;
  z-index: 10;
  cursor: default;

  @media (prefers-color-scheme: dark) {
    background: #111;
    color: white;
    .AdminRoot & {
      background: white;
      color: initial;
    }
  }

  ${({ position }) => {
    if (position === 'left') {
      return `
        display: flex;
        @media (max-width: 500px) {
          display: none;
        }
      `;
    } else {
      return `
      display: none;
      @media (max-width: 500px) {
          display: flex;
          left: 6px;
        }
      `;
    }
  }}
`;
