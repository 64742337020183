import React from 'react';
import _ from 'lodash';
import S3Upload from 'react-s3-uploader/s3upload';

import './DropzoneInput.css';
import { CSSProperties } from 'styled-components';
import { makeRequest } from '../Resource';
import { Spinner } from '@blueprintjs/core';

const S3_BUCKET_URL = 'https://withtheband-dev.herokuapp.com/api/s3-asset/';

interface VideoDropzoneInputProps {
  value: CoreAPI.PostDetailsBasic['video'] | null;
  onChange: (value: CoreAPI.PostDetailsBasic['video'] | null) => void;
  inputEl?: React.Ref<HTMLInputElement>;
  style: CSSProperties;
}

interface VideoDropzoneInputState {
  progress: number;
  cancelCount: number;
}

export class VideoDropzoneInput extends React.Component<
  VideoDropzoneInputProps,
  VideoDropzoneInputState
> {
  inputId = _.uniqueId('image-input-');

  constructor(props: VideoDropzoneInputProps) {
    super(props);

    this.state = {
      progress: 0,
      cancelCount: 0,
    };
  }

  onDragOver = (e: React.DragEvent<any>) => {
    e.preventDefault();
  };

  onDrop = (e: React.DragEvent<any>) => {
    e.preventDefault();
    this.onProcessFile(e.dataTransfer.files[0]);
  };

  onPickedFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      this.onProcessFile(e.target.files[0]);
    }
  };

  onProcessFile = (file: FileList[0]) => {
    if (!file) return;

    this.props.onChange({ muxAssetID: '', muxPlaybackID: '' });

    new S3Upload({
      files: [file],
      contentDisposition: 'auto',
      uploadRequestHeaders: { 'x-amz-acl': 'public-read' },
      onError: this.onUploadError,
      onFinishS3Put: async ({ fileKey }: { fileKey: string }) => {
        // Provide the S3 asset to Mux via our API to start transcoding
        const { muxAssetID, muxPlaybackID } = await makeRequest('/api/mux', 'POST', {
          url: `${S3_BUCKET_URL}${fileKey}`,
        });
        // Give Mux a few moments to create the video thumbnail
        setTimeout(() => {
          this.setState({ progress: 0 });
          this.props.onChange({ muxAssetID, muxPlaybackID });
        }, 3000);
      },
      onProgress: (progress: number) => this.setState({ progress }),
      server: '/api',
      signingUrl: '/s3/sign',
      signingUrlQueryParams: { path: 'uploaded-image/' },
      s3Url: S3_BUCKET_URL,
    });
  };

  onReset = () => {
    this.setState({
      progress: 0,
      cancelCount: this.state.cancelCount + 1,
    });
  };

  onUploadError = (err: Error) => {
    alert(
      `An error occurred while uploading the image: ${err.toString()}. You may want to disable ad-blockers and try again.`
    );
    this.onReset();
  };

  render() {
    const { inputEl, value, style } = this.props;
    const { progress } = this.state;

    return (
      <div
        className={`ImageDropzoneInput`}
        style={style}
        onDrop={this.onDrop}
        onDragOver={this.onDragOver}
      >
        <input
          id={this.inputId}
          ref={inputEl}
          type="file"
          accept="video/*"
          style={{ display: 'none' }}
          key={this.state.cancelCount}
          onChange={this.onPickedFile}
        />
        {value && value.muxPlaybackID ? (
          <div className="DropzoneInput populated">
            <label htmlFor={this.inputId} style={{ width: 'auto' }}>
              <ImgReloadUntilReady
                alt=""
                src={`https://image.mux.com/${value.muxPlaybackID}/thumbnail.jpg`}
                style={{
                  width: style.width,
                  height: style.height,
                  maxHeight: style.height,
                  objectFit: 'contain',
                }}
              />
            </label>
          </div>
        ) : value && !value.muxPlaybackID ? (
          <div className="DropzoneInput populated">
            <label htmlFor={this.inputId}>{CenteredSpinner}</label>
          </div>
        ) : (
          <label
            className="DropzoneInput"
            style={{ width: style.width, height: style.height, flexDirection: 'column' }}
            htmlFor={this.inputId}
          >
            <div style={{ padding: '0 10px' }}>{`Drag and drop a video`}</div>
          </label>
        )}

        {progress > 0 && <div className="progress" style={{ width: `${progress}%` }} />}
      </div>
    );
  }
}

const ImgReloadUntilReady: React.FunctionComponent<{
  src: string;
  alt: string;
  style: CSSProperties;
}> = ({ src, alt, style }) => {
  const imgRef = React.useRef(new Image());
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    const tryAgain = () => {
      imgRef.current.setAttribute('src', `${src}?t=${Date.now()}`);
    };
    imgRef.current.addEventListener('load', () => {
      setReady(true);
    });
    imgRef.current.addEventListener('error', () => {
      setTimeout(tryAgain, 1000);
    });
    tryAgain();
  }, []);

  return ready ? <img src={src} alt={alt} style={style} /> : CenteredSpinner;
};

const CenteredSpinner = (
  <div
    style={{
      width: 186,
      height: 140,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Spinner size={32} />
  </div>
);
