import { omit } from 'lodash';
import { useState } from 'react';
import { useDebouncedCall } from '../../Hooks';
import { makeRequest } from '../../Resource';
import { URL_REGEX } from './DecoratedText';

export interface PostComposerProps {
  user: CoreAPI.CurrentUser;
  club: CoreAPI.ClubWithMembership;
  channel: CoreAPI.ClubFeedChannel;
  isAdmin: boolean;
  onFinished: (postId: number) => void;
}

export function usePostComposerState({
  onFinished,
  club,
  user,
  channel,
  isAdmin,
}: PostComposerProps) {
  const EMPTY_VALUES: CoreAPI.CreatePostPayload = {
    notify: isAdmin ? true : false,
    channelKey: channel.key,
    audience: {},
    pinned: false,
    details: {
      type: 'text',
      text: '',
      images: [],
    },
  };

  const [saving, setSaving] = useState(false);
  const [values, setValues] = useState<CoreAPI.CreatePostPayload & { isTest?: boolean }>(
    EMPTY_VALUES
  );
  const [inputValue, _setInputValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hiddenPreviewUrls, setHiddenPreviewUrls] = useState<string[]>([]);

  const setInputValue = (value: string) => {
    _setInputValue(value);
    setValues(values => ({ ...values, details: { ...values.details, text: value } }));
    onCheckUrls(value);
  };

  const onCheckUrls = useDebouncedCall(async (text: string) => {
    const links = text.match(URL_REGEX)?.filter(l => !hiddenPreviewUrls.includes(l));

    if (!links?.length) return;

    setIsLoading(true);
    const metadata = await makeRequest<CoreAPI.LinkPreview[]>(
      '/api/v1/clubs/post/preview',
      'POST',
      { links }
    );
    setIsLoading(false);
    setValues(values => ({ ...values, details: { ...values.details, previews: metadata } }));
  });

  const save = async () => {
    if (!saving && !isLoading) {
      // Apply the final text to make sure there aren't changes waiting on a debounce

      const details = { ...values.details, text: inputValue };
      if (hiddenPreviewUrls.length) {
        details.previews = details.previews?.filter(p => !hiddenPreviewUrls.includes(p.url));
      }

      const final = { ...values, userId: user.id, channelKey: channel.key, details };

      setSaving(true);
      const resp = await makeRequest<CoreAPI.Post | { error: string }>(
        `/api/v1/clubs/${club.id}/posts`,
        'POST',
        { ...omit(final, 'isTest'), channelKey: final.isTest ? 'test' : final.channelKey }
      );
      if ('id' in resp) {
        setValues(EMPTY_VALUES);
        onFinished(resp.id);
      } else {
        alert(resp.error);
      }
      setSaving(false);
    }
  };

  return {
    saving,
    setSaving,
    setDetails: (details: CoreAPI.PostDetails) => setValues({ ...values, details }),
    values,
    setValues,
    save,
    setInputValue,
    isLoading,
    shownPreviews: values.details.previews?.filter(p => !hiddenPreviewUrls.includes(p.url)),
    hidePreview: (url: string) => {
      setHiddenPreviewUrls(prev => [...prev, url]);
    },
  };
}
