import {
  Button,
  Classes,
  Dialog,
  HTMLInputProps,
  HTMLSelect,
  Icon,
  IInputGroupProps,
  InputGroup,
  Popover,
} from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';
import moment from 'moment';
import React from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ClubContext } from '../App';
import { makeRequest, useResource } from '../Resource';
import { PageListHeader } from './Components';
import { PageContainer } from './PageHome';

interface TimeBlock {
  day: Date;
  timeStart: number;
  timeEnd: number;
}

const MIN = 60 * 1000;
const chime = buildSound('/public/sound/chime.wav');
const tick = buildSound('/public/sound/tick.mp3');

function slotsFor(date: TimeBlock, durationMin: number, gapMin: number) {
  const slots = [];
  let slotTime = moment(date.day).add(date.timeStart, 'minutes');
  let slotTimeEnd = moment(date.day).add(date.timeEnd, 'minutes');
  while (slotTime < slotTimeEnd) {
    slots.push(slotTime.toDate());
    slotTime = slotTime.add(durationMin, 'minutes').add(gapMin, 'minutes');
  }
  return slots;
}

export const PageClubMeetAndGreets = (props: RouteComponentProps<{ clubId: string }>) => {
  const club = React.useContext(ClubContext);

  const [blocks, ops] = useResource<CoreAPI.MeetAndGreetBlock[], CoreAPI.MeetAndGreetPostPayload>(
    `/api/admin/v1/clubs/${club.id}/meet-and-greets`
  );

  const [duration, setDuration] = React.useState(3);
  const [gap, setGap] = React.useState(1);
  const [date, setDate] = React.useState<TimeBlock | null>(null);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [showingInviteId, setShowingInviteId] = React.useState(-1);

  const [highlightedId, setHighlightedId] = React.useState(-1);
  const [highlightedSecRemaining, setHighlightedSecRemaining] = React.useState(0);

  const [lateByMS, setLateByMS] = React.useState(0);

  const all = (blocks || []).map(b => b.calls!).flat();

  React.useEffect(() => {
    const x = setInterval(() => {
      ops.refresh();
    }, 30 * 1000);
    return () => clearInterval(x);
  }, [ops]);

  React.useEffect(() => {
    const x = setInterval(() => {
      const now = Date.now() - lateByMS;

      const current = all.find(
        a => a.startDate - 10 * 1000 < now && a.startDate + a.duration * MIN > now
      );
      if (current) {
        const remaining = Math.round((current.startDate + current.duration * MIN - now) / 1000);
        setHighlightedSecRemaining(remaining);
        if (remaining === 30) {
          tick.play();
        }
        if (remaining < 15) {
          tick.play();
        }
      } else {
        setHighlightedSecRemaining(0);
      }

      if (highlightedId !== (current?.id || -1)) {
        setHighlightedId(current?.id || -1);
        chime.play();
      }
    }, 1000);
    return () => clearInterval(x);
  });

  const save = async () => {
    if (!date) return;
    const slots = slotsFor(date, duration, gap);
    setSaving(true);
    await ops.post({
      durations: duration,
      startDate: slots[0].getTime(),
      endDate: slots[slots.length - 1].getTime(),
      calls: slots.map(s => ({ startDate: s.getTime(), duration: duration })),
    });
    setSaving(false);
    setDate(null);
  };

  return (
    <PageContainer>
      <Dialog style={{ width: 590 }} isOpen={!!date} title="Schedule" onClose={() => setDate(null)}>
        {date && (
          <div className={Classes.DIALOG_BODY}>
            <div style={{ display: 'flex' }}>
              <div style={{ width: 560 }}>
                <strong>Date & Time</strong>
                <div style={{ display: 'flex' }}>
                  <DateInput
                    value={date.day}
                    parseDate={str => new Date(str)}
                    formatDate={date => moment(date).format('YYYY-MM-DD')}
                    onChange={day => setDate({ ...date, day })}
                  />
                  <TimeWindowPicker
                    start={date.timeStart}
                    end={date.timeEnd}
                    onChange={({ start, end }) => {
                      setDate({ ...date, timeStart: start, timeEnd: end });
                    }}
                  />
                </div>
              </div>
              <div style={{ marginLeft: 10 }}>
                <strong>Duration</strong>
                <HTMLSelect value={duration} onChange={e => setDuration(Number(e.target.value))}>
                  {[2, 3, 4, 5, 10, 15].map(m => (
                    <option key={m} value={m}>
                      {m} min
                    </option>
                  ))}
                </HTMLSelect>
              </div>
              <div style={{ marginLeft: 10 }}>
                <strong>Break</strong>
                <HTMLSelect value={gap} onChange={e => setGap(Number(e.target.value))}>
                  <option value={0}>None</option>
                  {[1, 2, 3, 4, 5].map(m => (
                    <option key={m} value={m}>
                      {m} min
                    </option>
                  ))}
                </HTMLSelect>
              </div>
            </div>
            <div style={{ paddingTop: 5, opacity: 0.6 }}>
              Timezone: {Intl.DateTimeFormat().resolvedOptions().timeZone}
            </div>

            <div
              style={{
                paddingTop: 15,
                marginTop: 15,
                borderTop: '1px solid #ccc',
                display: 'flex',
                color: '#777',
                fontSize: 12,
              }}
            >
              <p>
                Note: With the Band will help you coordinate your calls with audio alerts in your
                browser. Click "Test Sound" to make sure you hear a chime. If you do not, check the
                top left of your Browser window - you may need to enable audio.
              </p>
              <div style={{ flexShrink: 0, marginLeft: 10 }}>
                <Button small onClick={() => chime.play()}>
                  Test Sound
                </Button>
              </div>
            </div>
          </div>
        )}
        <div className={Classes.DIALOG_FOOTER}>
          <Button onClick={save} intent="primary" disabled={saving}>
            {saving ? 'Saving...' : 'Schedule & Get Links'}
          </Button>
          {date && (
            <span style={{ fontStyle: 'italic', paddingLeft: 15 }}>
              {slotsFor(date, duration, gap).length} timeslots will be created.
            </span>
          )}
        </div>
      </Dialog>
      <PageListHeader title="Meet and Greets">
        <div style={{ width: 10 }} />
        <Button
          onClick={() =>
            setDate({
              day: moment().add(4, 'days').startOf('day').toDate(),
              timeStart: 15 * 60,
              timeEnd: 15 * 60 + 30,
            })
          }
          intent="primary"
        >
          Schedule Meet and Greet
        </Button>
      </PageListHeader>
      <p>
        We'll schedule a Zoom call for each meet and greet and create private links you can share
        with each fan. (Fans join through the With The Band mobile apps). We track when they sign up
        and send reminders and push notifications to ensure they join the call early. During the
        meet and greet session, this page allows you to rapidly move from call to call!
      </p>
      <div>
        {(blocks || [])
          .sort((a, b) => a.startDate - b.startDate)
          .map((block, idx) => (
            <div key={idx}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h4>
                  {moment(block.startDate).format('LLLL')} to{' '}
                  {moment(block.endDate).format('h:mm A')}
                </h4>
                <div style={{ flex: 1 }} />
                <Button
                  onClick={() =>
                    window.confirm(
                      'Are you sure? If fans have accepted invitations to these events, click Cancel and record their usernames before deleting the schedule so you can follow-up with them individually.'
                    ) && ops.deleteItem(block)
                  }
                >
                  Cancel All
                </Button>
              </div>
              <table style={{ minWidth: '50vw' }}>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'left' }}>Time</th>
                    <th style={{ textAlign: 'left', width: '30%' }}>User</th>
                    <th style={{ textAlign: 'left', width: 300 }}>Call</th>
                    <th style={{ textAlign: 'left', width: 100 }}>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {block.calls?.map(c => (
                    <tr
                      key={c.id}
                      style={{
                        lineHeight: '30px',
                        background: highlightedId === c.id ? 'antiquewhite' : 'white',
                      }}
                    >
                      <td>
                        {moment(c.startDate).format('h:mm A')}
                        <span style={{ opacity: 0.5 }}> - {c.duration} min</span>
                        {highlightedId === c.id && lateByMS > 60 * 1000 && (
                          <em style={{ opacity: 0.5, paddingLeft: 5 }}>
                            {`(${Math.floor(lateByMS / (60 * 1000))}min behind schedule)`}
                          </em>
                        )}
                      </td>
                      <td>
                        {c.user ? (
                          <CurrentAssignment>
                            {c.user.username}
                            <Button
                              className="show-on-hover"
                              outlined={false}
                              small
                              minimal
                              style={{ marginLeft: 7 }}
                              onClick={() =>
                                window.confirm(
                                  "Are you sure? We'll send this fan an email letting them know that this meet and greet has been unscheduled."
                                ) &&
                                makeAssignRequest(
                                  club.id,
                                  { meetAndGreetId: c.id, username: null },
                                  ops.refresh
                                )
                              }
                            >
                              <Icon icon="trash" />
                            </Button>
                          </CurrentAssignment>
                        ) : (
                          <div style={{ display: 'flex', alignItems: 'center', lineHeight: 0 }}>
                            <AssignButton
                              meetAndGreetId={c.id}
                              clubId={club.id}
                              onDone={ops.refresh}
                            />
                            <div style={{ width: 10 }} />
                            {showingInviteId === c.id ? (
                              <AutoselectInputGroup
                                value={`https://app.withtheband.co/${club.slug}/meet:${c.invitationHash}`}
                                autoFocus
                                readOnly
                                small
                                onBlur={() => setShowingInviteId(-1)}
                              />
                            ) : (
                              <Button small onMouseUp={() => setShowingInviteId(c.id)}>
                                Show Invite Link
                              </Button>
                            )}
                          </div>
                        )}
                      </td>
                      <td style={{ display: 'flex', alignItems: 'center' }}>
                        {c.userId && (
                          <>
                            <a
                              href={
                                c.zoomstate && 'start_url' in c.zoomstate
                                  ? c.zoomstate.start_url
                                  : '/#'
                              }
                              onClick={() => setLateByMS(Date.now() - c.startDate)}
                              rel="nofollow noreferrer"
                              target="_blank"
                            >
                              <Button
                                small
                                type="submit"
                                intent={highlightedId === c.id ? 'primary' : 'none'}
                                disabled={!c.zoomstate}
                              >
                                Start as Artist
                              </Button>
                            </a>

                            <a
                              href={
                                c.zoomstate && 'join_url' in c.zoomstate
                                  ? c.zoomstate.join_url
                                  : '/#'
                              }
                              style={{ marginLeft: 10 }}
                              rel="nofollow noreferrer"
                              target="_blank"
                            >
                              <Button
                                small
                                type="submit"
                                intent={highlightedId === c.id ? 'primary' : 'none'}
                                disabled={!c.zoomstate}
                              >
                                Start as Moderator
                              </Button>
                            </a>
                            {highlightedId === c.id && (
                              <div style={{ paddingLeft: 10, opacity: 0.4 }}>
                                {formatTimeRemaining(highlightedSecRemaining)}
                              </div>
                            )}
                          </>
                        )}
                      </td>
                      <td>
                        {c.startDate < Date.now() && c.result && c.userId
                          ? c.result.participants_count === 0 && !c.result.started
                            ? 'Not Started'
                            : c.result.participants_count > 1
                            ? 'Completed'
                            : 'No Show'
                          : c.userstate === 'launched-app'
                          ? 'Fan in app'
                          : c.userstate === 'notified'
                          ? 'Fan notified'
                          : '-'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
      </div>
    </PageContainer>
  );
};

const TimeWindowPicker: React.FunctionComponent<{
  start: number;
  end: number;
  onChange: ({ start, end }: { start: number; end: number }) => void;
}> = props => {
  const options: React.ReactNode[] = [];
  for (let time = 6 * 60; time < 24 * 60; time += 15) {
    options.push(
      <option key={time} value={time}>
        {time >= 13 * 60 ? Math.floor((time - 12 * 60) / 60) : Math.floor(time / 60)}:
        {time % 60 === 0 ? '00' : time % 60}
        {time < 12 * 60 ? 'am' : 'pm'}
      </option>
    );
  }
  return (
    <>
      <HTMLSelect
        value={props.start}
        onChange={e => props.onChange({ end: props.end, start: Number(e.target.value) })}
      >
        {options}
      </HTMLSelect>
      <HTMLSelect
        value={props.end}
        onChange={e => props.onChange({ end: Number(e.target.value), start: props.start })}
      >
        {options}
      </HTMLSelect>
    </>
  );
};

async function makeAssignRequest(
  clubId: number,
  args: CoreAPI.MeetAndGreetAssignPayload,
  done: () => void
) {
  const resp = await makeRequest<{ success: true } | { error: string }>(
    `/api/admin/v1/clubs/${clubId}/meet-and-greets/assign`,
    'POST',
    args
  );
  if ('error' in resp) {
    alert(resp.error);
  } else {
    done();
  }
}

const AssignButton: React.FunctionComponent<{
  clubId: number;
  meetAndGreetId: number;
  onDone: () => void;
}> = props => {
  const [username, setUsername] = React.useState('');

  return (
    <Popover
      content={
        <form
          style={{ padding: 10, display: 'flex', alignItems: 'center' }}
          onSubmit={async e => {
            e.preventDefault();
            void makeAssignRequest(
              props.clubId,
              { meetAndGreetId: props.meetAndGreetId, username: username },
              props.onDone
            );
          }}
        >
          <InputGroup
            type="text"
            autoFocus
            value={username}
            placeholder="Fan Username"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
          />
          <div style={{ width: 10 }} />
          <Button small type="submit" intent="primary">
            Assign
          </Button>
        </form>
      }
    >
      <Button small>Assign...</Button>
    </Popover>
  );
};

const AutoselectInputGroup: React.FunctionComponent<IInputGroupProps & HTMLInputProps> = props => {
  const ref = React.createRef<HTMLInputElement>();
  React.useLayoutEffect(() => {
    ref.current?.focus();
    ref.current?.select();
  }, [ref]);
  return <InputGroup inputRef={ref} {...props} />;
};

function buildSound(src: string) {
  const sound: HTMLAudioElement = document.createElement('audio');
  sound.src = src;
  sound.setAttribute('preload', 'auto');
  sound.setAttribute('controls', 'none');
  sound.style.display = 'none';
  document.body.appendChild(sound);
  return sound;
}

function formatTimeRemaining(sec: number) {
  return `${Math.floor(sec / 60)}:${sec % 60 < 10 ? '0' : ''}${sec % 60} left`;
}

const CurrentAssignment = styled.div`
  display: flex;
  align-items: center;
  & .show-on-hover {
    display: none;
  }
  &:hover .show-on-hover {
    display: inline-flex;
  }
`;
