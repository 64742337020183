import React from 'react';
import styled from 'styled-components/macro';
import { Button } from '../Button';

interface PostBodyPollProps {
  poster: CoreAPI.User | null;
  responses: CoreAPI.PostResponse[];
  details: CoreAPI.PostDetailsPoll;
  onCreate: (r: CoreAPI.UpsertPostResponsePayload) => void;
}

export const PostBodyPoll: React.FunctionComponent<PostBodyPollProps> = ({
  poster,
  details,
  responses,
  onCreate,
}) => {
  const [choiceIdx, setChoiceIdx] = React.useState<number>(-1);
  const counts: { [idx: string]: number } = {};
  let mine: CoreAPI.PostResponse | null = null;
  let total = 0;

  responses.forEach(r => {
    if (r.details.type !== 'choice') return;
    counts[r.details.choiceIdx] = (counts[r.details.choiceIdx] || 0) + 1;
    total += 1;
    if (r.userId === poster?.id) {
      mine = r;
    }
  });

  if (mine) {
    return (
      <div>
        {details.choices.map((c, idx) => {
          const pct = Math.round(((counts[idx] || 0) * 100) / total);
          return (
            <PollResponseOption key={idx} percent={pct}>
              {c}
              <div style={{ flex: 1 }} />
              <div>{pct}%</div>
            </PollResponseOption>
          );
        })}
      </div>
    );
  }

  return (
    <div>
      {details.choices.map((c, idx) => (
        <label key={idx} style={{ display: 'block', marginBottom: 10 }}>
          <input
            type="radio"
            value={idx}
            disabled={!poster}
            checked={choiceIdx === idx}
            onChange={() => setChoiceIdx(idx)}
          />
          {c}
        </label>
      ))}
      <Button
        size="small"
        onClick={() =>
          onCreate({ userId: poster?.id || undefined, details: { type: 'choice', choiceIdx } })
        }
        style={{ marginBottom: 15 }}
        disabled={!poster}
      >
        {poster ? 'Submit Response' : 'Sign in to Respond'}
      </Button>
    </div>
  );
};

const PollResponseOption = styled.div<{ percent: number }>`
  display: flex;
  padding: 3px 6px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-bottom: 4px;
  background: ${({ percent, theme }) => `linear-gradient(
    to right,
    ${theme.tintColor}77 ${percent}%,
    transparent ${percent + 0.01}%
  )`};
`;
