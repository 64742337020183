import React from 'react';
import styled from 'styled-components/macro';

export const AudioPlayer: React.FunctionComponent<{
  audio: CoreAPI.Audio;
  onChangeTitle?: (title: string) => void;
}> = ({ audio, onChangeTitle }) => {
  return (
    <div style={{ background: '#171717', color: 'white', width: '100%', padding: 10 }}>
      {onChangeTitle ? (
        <input
          type="text"
          value={audio.title}
          onChange={e => onChangeTitle(e.target.value)}
          placeholder="Add a title..."
          style={{
            color: 'white',
            background: 'black',
            width: '100%',
            border: '1px solid rgba(255,255,255,0.3)',
            padding: 5,
            fontSize: 15,
          }}
        />
      ) : (
        <strong>{audio.title}</strong>
      )}
      <StyledAudio
        controls
        controlsList="nodownload noplaybackrate"
        onContextMenu={e => e.preventDefault()}
      >
        <source src={audio.url} type={audio.mimetype} />
        Your browser does not support the
        <code>audio</code> element.
      </StyledAudio>
    </div>
  );
};

const StyledAudio = styled.audio`
  width: 100%;
  margin-top: 10px;

  &::-webkit-media-controls-panel {
    background-color: #aaa;
  }
`;
