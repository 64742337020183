import React from 'react';
import { RouteChildrenProps } from 'react-router';
import { SessionContext } from '../App';
import { makeRequest } from '../Resource';
import { CenteredMaxWidth, CenteredContainer } from '../components/Common';

export const LogoutPage: React.FunctionComponent<RouteChildrenProps<{}>> = ({ history }) => {
  const [, ops] = React.useContext(SessionContext);
  React.useEffect(() => {
    const logout = async () => {
      await makeRequest<{ success: boolean }>(`/api/v1/logout`, 'POST', {});
      await ops.refresh();
      history.push('/login');
    };
    logout();
  }, [history, ops]);

  return (
    <CenteredContainer>
      <CenteredMaxWidth style={{ width: 400 }}>
        <h1 style={{ textAlign: 'center' }}>Logging Out...</h1>
      </CenteredMaxWidth>
    </CenteredContainer>
  );
};
