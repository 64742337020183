import { uniqBy } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useInterval } from '../../Hooks';
import { makeRequest } from '../../Resource';

export interface PostOps {
  onUpdate: (post: CoreAPI.UpdatePostPayload) => Promise<void>;
  onDelete: (id: number) => Promise<void>;
  load: (type: 'initial' | 'next' | 'latest') => Promise<void>;
}

export function usePaginatedPosts({
  clubSlug,
  channelKey,
  includeScheduled,
}: {
  clubSlug: string;
  channelKey: string;
  includeScheduled: boolean;
}) {
  const [posts, setPosts] = useState<CoreAPI.Post[]>([]);
  const [hasMorePosts, setHasMore] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const load = useCallback(
    async (type: 'initial' | 'next' | 'latest') => {
      if (isLoading) return;

      setLoading(true);
      let path = `/api/v1/clubs/${clubSlug}/posts?channelKey=${channelKey}&includeScheduled=${
        includeScheduled ? true : false
      }`;
      if (type === 'next') path += `&offset=${posts.length}`;
      const res = await makeRequest<CoreAPI.PostsRes>(path);
      setPosts(prev =>
        type === 'next' ? [...prev, ...res.posts] : uniqBy([...res.posts, ...prev], 'id')
      );
      if (type !== 'latest' && typeof res.hasMorePosts === 'boolean') setHasMore(res.hasMorePosts);
      setLoading(false);
    },
    [isLoading, channelKey, clubSlug, posts.length, includeScheduled]
  );

  const postOps = React.useMemo<PostOps>(
    () => ({
      load: load,
      onDelete: async (id: number) => {
        const resp = await makeRequest<{ error?: string }>(
          `/api/v1/clubs/${clubSlug}/posts/${id}`,
          'DELETE'
        );
        if (resp && 'error' in resp) {
          alert(resp.error);
        } else {
          setPosts(prev => prev.filter(p => p.id !== id));
        }
      },
      onUpdate: async (post: CoreAPI.UpdatePostPayload) => {
        const resp = await makeRequest<CoreAPI.Post | { error: string }>(
          `/api/v1/clubs/${clubSlug}/posts/${post.id}`,
          'PUT',
          post
        );
        if (!resp || 'error' in resp) {
          alert(resp?.error);
          return;
        }
        setPosts(prev => prev.map(p => (p.id === post.id ? resp : p)));
      },
    }),
    [load, clubSlug]
  );

  useEffect(() => {
    setPosts([]);
    load('initial');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelKey, clubSlug]);

  // Refresh every 30s
  const loadLatest = React.useCallback(() => load('latest'), [load]);
  useInterval(loadLatest, 30000);

  return {
    posts,
    postOps,
    hasMorePosts,
    loaded: posts.length !== 0,
  };
}
