import qs from 'query-string';
import React from 'react';
import { RouteChildrenProps } from 'react-router';
import { Link } from 'react-router-dom';
import { SessionContext } from '../App';
import { Button } from '../components/Button';
import { Form } from '../components/Common';
import { makeRequest } from '../Resource';
import { AuthPageContainer } from './AuthPageContainer';

export const LoginPage: React.FunctionComponent<RouteChildrenProps<{}>> = ({
  location,
  history,
}) => {
  const [, ops] = React.useContext(SessionContext);
  const [error, setError] = React.useState('');

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const next = (qs.parse(location.search)['next'] as string) || '/';

  const onSubmit = async (e: React.FormEvent<any>) => {
    e.preventDefault();

    if (!email || !password) {
      setError('Please enter an email address and password.');
      return;
    }
    const resp = await makeRequest<{ error?: string; success: boolean }>(`/api/v1/login`, 'POST', {
      username: email,
      password,
    });
    if (resp.error) {
      setError(resp.error);
    } else {
      await ops.refresh();
      history.push(next);
    }
  };

  return (
    <AuthPageContainer location={location}>
      <Form onSubmit={onSubmit}>
        <h1 style={{ textAlign: 'center' }}>Sign In</h1>

        {error ? <div className="alert alert-danger">{error}</div> : undefined}

        <div className="group" style={{ marginTop: 8, marginBottom: 8 }}>
          <label className="form-block" htmlFor="email">
            <div>Email</div>
            <input
              autoFocus
              value={email}
              onChange={e => setEmail(e.target.value)}
              className="field"
              type="text"
              name="username"
              autoCapitalize="off"
              autoComplete="email"
              placeholder="hello-world@domain.com"
              id="email"
            />
          </label>

          <label className="form-block" htmlFor="current-password">
            <div>Password</div>
            <input
              value={password}
              onChange={e => setPassword(e.target.value)}
              className="field"
              type="password"
              name="password"
              autoComplete="current-password"
              id="current-password"
            />
          </label>
        </div>
        <Link
          style={{ float: 'right', paddingBottom: 20, color: '#777', fontSize: 14 }}
          to={`/forgot?email=${email}`}
        >
          Forgot Password?
        </Link>
        <div style={{ clear: 'both' }}></div>
        <input type="submit" style={{ display: 'none' }} />
        <Button intent="primary" style={{ width: '100%' }} onClick={onSubmit}>
          <div className="loader-container"></div>
          Sign In
        </Button>

        <hr style={{ marginTop: 40, marginBottom: 40 }} />

        <div style={{ textAlign: 'center' }}>
          <p>Just getting started?</p>
          <Link to="/register">
            <Button style={{ width: '100%' }}>Create an account</Button>
          </Link>
        </div>
      </Form>
    </AuthPageContainer>
  );
};
