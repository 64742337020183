export const BrandTint = '#5B62BE';

export const BrandSecondaryTint = '#8337a7';

export const BrandAlphaSteps: string[] = [];
for (let ii = 0; ii < 15; ii++) {
  BrandAlphaSteps.push(
    `${BrandTint}${
      ['11', '22', '33', '44', '55', '66', '77', '88', '99', 'AA', 'BB', 'CC', 'DD', 'EE', 'FF'][ii]
    }`
  );
}
