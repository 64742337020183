import { ITextAreaProps, TextArea } from '@blueprintjs/core';
import { omit } from 'lodash';
import React, { CSSProperties, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { PublicFooter } from '../pages/PublicFooter';

export const WARNING_TINT = '#d9822b';

export const hexWithOpacity = (hex: string, opacity: number) => {
  const tempHex = hex.replace('#', '');
  const r = parseInt(tempHex.substring(0, 2), 16);
  const g = parseInt(tempHex.substring(2, 4), 16);
  const b = parseInt(tempHex.substring(4, 6), 16);
  return `rgba(${r},${g},${b},${opacity})`;
};

const fadeup = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const FeedItemContainer = styled.div`
  display: block;
  position: relative;
  padding: 10px;
  padding-bottom: 20px;
  user-select: none;
  animation: ${fadeup} 550ms ease-out;
  animation-fill-mode: both;
`;

export const TextInput: React.FC<ITextAreaProps> = props => {
  const [value, setValue] = useState('');

  useEffect(() => {
    typeof props.value === 'string' && setValue(props.value);
  }, [props.value]);

  return (
    <TextArea
      style={{
        resize: 'none',
        borderRadius: 10,
        flex: 1,
        fontFamily: 'Manrope',
        background: hexWithOpacity('#ffffff', 0.9),
        ...props.style,
      }}
      fill={true}
      growVertically={true}
      value={value}
      {...omit(props, ['style', 'value'])}
    />
  );
};

export const FeedItemBox = styled.div`
  background: ${({ theme }) =>
    theme.themeMode === 'dark' ? hexWithOpacity('#000000', 0.45) : hexWithOpacity('#ffffff', 0.2)};
  display: flex;
  border-radius: 15px;
  box-shadow: 0 4px 16px rgba(255, 255, 255, 0.12);
  padding: 12px 16px;
  gap: 12px;
  z-index: 2;
  position: relative;
  user-select: none;
  color: white;

  @media (max-width: 500px) {
    padding: 12px;
  }

  @media (prefers-color-scheme: dark) {
    background: rgb(40, 40, 40);
    color: white;
    .AdminRoot & {
      background: white;
      color: initial;
    }
  }
`;

export const Form = styled.form`
  opacity: 1;
  transition: transform 200ms linear, opacity 200ms linear;
  transform: translate(0, 0);
  &.thank-you {
    transform: translate(0, 40px);
    opacity: 0;
  }

  & label.form-block {
    display: block;
    margin-top: 15px;
  }
  & label.form-block div {
    opacity: 0.8;
    padding-bottom: 3px;
  }

  & input[type='text'],
  & input[type='password'],
  & input[type='email'],
  & textarea {
    width: 100%;
    box-sizing: border-box;
    padding: 8px 5px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-bottom: 1px solid white;
    background: rgba(235, 235, 235, 0.9);
    font-size: 14px;
  }
  & input[type='text']:focus,
  & input[type='password']:focus,
  & input[type='email']:focus {
    border-bottom: 1px solid rgb(226, 62, 76);
  }

  & label {
    opacity: 0.8;
    padding-bottom: 3px;
    font-size: 14px;
    line-height: 22px;
  }
  & input {
    background: #f3f3f3;
    border-bottom: 1px solid #ddd;
  }
  & hr {
    margin: 30px 0;
    border: none;
    border-bottom: 1px solid #ddd;
  }
`;

export const SheetModal = styled.div`
  width: 90vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1000px;
  min-width: 300px;
  max-height: 90vh;
  margin: auto;
  background: white;
  border-radius: 6px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.25);
  padding: 10px;
  overflow: auto;
`;

export const Sheet = styled.div<{ visible: boolean }>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 20;
  pointer-events: ${({ visible }) => (visible ? 'initial' : 'none')};
  background: ${({ visible }) => (visible ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0)')};
  transition: background 200ms ease-in-out;

  ${SheetModal} {
    top: ${({ visible }) => (visible ? '50%' : '55%')};
    opacity: ${({ visible }) => (visible ? '1' : '0')};
    transition: opacity 200ms ease-in-out, top 200ms ease-in-out;
  }
`;

export const CenteredMaxWidth = styled.div`
  display: block;
  max-width: 800px;
  margin: auto;

  @media (max-width: 500px) {
    max-width: 100% !important;
  }
`;

const ClubHeroContainer = styled.div`
  display: block;
  height: 35vh;
  min-height: 300px;
  max-height: 600px;
  text-align: center;
  font-size: 30px;
  padding-top: 100px;
  position: relative;
`;

export const ClubHero: React.FunctionComponent<{ club: CoreAPI.Club }> = ({ club }) => {
  const backgroundPosition = `${
    club.config.heroImageAlignment === 'left'
      ? 25
      : club.config.heroImageAlignment === 'right'
      ? 70
      : 50
  }% 25%`;

  return (
    <ClubHeroContainer
      style={{
        background: `${club?.config.tintColor}33`,
        backgroundBlendMode: 'multiply',
        backgroundImage: `url(${
          club?.config.heroImageURL
        }), linear-gradient(to bottom, ${hexWithOpacity('#000000', 0)} 50%, ${hexWithOpacity(
          club?.config.bgColor,
          0.9
        )}`,
        backgroundPosition,
        backgroundSize: 'cover',
      }}
    />
  );
};

export const WhiteShadowedBox = styled.div`
  background: white;
  padding: 25px;
  flex: 1;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 1);
  outline: 8px solid white;
  outline-offset: 10px;
`;

export const DashboardBox = styled.div`
  flex: 1;
  padding: 30px;
  background: #fff;
  border: 1px solid #ccc;
  text-decoration: none;
`;

export const CenteredContainer: React.FunctionComponent<{}> = ({ children }) => (
  <>
    <div className="background" />
    <div className="page-container" style={{ paddingTop: 130, fontFamily: 'Manrope' }}>
      {children}
      <PublicFooter />
    </div>
  </>
);

export const ClubPageContainer = styled.div`
  min-height: 100vh;
  position: relative;
  background: ${({ theme }) => theme.bgColor};
  font-family: Manrope;
`;

export const ErrorMessage = styled.div`
  padding: 10px 20px;
  margin-bottom: 15px;
  background-color: #fae9ea;
  border-color: #cc323f;
  color: rgba(0, 0, 0, 0.7);
`;

export const InfoRow = styled.div`
  display: block;
  margin-top: 15px;
  & label {
    opacity: 0.6;
  }
`;

export const ClickableItem = styled.div<{ disabled?: boolean }>`
  font-weight: 500;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.45 : 1)};
  &:hover {
    opacity: 0.5;
  }
`;

export const CharInContainer: React.FC<{ char: string | number; style?: CSSProperties }> = ({
  char,
  style,
}) => {
  return char ? (
    <span
      style={{
        display: 'flex',
        fontSize: 11,
        fontWeight: 600,
        background: '#F5F3F2',
        borderRadius: '6px',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'black',
        minWidth: 20,
        padding: '2px 4px',
        ...style,
      }}
    >
      {char}
    </span>
  ) : (
    <></>
  );
};

export const FadeInContainer = styled.div`
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
`;

export const MessageContainer = styled(FadeInContainer)`
  background: ${hexWithOpacity(WARNING_TINT, 0.1)};
  color: ${WARNING_TINT};
  padding: 5px 12px 8px 12px;
  font-weight: 500;
  min-height: 30px;
  line-height: 1.5;
`;

export const CondensedText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
`;
