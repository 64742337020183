import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React from 'react';
import { makeRequest } from '../Resource';
import { AddressForm, EmptyAddressValues } from '../components/AddressForm';
import { Button } from '../components/Button';
import { ErrorMessage, Form } from '../components/Common';
import { JoinThankYou } from './JoinThankYou';
import { Checkbox } from '@blueprintjs/core';

export const JoinForm: React.FunctionComponent<{
  club: CoreAPI.Club;
  tier: CoreAPI.Club['tiers'][0];
  couponCode: string | null;
  onJoined: () => void;
}> = ({ club, tier, couponCode, onJoined }) => {
  const [address, setAddress] = React.useState<CoreAPI.Address>(EmptyAddressValues);
  const [error, setError] = React.useState<string | undefined>();
  const [state, setState] = React.useState<'none' | 'loading' | 'thank-you'>('none');
  const [acceptedClubTerms, setClubTerms] = React.useState<boolean>(!club.config?.termsURL);
  const [acceptedClubPrivacy, setClubPrivacy] = React.useState<boolean>(!club.config?.privacyURL);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<any>) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (!acceptedClubTerms) {
      setError('You must accept the club terms of use.');
      return;
    }

    if (!acceptedClubPrivacy) {
      setError('You must accept the club privacy policy.');
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }
    setState('loading');
    setError(undefined);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setError(error.message);
      setState('none');
    } else if (paymentMethod) {
      const payload: CoreAPI.JoinClubPayload = {
        shipping: address,
        stripeToken: paymentMethod.id,
        stripeCouponCode: couponCode,
        tier: tier.key,
      };
      const resp = await makeRequest<{ error?: string }>(
        `/api/v1/clubs/${club.slug}/join`,
        'POST',
        payload
      );

      if (resp.error) {
        setError(resp.error);
      } else {
        setState('thank-you');
        if (club.config?.pixelId)
          (window as any).fbq('track', 'Purchase', { currency: 'USD', value: tier.price });
        setTimeout(() => {
          onJoined();
        }, 2000);
      }
    }
  };

  return (
    <>
      {state === 'thank-you' && <JoinThankYou />}
      <Form onSubmit={handleSubmit} className={state}>
        <h2>Join the Crew</h2>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <p>
          Your membership may include exclusive merch - enter your mailing address below so{' '}
          {club.name} knows where to reach you!
        </p>
        <AddressForm value={address} onChange={setAddress} />
        <hr />
        {!tier.price && (
          <p style={{ fontStyle: 'italic', fontSize: 13 }}>
            Please enter a valid credit card for verification. Your credit card will not be charged.
          </p>
        )}
        <div style={{ background: '#f3f3f3', borderBottom: '1px solid #ddd', padding: '7px 6px' }}>
          <CardElement
            options={{
              style: {
                base: {
                  iconColor: '#111',
                  color: '#111',
                  fontWeight: '400',
                  fontFamily: "'Open Sans', sans-serif",
                  fontSize: '16px',
                  fontSmoothing: 'antialiased',
                  '::placeholder': {
                    color: '#999',
                  },
                  ':-webkit-autofill': {
                    color: '#999',
                  },
                },
                invalid: {
                  iconColor: 'rgb(226, 62, 76)',
                  color: 'rgb(226, 62, 76)',
                },
              },
            }}
          />
        </div>
        <div style={{ height: 20 }} />
        {club.config?.termsURL && (
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <Checkbox
              checked={acceptedClubTerms}
              onChange={e => setClubTerms(e.currentTarget.checked)}
            />
            <div>
              I agree to this club's{' '}
              <a href={club.config.termsURL} target="_blank" rel="noreferrer">
                terms of use
              </a>
              .
            </div>
          </div>
        )}
        {club.config?.privacyURL && (
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <Checkbox
              checked={acceptedClubPrivacy}
              onChange={e => setClubPrivacy(e.currentTarget.checked)}
            />
            <div>
              I agree to this club's{' '}
              <a href={club.config.privacyURL} target="_blank" rel="noreferrer">
                privacy policy
              </a>
              .
            </div>
          </div>
        )}
        <Button
          intent="primary"
          onClick={handleSubmit}
          disabled={!stripe || state === 'loading'}
          style={{ width: '100%' }}
        >
          {state === 'loading' ? `Processing...` : `Join ${club.name}`}
        </Button>
      </Form>
    </>
  );
};
