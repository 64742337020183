import { HTMLSelect } from '@blueprintjs/core';
import moment from 'moment';
import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ClubContext } from '../App';
import { hasOnlyFreeTier } from '../components/JoinModal';
import { useResource } from '../Resource';
import { CardActivity } from './cards/CardActivity';
import { CardSetFinance, CardSetFinanceMonthlyTable } from './cards/CardSetFinance';
import { CardSetGeographies } from './cards/CardSetGeographies';
import { CardTierBreakdown } from './cards/CardTierBreakdown';
import { generateFakeStats } from './generateFakeStats';
import { PageContainer } from './PageHome';

export type DashboardDateRange = [number | null, number | null]; // unix

export const DAY_SEC = 24 * 60 * 60;

export const PageClubDashboard: React.FunctionComponent<RouteComponentProps> = props => {
  const club = useContext(ClubContext);
  let [stats] = useResource<CoreAPI.AdminClubStatsResponse>(`/api/admin/v1/clubs/${club.id}/stats`);
  const fanCreatedClub = hasOnlyFreeTier(club);

  if (props.location.search.includes('s=true')) {
    stats = generateFakeStats(
      club.id,
      club.tiers.map(t => t.key),
      props.location.search.includes('large') ? 25 : 5
    );
  }

  const now = moment().endOf('day').unix();
  const [range, setRange] = React.useState<'week' | 'month' | 'quarter' | 'year'>('month');
  const dateRange: DashboardDateRange =
    range === 'week'
      ? [now - DAY_SEC * 7, now]
      : range === 'month'
      ? [now - DAY_SEC * 30, now]
      : range === 'quarter'
      ? [now - DAY_SEC * 90, now]
      : [now - DAY_SEC * 365, now];

  return (
    <PageContainer>
      {fanCreatedClub ? (
        <>
          <h3>Activity</h3>
          <CardActivity data={stats} dateRange={dateRange} />
        </>
      ) : (
        <>
          <div style={{ display: 'flex' }}>
            <h2 className="bp3-heading">Dashboard</h2>
            <div style={{ flex: 1 }} />
            <HTMLSelect value={range} onChange={e => setRange(e.target.value as any)}>
              <option value="week">Past week</option>
              <option value="month">Past month</option>
              <option value="quarter">Past quarter</option>
              <option value="year">Past year</option>
            </HTMLSelect>
          </div>
          <CardSetFinance data={stats} key={range} dateRange={dateRange} />
          <h3>Geographies</h3>
          <CardSetGeographies data={stats} />
          <div style={{ display: 'flex', gap: 20, marginTop: 20 }}>
            <div style={{ flex: 1 }}>
              <h3>Membership Tiers</h3>
              <CardTierBreakdown data={stats} />
            </div>
            <div style={{ flex: 1 }}>
              <h3>Activity</h3>
              <CardActivity data={stats} dateRange={dateRange} />
            </div>
          </div>
          <h3>Monthly Summary</h3>
          <CardSetFinanceMonthlyTable data={stats} />
        </>
      )}
    </PageContainer>
  );
};
