import React, { useRef } from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { NativeBridge } from '../../NativeBridge';
import { Preview } from '../composer/PostComposer';
import { AudioPlayer } from '../embeds/Audio';
import { MuxVideo } from '../embeds/MuxVideo';

export const PostBodyBasic: React.FunctionComponent<{ details: CoreAPI.PostDetailsBasic }> = ({
  details,
}) => {
  const container = useRef<HTMLDivElement | null>(null);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        marginBottom: container.current?.hasChildNodes() ? 10 : 'unset',
      }}
      ref={container}
    >
      {!!details.previews?.length && <Preview previews={details.previews} />}
      {details.audio && <AudioPlayer audio={details.audio} />}
      {!!details.images?.length && (
        <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
          {details.images.map(i =>
            NativeBridge.isAvailable() ? (
              <img
                key={i.url}
                src={i.url}
                alt={i.alt}
                style={{ flex: 1, width: '1%' }}
                onClick={() =>
                  NativeBridge.viewImages(
                    [i, ...details.images.filter(o => o !== i)].map(i => i.url)
                  )
                }
              />
            ) : (
              <Zoom
                key={i.url}
                zoomMargin={24}
                wrapStyle={{ flex: 1, position: 'relative', display: 'flex' }}
              >
                <img src={i.url} alt={i.alt} style={{ width: '100%', flex: 1 }} />
              </Zoom>
            )
          )}
        </div>
      )}
      {details.video && (
        <MuxVideo autoPlay={false} aspectClassName="vjs-16-9 vjs-fluid" {...details.video} />
      )}
    </div>
  );
};
