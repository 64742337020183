import React, { useRef } from 'react';
import { XButton } from './PostComposer';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { DragItem } from '../../pages-dashboard/settings/ChannelEditor';
import type { Identifier, XYCoord } from 'dnd-core';

const IMAGE_ITEM_TYPE = 'image';

export const PostImages = ({
  images,
  onRemove,
  onReorder,
}: {
  images: {
    url: string;
    alt: string;
  }[];
  onRemove: (url: string) => void;
  onReorder: (images: { url: string; alt: string }[]) => void;
}) => {
  return (
    <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
      {images.map(({ url }, idx) => (
        <ImageContainer
          key={`image-${idx}-${url}`}
          idx={idx}
          url={url}
          onRemove={() => onRemove(url)}
          images={images}
          onChange={images => onReorder(images)}
        />
      ))}
    </DndProvider>
  );
};

export const ImageContainer = ({
  key,
  idx,
  url,
  onRemove,
  images,
  onChange,
}: {
  key: string;
  idx: number;
  url: string;
  onRemove: () => void;
  images: { url: string; alt: string }[];
  onChange: (images: { url: string; alt: string }[]) => void;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ isDragging }, drag] = useDrag({
    type: IMAGE_ITEM_TYPE,
    item: () => ({ key, idx }),
    collect: monitor => ({ isDragging: monitor.isDragging() }),
  });

  const moveImage = (dragIndex: number, hoverIndex: number) => {
    const update = [...images];
    update.splice(dragIndex, 1);
    update.splice(hoverIndex, 0, images[dragIndex]);
    onChange(update);
  };

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: IMAGE_ITEM_TYPE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.idx;
      const hoverIndex = idx;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveImage(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.idx = hoverIndex;
    },
  });

  drag(drop(ref));

  return (
    <div
      data-handler-id={handlerId}
      ref={ref}
      style={{ width: '47%', opacity: isDragging ? 0.5 : 1, cursor: 'move' }}
    >
      <XButton
        onClick={() => {
          onRemove();
        }}
        style={{ position: 'absolute', margin: 8 }}
      />
      <img src={url} alt={`Post`} style={{ width: '100%', objectFit: 'contain' }} />
    </div>
  );
};
