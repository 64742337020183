import { Spinner } from '@blueprintjs/core';
import React, { useState } from 'react';
import { AudioPlayer } from '../embeds/Audio';
import { AudioIcon } from '../Icons';
import { PostActionIcon } from './MemberPostComposer';
import { getFormData } from './PostComposer';

export const useAudioUploader = (
  isAdmin: boolean,
  details: CoreAPI.PostDetails,
  setDetails: (details: CoreAPI.PostDetails) => void
) => {
  const [fileInputKey, setFileInputKey] = useState<number>(0);
  const [isLoadingAudio, setLoadingAudio] = useState(false);

  const onUploadAudio = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const formData = getFormData(e, setFileInputKey);
    if (formData) {
      setLoadingAudio(true);
      const resp = await fetch(`/api/audio`, {
        method: 'POST',
        credentials: 'include',
        body: formData,
      });
      setLoadingAudio(false);

      if (resp.status === 200) {
        const { url, mimetype } = await resp.json();
        setDetails({
          ...details,
          type: 'text',
          images: 'images' in details ? details.images : [],
          audio: { url, mimetype, title: '' },
        });
      } else {
        alert('Upload failed, please try again.');
        setFileInputKey(k => k + 1);
      }
    }
  };

  const addAudioButton = (
    <label htmlFor="special-audio">
      <PostActionIcon src={AudioIcon} alt="Add audio">
        <input
          key={`attachment-input-${fileInputKey}`}
          onChange={onUploadAudio}
          disabled={details.type === 'poll'}
          accept=".mp3,.wav,.ogg"
          id="special-audio"
          style={{ display: 'none' }}
          type="file"
        />
      </PostActionIcon>
    </label>
  );

  const postAudioComposer = isLoadingAudio ? (
    <Spinner size={24} />
  ) : (
    details.type === 'text' &&
    details.audio && (
      <AudioPlayer
        audio={details.audio}
        onChangeTitle={title => setDetails({ ...details, audio: { ...details.audio!, title } })}
      />
    )
  );

  return {
    postAudioComposer: isAdmin ? postAudioComposer : undefined,
    addAudioButton: isAdmin ? addAudioButton : undefined,
  };
};
