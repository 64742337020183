import React from 'react';
import { Redirect, Route, RouteChildrenProps, Switch } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { ClubContext } from '../App';
import { PublicNav } from '../pages/PublicNav';
import { useResource } from '../Resource';
import { ClubPage } from './ClubPage';
import { JoinPage } from './JoinPage';

export const ClubRoot: React.FunctionComponent<
  RouteChildrenProps<{
    slug: string;
  }>
> = props => {
  const slug = props.match?.params.slug;
  const [club] = useResource<CoreAPI.ClubWithMembership | { error: string }>(
    `/api/v1/clubs/${slug}`
  );
  if (!club) {
    return <div />;
  }
  if ('error' in club) {
    return <div>{club.error}</div>;
  }

  return (
    <ThemeProvider theme={club.config}>
      <ClubContext.Provider value={club}>
        <Switch>
          <Route
            path="/:slug/mobile"
            render={({ location }) => (
              <Redirect to={`${location.pathname.replace('/mobile', '')}${location.search}`} />
            )}
          />
          <Route
            render={() => (
              <>
                <PublicNav />
                <Switch>
                  <Route path="/:slug/join/:tier" component={JoinPage} />
                  <Route path="/:slug/:page?/:channelSlug?" component={ClubPage} />
                </Switch>
              </>
            )}
          />
        </Switch>
      </ClubContext.Provider>
    </ThemeProvider>
  );
};
