import React from 'react';
import qs from 'query-string';
import { Redirect } from 'react-router-dom';

import { SessionContext } from '../App';
import { CenteredMaxWidth, WhiteShadowedBox, CenteredContainer } from '../components/Common';
import { PublicNav } from './PublicNav';
import { Location } from 'history';

export const AuthPageContainer: React.FunctionComponent<{ location: Location; width?: number }> = ({
  children,
  location,
  width = 400,
}) => {
  const [session] = React.useContext(SessionContext);
  if (session.user) {
    const next = (qs.parse(location.search)['next'] as string) || '/';
    return <Redirect to={next} />;
  }

  return (
    <>
      <PublicNav />
      <CenteredContainer>
        <CenteredMaxWidth style={{ width: width }}>
          <WhiteShadowedBox>{children}</WhiteShadowedBox>
        </CenteredMaxWidth>
      </CenteredContainer>
    </>
  );
};
