import React from 'react';
import { useHistory } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { SessionContext } from '../App';
import { formatPrice } from '../Utils';
import { Button } from './Button';
import { SheetModal } from './Common';
import { useScrollToItem } from '../Hooks';

export function hasOnlyFreeTier(club: CoreAPI.Club) {
  return club.tiers.length === 1 && club.tiers[0].price === 0;
}

export const JoinModal: React.FunctionComponent<{
  club: CoreAPI.ClubWithMembership;
  currentTierKey?: string;
  currentTierLocked?: boolean;
  onSwitchTiers?: (tierKey: string) => void;
  visible: boolean;
}> = ({ visible, club, currentTierKey, currentTierLocked, onSwitchTiers }) => {
  const [session] = React.useContext(SessionContext);
  const location = useLocation();
  const history = useHistory();

  const joinTierLink = (t: CoreAPI.ClubMembershipTier) => {
    const next = `/${club.slug}/join/${t.key}${location.search}`;
    return session.user
      ? next
      : `/register${location.search}${location.search.length ? '&' : '?'}next=${encodeURIComponent(
          next
        )}`;
  };

  // Immediately direct away from the modal if there's only a free tier
  React.useEffect(() => {
    if (visible && hasOnlyFreeTier(club) && !currentTierKey) {
      history.push(joinTierLink(club.tiers[0]));
    }
  });

  const ref = useScrollToItem(visible);

  return (
    <SheetModal style={{ textAlign: 'center' }} onClick={e => e.stopPropagation()}>
      {currentTierKey ? (
        <h2>Membership Tiers</h2>
      ) : (
        <>
          <h2 style={{ marginBottom: 0 }}>Join {club.name}</h2>
          <ChooseTierH4>Choose a Membership Tier</ChooseTierH4>
        </>
      )}
      <Tiers>
        {club.tiers
          .sort((a, b) => a.price - b.price)
          .map((t, idx) => (
            <TierWrap key={t.key} ref={idx === 1 ? ref : undefined}>
              <Tier selected={idx === 1}>
                {idx === 1 && <MostPopularTag>Most Popular</MostPopularTag>}
                {t.imageURL && (
                  <img src={t.imageURL} style={{ width: '100%', maxWidth: 500 }} alt="tier" />
                )}
                <strong style={{ paddingTop: 20, display: 'block' }}>{t.name}</strong>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    padding: '10px 20px',
                  }}
                >
                  <div style={{ fontSize: '1.3rem', fontWeight: 600 }}>{formatPrice(t.price)}</div>
                  <div style={{ fontSize: '0.9rem', fontWeight: 600, opacity: 0.5 }}>
                    PER {club.config.earnings?.billingCycle === 'monthly' ? 'MONTH' : 'YEAR'}
                  </div>
                  <div style={{ height: 40, marginTop: 10, marginBottom: 10 }}>
                    {currentTierKey ? (
                      t.key === currentTierKey ? (
                        <div
                          style={{
                            background: 'rgba(0,0,0,0.1)',
                            textAlign: 'center',
                            padding: '12px 20px',
                            fontWeight: 600,
                          }}
                        >
                          Your Current Tier
                        </div>
                      ) : !currentTierLocked ? (
                        <Button intent="primary" onClick={() => onSwitchTiers?.(t.key)}>
                          Switch Tiers
                        </Button>
                      ) : (
                        <></>
                      )
                    ) : (
                      <Link to={joinTierLink(t)} style={{ padding: '10px 0' }}>
                        <Button intent="primary">{session.user ? 'Join' : 'Sign Up'}</Button>
                      </Link>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    whiteSpace: 'pre-wrap',
                    textAlign: 'left',
                    padding: '10px 20px',
                    maxHeight: '30vh',
                    overflowY: 'auto',
                    borderTop: '1px solid #ccc',
                  }}
                  dangerouslySetInnerHTML={{ __html: t.description }}
                />
                <div
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: 25,
                    background:
                      'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
                  }}
                />
              </Tier>
            </TierWrap>
          ))}
      </Tiers>
    </SheetModal>
  );
};

const MostPopularTag = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  border-bottom-left-radius: 5px;
  padding: 3px 6px;
  background: ${({ theme }) => theme.tintColor};
  color: white;
  text-transform: uppercase;
`;

const Tiers = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 10px;
  @media (max-width: 500px) {
    margin-left: -10px;
    margin-right: -10px;
  }
`;

const TierWrap = styled.div`
  position: relative;
  flex: 1;
  margin: 10px;
  font-size: 0.9rem;
  scroll-snap-align: center;
  @media (max-width: 500px) {
    margin: 5px;

    &:first-child {
      padding-left: calc((100% - 300px) / 2);
    }
    &:last-child {
      padding-right: calc((100% - 300px) / 2);
    }
    box-sizing: content-box;
    width: 270px;
    min-width: 270px;
  }
`;

const Tier = styled.div<{ selected: boolean }>`
  width: 100%;
  border: 1px solid ${({ selected, theme }) => (selected ? theme.tintColor : '#ccc')};
  box-shadow: ${({ selected, theme }) => (selected ? `0 0 2px ${theme.tintColor}` : 'none')};

  & ul {
    margin: 0;
    padding-left: 13px;
    white-space: normal;
  }
  & li {
    margin-bottom: 10px;
    white-space: pre-line;
  }
`;

const ChooseTierH4 = styled.h4`
  margin-top: 0;
  opacity: 0.6;
  @media (max-width: 500px) {
    display: none;
  }
`;
