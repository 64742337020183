import React from 'react';

export const ProfilePicture: React.FunctionComponent<{
  user: Pick<CoreAPI.User, 'profilePictureURL' | 'username'>;
  size: number;
  onClick?: () => void;
}> = ({ user, size, onClick }) => {
  if (user.profilePictureURL) {
    return (
      <img
        onClick={onClick}
        alt=""
        role="presentation"
        src={user.profilePictureURL}
        style={{ width: size, height: size, borderRadius: size / 2 }}
      />
    );
  }

  const seed = user.username
    .split('')
    .map(c => c.charCodeAt(0))
    .reduce((a, b) => a + b, 0);
  const color = `hsl(${seed}, 50%, 70%)`;

  return (
    <div
      onClick={onClick}
      style={{
        width: size,
        minWidth: size,
        height: size,
        borderRadius: size / 2,
        fontSize: size / 2,
        fontWeight: 700,
        background: color,
        display: 'inline-flex',
        letterSpacing: 1.2,
        alignItems: 'center',
        justifyContent: 'center',
        userSelect: 'none',
      }}
    >
      {user.username.slice(0, 2).toUpperCase()}
    </div>
  );
};
