import { PieDatumWithColor, ResponsivePie } from '@nivo/pie';
import React, { useContext } from 'react';
import { ClubContext } from '../../App';
import { BrandAlphaSteps } from '../../Colors';
import { DashboardBox } from '../../components/Common';

export const CardTierBreakdown: React.FunctionComponent<{
  data: CoreAPI.AdminClubStatsResponse | undefined;
}> = ({ data }) => {
  const club = useContext(ClubContext);

  if (!data) {
    return <DashboardBox style={{ flex: 1, height: 400 }} />;
  }

  // Display the current tiers with colors in price order
  let segments: PieDatumWithColor[] = [];
  const currentTierKeys = club.tiers.map(t => t.key);

  club.tiers.forEach((t, idx) => {
    segments.push({
      id: t.key,
      name: t.name,
      value: data.breakdown.tier[t.key] || 0,
      color: BrandAlphaSteps[15 - (club.tiers.length - idx) * 3],
    });
  });

  // Display unknown / old tiers with the lightest tint color
  Object.entries(data.breakdown.tier)
    .filter(d => !currentTierKeys.includes(d[0]))
    .forEach(d => {
      segments.push({ id: d[0], name: d[0], value: d[1], color: BrandAlphaSteps[0] });
    });

  // More gracefully handle 0+0+0+0 case, pie needs at least one non-zero segment
  if (segments.every(s => s.value === 0)) {
    segments = [{ id: 'None', name: 'None', value: 1, color: BrandAlphaSteps[0] }];
  }

  return (
    <DashboardBox style={{ flex: 1, height: 400 }}>
      <ResponsivePie
        data={segments}
        colors={d => (d as PieDatumWithColor).color}
        margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={6}
        radialLabelsTextColor="#333333"
        radialLabelsLinkOffset={0}
        radialLabel={'name'}
        tooltip={d => <span>{d.value} members</span>}
        radialLabelsLinkDiagonalLength={16}
        radialLabelsLinkHorizontalLength={24}
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkColor={{ from: 'color' }}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#333333"
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
      />
    </DashboardBox>
  );
};
