import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ClubContext, SessionContext } from '../App';
import { NativeBridge } from '../NativeBridge';

export const PublicNav: React.FunctionComponent = () => {
  const [session] = React.useContext(SessionContext);
  const club = React.useContext(ClubContext);
  const location = useLocation();

  if (NativeBridge.isAvailable()) {
    return <span />;
  }

  const hasAdminAccess = session.user
    ? session.user.role === 'admin' ||
      session.clubs.find(
        c => c.membership?.permissions === 'owner' || c.membership?.permissions === 'moderator'
      )
    : false;

  return (
    <NavContainer>
      {club.config?.heroNavLogoURL ? (
        <a className="item logo-custom" href={`/${club.slug}`} style={{ position: 'relative' }}>
          <img alt="logo" src={club.config.heroNavLogoURL} style={{ maxWidth: 150 }} />
        </a>
      ) : (
        <a className="item logo-wtb" href="/" style={{ position: 'relative' }}>
          <div className="logo logo-wtb inverse" />
          <div className="logo logo-wtb" />
        </a>
      )}
      <div className="spacer"></div>
      {session.user ? (
        <ul>
          {hasAdminAccess ? (
            <Link to={`/admin`} className="item">
              <li>ADMIN</li>
            </Link>
          ) : (
            <></>
          )}
          <Link to={`/explore`} className="item">
            <li>EXPLORE</li>
          </Link>
          {!!session.clubs.length && (
            <div className="item">
              <li>CREWS</li>
              <ul className="submenu">
                {session.clubs.map(c => (
                  <Link to={`/${c.slug}`} key={c.id}>
                    <li>{c.name}</li>
                  </Link>
                ))}
              </ul>
            </div>
          )}

          <div className="item" style={{ padding: 5, borderRadius: 25, height: 45, marginLeft: 5 }}>
            <li>
              <img
                alt="profile"
                style={{ borderRadius: 20, width: 40, height: 40 }}
                src={session.user.profilePictureURL || '/profile-empty.png'}
              />
            </li>
            <ul className="submenu">
              {hasAdminAccess ? (
                <Link to={`/admin`}>
                  <li>Admin</li>
                </Link>
              ) : undefined}
              <Link to={`/explore`}>
                <li>Explore</li>
              </Link>
              <Link to={`/manage`}>
                <li>Account Settings</li>
              </Link>
              <a href={`https://www.withtheband.co/faqs`} target="_blank" rel="noreferrer">
                <li>FAQs</li>
              </a>
              <hr />
              <Link to={`/logout`} className="item">
                <li>Sign Out</li>
              </Link>
            </ul>
          </div>
        </ul>
      ) : (
        <ul>
          <Link to={`/login?next=${location.pathname}`} className="item">
            <li>SIGN IN</li>
          </Link>
        </ul>
      )}
    </NavContainer>
  );
};

const NavContainer = styled.nav`
  font-family: 'Rajdhani', sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  letter-spacing: 1px;
  align-items: flex-start;
  padding-top: 35px;
  padding-left: 45px;
  padding-right: 45px;
  padding-bottom: 20px;
  width: 100%;
  position: absolute;
  box-sizing: border-box;
  z-index: 3;

  & .logo-wtb {
    width: 239px;
    height: 37px;
  }
  & .logo {
    mask-image: url(/public/images/logo-white.png);
    mask-size: contain;
    mask-repeat: no-repeat;
    background: ${({ theme }) => theme.heroNavColor};
    position: absolute;
    top: 0;
    left: 0;
  }
  & .logo.inverse {
    background: ${({ theme }) => theme.tintColor};
    top: 1.5px;
    left: 1.5px;
  }
  & .spacer {
    flex: 1;
  }

  & > ul {
    padding: 0;
    margin-top: -15px;
    flex-shrink: 0;
  }
  & > ul > .item {
    color: ${({ theme }) => theme.heroNavColor};
    text-shadow: 1.5px 1.5px 0 ${({ theme }) => theme.tintColor};
    list-style-type: none;
    margin: 0;
    padding: 12px 10px 6px;
    display: inline-block;
    text-decoration: none;
    position: relative;
    vertical-align: middle;
  }
  & ul a {
    text-decoration: none;
  }
  & .item ul.submenu {
    display: none;
    position: absolute;
    padding-left: 0;
    background: rgba(255, 255, 255, 0.8);
    right: 0;
    top: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    text-shadow: none;
  }
  & .item ul.submenu hr {
    border: none;
    border-top: 1px solid #ccc;
    padding: 0;
    margin: 0;
  }

  & .item ul.submenu li {
    color: #111;
    white-space: nowrap;
    text-transform: none;
    font-size: 15px;
    padding: 10px;
    display: block;
  }
  & .item ul.submenu li:hover {
    background: rgba(255, 255, 255, 0.8);
  }

  & ul .item:hover {
    background: rgba(120, 120, 120, 0.15);
    text-decoration: none;
    color: ${({ theme }) => theme.heroNavColor};
  }
  & ul .item:hover ul.submenu {
    display: block;
  }

  @media (max-width: 600px) {
    padding: 25px;
    padding-right: 5px;

    & > ul > .item {
      padding: 5px 10px 6px;
      :not(:nth-last-child(-n + 2)) {
        display: none;
      }
    }

    & .logo-wtb,
    & .logo-custom {
      max-width: 65%;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        transition: opacity 550ms ease-out;
      }
    }
    & .spacer {
      display: block;
      min-height: 10px;
    }
  }
`;
