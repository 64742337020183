import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { ClubContext, SessionContext } from '../../App';
import { NativeBridge } from '../../NativeBridge';
import { formatPrice } from '../../Utils';
import { Button } from '../Button';
import { hasOnlyFreeTier } from '../JoinModal';

export const PostPaywall: React.FunctionComponent<{
  audience: CoreAPI.PostAudience;
}> = ({ audience, children }) => {
  const [session] = React.useContext(SessionContext);
  const history = useHistory();
  const club = React.useContext(ClubContext);
  let msg: React.ReactNode = null;
  const { sessionTierKey, isLapsed } = checkMembership(club);
  const billingCycle = club.config.earnings?.billingCycle || '';
  const fanCreatedClub = hasOnlyFreeTier(club);

  if (
    session.user?.role === 'admin' ||
    club.membership?.permissions === 'moderator' ||
    club.membership?.permissions === 'owner'
  )
    return <>{children}</>;

  if (!sessionTierKey || isLapsed) {
    msg = (
      <div style={{ textAlign: 'center', maxWidth: 400 }}>
        <div style={{ paddingBottom: 10 }}>
          {isLapsed ? `Your membership has lapsed. ` : ''}
          Unlock this post by joining <strong>{club.name}</strong> on With the Band
        </div>
        <Button
          onClick={() =>
            NativeBridge.isAvailable() && !fanCreatedClub
              ? NativeBridge.viewMembershipOptions()
              : history.push(`/${club.slug}/join`)
          }
        >
          {club.tiers.some(t => !t.price)
            ? 'Join Now'
            : `Join now for ${formatPrice(club.tiers[0].price)} / ${billingCycle.slice(0, -2)}`}
        </Button>
      </div>
    );
  } else if (audience.onlyTiers?.length && !audience.onlyTiers.includes(sessionTierKey)) {
    const nameFor = (key: string) => club.tiers.find(t => t.key === key)?.name || key;
    msg = `This post is exclusive to ${audience.onlyTiers.map(nameFor)} members`;
  }

  if (!msg) {
    return <>{children}</>;
  }

  return (
    <PostPaywallContainer>
      <PostPaywallUnderlying>{children}</PostPaywallUnderlying>
      <PostPaywallOverlay>{msg}</PostPaywallOverlay>
    </PostPaywallContainer>
  );
};

export const checkMembership = (club: CoreAPI.ClubWithMembership) => {
  const sessionTierKey = club.membership?.tier;
  const isLapsed =
    club.membership?.billingPeriodEnd && club.membership.billingPeriodEnd < Date.now();
  return { isActive: sessionTierKey && !isLapsed, isLapsed, sessionTierKey };
};

const PostPaywallContainer = styled.div`
  position: relative;
  min-height: 170px;
`;

const PostPaywallUnderlying = styled.div`
  z-index: 1;
  background: #eee;
  filter: blur(10px);
  opacity: 0.4;
`;

const PostPaywallOverlay = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
