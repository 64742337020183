import moment from 'moment-timezone';
import React from 'react';
import styled from 'styled-components/macro';
import { NativeBridge } from '../../NativeBridge';
import { useResource } from '../../Resource';
import { Button } from '../Button';

export const PostBodyLivestream: React.FunctionComponent<{
  details: CoreAPI.PostDetailsZoom;
  post: CoreAPI.Post;
  club: CoreAPI.Club;
}> = ({ details, club, post }) => {
  const [state, stateOps] = useResource<CoreAPI.ZoomState>(
    `/api/v1/clubs/${club.id}/posts/${post.id}/zoomstate`
  );

  React.useEffect(() => {
    const timer = setTimeout(() => stateOps.refresh(), 30000);
    return () => clearTimeout(timer);
  });

  if (!state) {
    return <LivestreamBox club={club} />;
  }

  if ('error' in state) {
    return <div>{`Zoom error: ${state.error}.`}</div>;
  }

  const start = moment(details.start).format(`MMMM DD YYYY [at] h:mma`);

  if (state.end_time) {
    return (
      <LivestreamBox club={club}>
        <strong style={{ color: club.config.tintColor }}>{club.name} Live Stream!</strong>
        <div style={{ opacity: 0.6 }}>{`${state.participants_count} fan${
          state.participants_count > 1 ? 's' : ''
        } joined - ended ${moment(state.end_time).format(`MMMM DD YYYY [at] h:mma`)}`}</div>
      </LivestreamBox>
    );
  }
  if (state.start_url) {
    return (
      <LivestreamBox club={club}>
        <strong style={{ color: club.config.tintColor }}>{club.name} Live Stream!</strong>
        <div style={{ opacity: 0.6 }}>{`Scheduled for ${start}.`}</div>
        <div>
          Fans in the waiting room will automatically enter the call when you join. When you're
          finished, end the Zoom call and all participants will be disconnected.
        </div>
        <div style={{ display: 'flex', marginTop: 10 }}>
          <a href={state.start_url} target="_blank" rel="noopener noreferrer">
            <Button size="small">Join as Artist</Button>
          </a>
          <div style={{ width: 10 }} />
          <a href={state.join_url} target="_blank" rel="noopener noreferrer">
            <Button size="small">Join as Guest</Button>
          </a>
          <div style={{ flex: 1 }} />
        </div>
      </LivestreamBox>
    );
  }

  return (
    <div
      style={{ textDecoration: 'none' }}
      onClick={e => {
        if (NativeBridge.isAvailable()) {
          NativeBridge.joinZoomCall(state.join_url);
          e.preventDefault();
        } else {
          window.open(state.join_url, '_blank');
        }
      }}
    >
      <LivestreamBox club={club} clickable>
        <strong style={{ color: club.config.tintColor }}>{club.name} Live Stream</strong>
        <div>
          {state.status === 'started'
            ? `The artist has joined! Tap to enter the livestream.`
            : `Tap here to join on ${start}`}
        </div>
      </LivestreamBox>
    </div>
  );
};

const LivestreamIcon = styled.div`
  width: 49px;
  height: 57px;
  margin-right: 15px;
  mask-image: url(${require('../../images/livestream.png').default});
  mask-size: contain;
  mask-repeat: no-repeat;
  -webkit-mask-image: url(${require('../../images/livestream.png').default});
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  background: ${({ theme }) => theme.tintColor};

  @media (prefers-color-scheme: dark) {
    background: white;
  }
`;

const LivestreamBox: React.FunctionComponent<{
  club: CoreAPI.Club;
  clickable?: boolean;
}> = props => (
  <LivestreamContainer clickable={props.clickable}>
    <div style={{ display: 'flex', margin: 'auto', alignItems: 'center' }}>
      <LivestreamIcon />
      <div style={{ flex: 1 }}>{props.children}</div>
    </div>
  </LivestreamContainer>
);

const LivestreamContainer = styled.div<{ clickable?: boolean }>`
  padding: 20px;
  color: black !important;
  display: block;
  min-height: 100px;
  margin-bottom: 5px;
  margin-top: 10px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.tintColor}11;
  border: 1px solid ${({ theme }) => theme.tintColor}55;

  ${({ clickable, theme }) =>
    clickable &&
    `&:hover {
        text-decoration: none;
        background: ${theme.tintColor}22;
        border: 1px solid ${theme.tintColor}77;
      }
    `}

  @media (prefers-color-scheme: dark) {
    color: white !important;
    strong {
      color: white !important;
    }
    .AdminRoot & {
      color: black !important;
    }
  }
`;
