import moment from 'moment-timezone';
import React, { FormEvent } from 'react';
import styled from 'styled-components/macro';
import { NativeBridge } from '../../NativeBridge';
import { Button } from '../Button';
import { DecoratedTextBlock, DecoratedTextInput } from '../composer/DecoratedText';
import { ProfilePicture } from '../ProfilePicture';
import { MoreActions, MoreActionsDropdown } from './MoreActionsDropdown';

interface CommentProps {
  poster: CoreAPI.User | null;
  indent: number;
  response: CoreAPI.PostResponse;
  onEdit: (c: CoreAPI.UpsertPostResponsePayload) => Promise<{ error?: string }>;
  onReply?: () => void;
  onDelete: () => void;
  adminOptions: boolean;
}

export const Comment: React.FunctionComponent<CommentProps> = ({
  poster,
  indent,
  response,
  onEdit,
  onReply,
  onDelete,
  adminOptions,
}) => {
  const [editing, setEditing] = React.useState(false);
  const details = response.details as CoreAPI.PostResponseDetailsComment;
  const onClickUsername = () =>
    NativeBridge.isAvailable() ? NativeBridge.viewProfile(response.user.id) : undefined;

  return (
    <CommentContainer indent={indent}>
      <ProfilePicture user={response.user} size={25} onClick={onClickUsername} />
      {editing ? (
        <CommentForm
          initial={details}
          onSave={details => onEdit({ details, userId: response.userId })}
          onDone={() => setEditing(false)}
        />
      ) : (
        <>
          <div style={{ padding: '0 10px', userSelect: 'text', flex: 1 }}>
            <CommentUsername
              isMe={poster?.username === response.user.username || false}
              onClick={onClickUsername}
            >
              {response.user.username}
            </CommentUsername>
            <DecoratedTextBlock text={details.text} />
            <div style={{ fontSize: 11, opacity: 0.6 }}>
              {tinyTimeAgo(response.createdAt)}
              {poster?.id !== response.userId && onReply && (
                <div
                  tabIndex={0}
                  style={{
                    fontWeight: 500,
                    padding: '6px 10px',
                    margin: '-2px 0',
                    display: 'inline-block',
                    cursor: 'default',
                    userSelect: 'none',
                  }}
                  onClick={onReply}
                >
                  Reply
                </div>
              )}
            </div>
          </div>
          {(poster?.id === response.userId || adminOptions) && (
            <MoreActionsDropdown tabIndex={0}>
              <div className="title">&#8226;&#8226;&#8226;</div>
              <MoreActions>
                <li onClick={() => setEditing(true)}>Edit Comment</li>
                <li onClick={onDelete}>Delete Comment</li>
              </MoreActions>
            </MoreActionsDropdown>
          )}
        </>
      )}
    </CommentContainer>
  );
};

interface CommentFormProps {
  initial: CoreAPI.PostResponseDetailsComment;
  onSave: (details: CoreAPI.PostResponseDetailsComment) => Promise<{ error?: string }>;
  onDone?: () => void;
  autoFocus?: boolean;
}

export const CommentForm: React.FunctionComponent<CommentFormProps> = props => {
  const [text, setText] = React.useState(props.initial.text);
  const [saving, setSaving] = React.useState(false);

  const onSubmit = async (e: MouseEvent | FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (saving) {
      return;
    }
    if (text.length === 0) {
      return;
    }
    setSaving(true);
    try {
      const resp = await props.onSave({ ...props.initial, text });
      if (resp && 'error' in resp) {
        window.alert(resp.error);
      } else {
        setText('');
      }
    } catch (err) {
      window.alert('Sorry, your comment could not be saved, please try again.');
    }
    setSaving(false);
    props.onDone?.();
  };

  return (
    <form
      style={{
        padding: '3px 6px',
        paddingRight: 0,
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        opacity: saving ? 0.5 : 1,
      }}
      onSubmit={onSubmit}
    >
      <DecoratedTextInput
        key={`comment-${saving}`}
        initialValue={text}
        onChange={setText}
        placeholder="Write a comment..."
        style={{ fontSize: 12, marginTop: -4 }}
      />
      {!!text.length && (
        <div style={{ height: 30, display: 'flex', alignItems: 'center' }}>
          <Button size="small" style={{ marginLeft: 10 }} onMouseDown={onSubmit}>
            Post
          </Button>
        </div>
      )}
    </form>
  );
};

export const CommentContainer = styled.div<{ indent: number }>`
  display: flex;
  align-items: flex-start;
  padding: 4px 12px 6px ${({ indent }) => 10 + indent * 35}px;
`;

const CommentUsername = styled.div<{ isMe: boolean }>`
  font-weight: 600;
`;

function tinyTimeAgo(timestamp: number | Date | string) {
  return moment(timestamp)
    .fromNow()
    .replace(/^an /, '1 ')
    .replace(/^a /, '1 ')
    .replace(/ minutes? ago/, 'm')
    .replace(/ hours? ago/, 'h')
    .replace(/ days? ago/, 'd')
    .replace(/ weeks? ago/, 'w')
    .replace(/ months? ago/, 'mo')
    .replace(/ years? ago/, 'y');
}
