import { Button, Tooltip } from '@blueprintjs/core';
import { MultiSelectEnumInput, SingleLineInput } from '../../components/TextInputs';

export const RewardsEditor = ({
  rewards = [],
  updateRewards,
  tiers,
}: {
  rewards: CoreAPI.ClubConfig['rewards'];
  tiers: CoreAPI.Club['tiers'];
  updateRewards: (rewards: CoreAPI.ClubConfig['rewards']) => void;
}) => {
  const rewardsList = [...rewards];
  if (!rewardsList.find(r => !r.name.length))
    rewardsList.push({ rewardId: `new-${rewards.length}`, name: '', tiers: [] });

  const removeReward = (rewardId: string) => {
    updateRewards(rewards.filter(r => r.rewardId !== rewardId));
  };

  const tierOptions = tiers.map(t => ({ label: t.name, value: t.key }));

  return (
    <>
      {rewardsList.map((r, idx) => {
        const existing = rewards[idx];
        return (
          <div key={idx} style={{ display: 'flex', flex: 1, alignItems: 'center', gap: 10 }}>
            <div style={{ flex: 1 }}>
              <SingleLineInput
                key={r.rewardId}
                disabled={!r.rewardId.startsWith('new')}
                placeholder="Add a reward"
                value={r.name}
                style={{ marginBottom: 'unset' }}
                onChange={name => {
                  if (!existing) return updateRewards([...rewards, { ...r, name }]);

                  if (name) {
                    existing.name = name;
                    updateRewards(rewards);
                  } else {
                    removeReward(r.rewardId);
                  }
                }}
              />
            </div>
            {!!r.name ? (
              <>
                <MultiSelectEnumInput
                  placeholder="Select tiers"
                  values={tierOptions.filter(t => r.tiers.includes(t.value))}
                  options={tierOptions}
                  onChange={tiers => {
                    if (existing) {
                      existing.tiers = tiers.map(t => t.value);
                      updateRewards(rewards);
                    }
                  }}
                />
                <Tooltip
                  content={
                    r.rewardId.startsWith('new')
                      ? ''
                      : 'User tracking for this reward will not be available once this is deleted.'
                  }
                >
                  <Button icon="trash" onClick={() => removeReward(r.rewardId)} />
                </Tooltip>
              </>
            ) : (
              <div style={{ width: 30 }} />
            )}
          </div>
        );
      })}
    </>
  );
};
