import {
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  Icon,
  InputGroup,
  MenuItem,
  Popover,
  Spinner,
  Tooltip,
} from '@blueprintjs/core';
import { DatePicker } from '@blueprintjs/datetime';
import { Select } from '@blueprintjs/select';
import moment from 'moment';
import React from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components/macro';
import { Button as OurButton } from '../Button';
import {
  CharInContainer,
  ClickableItem,
  FeedItemBox,
  FeedItemContainer,
  hexWithOpacity,
} from '../Common';
import { TikTok } from '../embeds/TikTok';
import { Youtube } from '../embeds/Youtube';
import { PhotoIcon, VideoIcon } from '../Icons';
import { DecoratedTextInput } from './DecoratedText';
import { PostActionIcon } from './MemberPostComposer';
import { PostComposerMultimedia } from './PostComposerMultimedia';
import { PostPollComposer } from './PostPollComposer';
import { PostStreamComposer } from './PostStreamComposer';
import { useAudioUploader } from './useAudioUploader';
import { PostComposerProps, usePostComposerState } from './usePostComposerState';

export const PostComposer: React.FunctionComponent<PostComposerProps & { canTest: boolean }> =
  props => {
    const { club, canTest } = props;
    const {
      save,
      saving,
      values,
      setValues,
      setInputValue,
      setDetails,
      isLoading,
      shownPreviews,
      hidePreview,
    } = usePostComposerState(props);
    const location = useLocation();

    const attachmentsRef = React.createRef<any>();
    const { details, audience, notify } = values;

    const { postAudioComposer, addAudioButton } = useAudioUploader(
      props.isAdmin,
      details,
      setDetails
    );

    const setAudience = (audience: CoreAPI.PostAudience) => setValues({ ...values, audience });
    const setAudienceLocation = (text: string | undefined) => {
      setAudience(
        text !== undefined
          ? { ...audience, onlyLocations: [{ text, radiusMi: 100, lat: 0, lng: 0 }] }
          : { ...audience, onlyLocations: undefined }
      );
    };

    const toggleAudienceTier = (tier: CoreAPI.ClubMembershipTier) => {
      const all = club.tiers.map(t => t.key);
      if (audience.onlyTiers === undefined) {
        setAudience({
          ...audience,
          onlyTiers: all.filter(k => k !== tier.key),
        });
      } else {
        let toggled: string[] | undefined = audience.onlyTiers.includes(tier.key)
          ? audience.onlyTiers.filter(t => t !== tier.key)
          : [...audience.onlyTiers, tier.key];
        if (toggled.length === all.length) {
          toggled = undefined;
        }
        setAudience({ ...audience, onlyTiers: toggled });
      }
    };

    return (
      <FeedItemContainer>
        <FeedItemBox style={{ flexDirection: 'column' }}>
          {saving && (
            <SavingOverlay>
              <Spinner size={30} />
            </SavingOverlay>
          )}
          <DecoratedTextInput
            key={details.text === '' ? 'empty' : 'not-empty'}
            initialValue={details.text}
            onChange={setInputValue}
            placeholder="Write a post..."
          />
          {!!shownPreviews?.length && (
            <Preview isLoading={isLoading} previews={shownPreviews} removePreview={hidePreview} />
          )}
          {details.type === 'poll' && (
            <PostPollComposer
              club={club}
              values={details}
              onChange={setDetails}
              onRemove={() => setDetails({ type: 'text', text: details.text, images: [] })}
            />
          )}
          {details.type === 'livestream' && (
            <PostStreamComposer
              values={details}
              onChange={setDetails}
              onRemove={() => setDetails({ type: 'text', text: details.text, images: [] })}
            />
          )}
          {postAudioComposer}
          {details.type === 'text' && details.signup && (
            <PostComposerSignupEditor
              signupLabel={details.signup.label}
              onChange={label => setDetails({ ...details, signup: { closed: false, label } })}
            />
          )}
          <PostComposerMultimedia ref={attachmentsRef} details={details} setDetails={setDetails} />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ButtonGroup minimal style={{ gap: 5 }}>
              <PostActionIcon
                src={PhotoIcon}
                alt="Add photo"
                onClick={() => attachmentsRef.current.attachImage()}
              />
              <PostActionIcon
                src={VideoIcon}
                alt="Add video"
                onClick={() => attachmentsRef.current.attachVideo()}
              />
              {addAudioButton}
              <Tooltip content="Add signup button">
                <Button
                  disabled={details.type !== 'text'}
                  onClick={() => {
                    if (details.type === 'text') {
                      setDetails({ ...details, signup: { label: 'Count Me In!', closed: false } });
                    }
                  }}
                >
                  <Icon icon="hand" iconSize={22} />
                </Button>
              </Tooltip>
              <Tooltip content="Add scheduled livestream">
                <Button
                  onClick={() =>
                    setDetails({
                      type: 'livestream',
                      text: details.text,
                      start: Date.now(),
                      zoom: null,
                    })
                  }
                  active={details.type === 'livestream'}
                >
                  <Icon icon="cell-tower" iconSize={20} />
                </Button>
              </Tooltip>
              <Tooltip content="Add poll">
                <Button
                  onClick={() =>
                    setDetails({ type: 'poll', text: details.text, choices: ['', ''] })
                  }
                  active={details.type === 'poll'}
                >
                  <Icon icon="add-to-artifact" iconSize={22} />
                </Button>
              </Tooltip>
              <div style={{ width: 30 }} />
              <Select<CoreAPI.ClubMembershipTier>
                filterable={false}
                items={club.tiers}
                popoverProps={{ minimal: true }}
                onItemSelect={e => toggleAudienceTier(e)}
                noResults={<MenuItem disabled={true} text="No results." />}
                itemRenderer={(item, props) => (
                  <MenuItem
                    key={item.key}
                    text={item.name}
                    onClick={props.handleClick}
                    icon={
                      audience.onlyTiers === undefined || audience.onlyTiers.includes(item.key)
                        ? 'tick'
                        : 'blank'
                    }
                  />
                )}
              >
                <Button active={audience.onlyTiers !== undefined}>
                  <Icon icon="inherited-group" iconSize={20} />
                </Button>
              </Select>
              <Button
                onClick={() => setAudienceLocation(audience.onlyLocations ? undefined : '')}
                active={audience.onlyLocations !== undefined}
              >
                <Icon icon="map-marker" iconSize={20} />
              </Button>
              {audience.onlyLocations !== undefined && (
                <div style={{ marginLeft: 7 }}>
                  <InputGroup
                    type="text"
                    placeholder="Within 100mi of..."
                    value={audience.onlyLocations[0].text}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setAudienceLocation(e.target.value)
                    }
                  />
                </div>
              )}
              <ScheduleButton
                value={values.scheduledAt}
                onChange={scheduledAt => setValues({ ...values, scheduledAt })}
              />
            </ButtonGroup>
            <div style={{ flex: 1 }} />
            {canTest && location.search.includes('test=true') && (
              <Checkbox
                label="Test Post"
                checked={!!values.isTest}
                style={{ marginBottom: 0, marginRight: 15 }}
                onChange={e => {
                  setValues({ ...values, isTest: !values.isTest });
                }}
              />
            )}
            <Checkbox
              label="Notify Members"
              checked={notify}
              style={{ marginBottom: 0 }}
              onChange={e => setValues({ ...values, notify: !notify })}
            />
            <div style={{ width: 10 }} />
            <Button disabled={saving || isLoading} intent="primary" onClick={save}>
              Post
            </Button>
          </div>
        </FeedItemBox>
      </FeedItemContainer>
    );
  };

export const Preview: React.FunctionComponent<{
  isLoading?: boolean;
  previews: CoreAPI.LinkPreview[];
  removePreview?: (url: string) => void;
}> = ({ isLoading, previews, removePreview }) => {
  return (
    <PreviewContainer>
      {previews.map((p, i) => {
        return (
          p.image && (
            <PreviewCard key={`${i}-${p.url}`}>
              {removePreview && (
                <XButton
                  onClick={() => removePreview(p.url)}
                  style={{ position: 'absolute', margin: 8 }}
                />
              )}
              {p.url.includes('tiktok.com') && p.url.includes('/video/') ? (
                <TikTok url={p.url} />
              ) : p.url.includes('youtube.com/watch') ? (
                <Youtube url={p.url} />
              ) : (
                <>
                  <PreviewImage src={p.image} alt={p.title} />
                  <PreviewTextContainer>
                    <PreviewTitle href={p.url} target="_blank" rel="noopener noreferrer">
                      {p.title}
                    </PreviewTitle>
                    <PreviewDescription>{p.description}</PreviewDescription>
                  </PreviewTextContainer>
                </>
              )}
            </PreviewCard>
          )
        );
      })}
      {isLoading && <Spinner size={24} />}
    </PreviewContainer>
  );
};

export const XButton: React.FC<{ onClick?: () => void; style?: React.CSSProperties }> = ({
  onClick,
  style,
}) => (
  <ClickableItem onClick={onClick}>
    <CharInContainer
      char="+"
      style={{
        color: 'black',
        background: 'white',
        transform: 'rotate(45deg)',
        paddingTop: 2,
        fontSize: 18,
        width: 20,
        height: 20,
        borderRadius: '50%',
        fontWeight: 800,
        ...style,
      }}
    />
  </ClickableItem>
);

export const getFormData = (
  e: React.ChangeEvent<HTMLInputElement>,
  setFileInputKey: React.Dispatch<React.SetStateAction<number>>
) => {
  if (e.target.files?.length) {
    const raw = e.target.files[0];
    if (raw.size > 10 * 1000 * 1024) {
      alert('Please choose a file less than 10mb in size.');
      setFileInputKey(k => k + 1);
      return;
    }

    const newFileNameLc = raw.name.toLowerCase();
    if (newFileNameLc.includes('/')) {
      alert(`Please do not use files with slashes in the name.`);
      setFileInputKey(k => k + 1);
      return;
    }

    const formData = new FormData();
    formData.append('audio', raw);

    return formData;
  }
};

export const nameFromAttachmentPath = (attachmentPath: string) => {
  const fileName = attachmentPath.split('/').pop() || 'Invalid Name';
  return fileName;
};

const PreviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
`;

const PreviewCard = styled.div`
  background: transparent;
  width: 100%;
  position: relative;
  user-select: none;
`;

const PreviewImage = styled.img`
  width: 100%;
  height: 200px;
  margin-bottom: -4px;
  border-radius: 8px 8px 0 0;
  object-fit: cover;
`;

const PreviewTextContainer = styled.div`
  width: 100%;
  background: ${({ theme }) =>
    theme.themeMode === 'dark' ? hexWithOpacity('#000000', 0.45) : hexWithOpacity('#ffffff', 0.2)};
  padding: 8px;
  border-radius: 0 0 8px 8px;
`;

const PreviewTitle = styled.a`
  font-weight: 500;
  color: ${({ theme }) =>
    theme.themeMode === 'light' ? hexWithOpacity('#000000', 0.45) : hexWithOpacity('#ffffff', 0.5)};
  &:visited {
    color: ${({ theme }) =>
      theme.themeMode === 'light'
        ? hexWithOpacity('#000000', 0.5)
        : hexWithOpacity('#ffffff', 0.5)};
  }
`;

const PreviewDescription = styled.div`
  font-size: 12px;
  margin-top: 4px;
`;

const SavingOverlay = styled.div`
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ScheduleButton: React.FC<{ value: Date | undefined; onChange: (d: Date) => void }> = ({
  value,
  onChange,
}) => (
  <Popover
    content={
      <>
        <div
          style={{ fontWeight: 600, fontSize: 15, textAlign: 'center', padding: '10px 0 5px 0' }}
        >
          Schedule Post
        </div>
        <Divider />
        <DatePicker
          value={value}
          onChange={d => {
            if (moment(d).valueOf() > moment().add(5, 'm').valueOf()) {
              onChange(d);
            }
          }}
          minDate={new Date()}
          maxDate={moment().add(1, 'y').toDate()}
          highlightCurrentDay={true}
          showActionsBar={true}
          timePickerProps={{ useAmPm: true }}
        />
        <Divider />
        <div style={{ fontSize: 13, textAlign: 'center', padding: '5px 5px 10px 5px' }}>
          Scheduled for:
          <div style={{ fontWeight: 600, padding: 5 }}>
            {value ? moment(value).format('ddd, ll h:mm a') : 'Immediate'}
          </div>
        </div>
      </>
    }
  >
    <Button>
      <Icon icon="calendar" iconSize={22} />
    </Button>
  </Popover>
);

const PostComposerSignupEditor: React.FC<{
  signupLabel: string;
  onChange: (label: string) => void;
}> = ({ signupLabel, onChange }) => {
  return (
    <div>
      <OurButton
        size="normal"
        onClick={e => {
          e.currentTarget.querySelector('input')?.focus();
          e.stopPropagation();
          e.preventDefault();
        }}
        intent="primary"
      >
        <input
          type="text"
          value={signupLabel}
          style={{
            background: 'transparent',
            border: 'none',
            borderBottom: '1px solid rgba(255,255,255,0.4)',
            color: 'white',
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 16,
            width: 180,
          }}
          onClick={e => e.stopPropagation()}
          onChange={e => onChange(e.target.value)}
        />
      </OurButton>
    </div>
  );
};
