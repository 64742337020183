import { Spinner, Tooltip } from '@blueprintjs/core';
import React from 'react';
import styled from 'styled-components/macro';
import { Button } from '../Button';
import { ClickableItem, FeedItemBox, hexWithOpacity } from '../Common';
import { PhotoIcon, VideoIcon } from '../Icons';
import { ProfilePicture } from '../ProfilePicture';
import { DecoratedTextInput } from './DecoratedText';
import { Preview } from './PostComposer';
import { PostComposerMultimedia } from './PostComposerMultimedia';
import { useAudioUploader } from './useAudioUploader';
import { PostComposerProps, usePostComposerState } from './usePostComposerState';

export const MemberPostComposer: React.FunctionComponent<PostComposerProps> = props => {
  const { save, saving, values, setDetails, setInputValue, isLoading, shownPreviews, hidePreview } =
    usePostComposerState(props);
  const attachmentsRef = React.createRef<any>();
  const { details } = values;
  const { postAudioComposer, addAudioButton } = useAudioUploader(
    props.isAdmin,
    details,
    setDetails
  );

  return (
    <Container>
      <FeedItemBox>
        {saving && (
          <SavingOverlay>
            <Spinner size={30} />
          </SavingOverlay>
        )}
        <ProfilePicture user={props.user} size={window.innerWidth < 500 ? 40 : 50} />
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 12 }}>
          <DecoratedTextInput
            key={`${details.text && 'new-'}post-input`}
            initialValue={details.text}
            onChange={setInputValue}
            placeholder="Write a post..."
          />
          {!!shownPreviews?.length && (
            <Preview isLoading={isLoading} previews={shownPreviews} removePreview={hidePreview} />
          )}
          {postAudioComposer}
          <PostComposerMultimedia ref={attachmentsRef} details={details} setDetails={setDetails} />
          <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
            <PostActionIcon
              src={PhotoIcon}
              alt="Add photo"
              onClick={() => attachmentsRef.current.attachImage()}
            />
            <PostActionIcon
              src={VideoIcon}
              alt="Add video"
              onClick={() => attachmentsRef.current.attachVideo()}
            />
            {addAudioButton}
            <div style={{ flex: 1 }} />
            <Button disabled={saving || isLoading} intent="primary" onClick={save}>
              Post
            </Button>
          </div>
        </div>
      </FeedItemBox>
    </Container>
  );
};

export const PostActionIcon: React.FC<{
  src: string;
  alt: string;
  tooltipContent?: string;
  onClick?: () => void;
  children?: React.ReactNode;
}> = ({ src, alt, tooltipContent, onClick, children }) => {
  return (
    <Tooltip content={tooltipContent || alt} position="bottom">
      <ClickableItem
        onClick={onClick}
        style={{
          width: 30,
          height: 30,
          backgroundColor: hexWithOpacity('#ffffff', 0.4),
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          src={src}
          alt={alt}
          style={{ boxShadow: `0px 0px 4px 2px ${hexWithOpacity('#000000', 0.1)}` }}
        />
        {children}
      </ClickableItem>
    </Tooltip>
  );
};

const Container = styled.div`
  display: block;
  position: relative;
  padding: 10px;
  padding-bottom: 30px;
  user-select: none;
`;

const SavingOverlay = styled.div`
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
`;
